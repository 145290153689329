import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import RadarChart from '@components/chart/radar'
import AuthContext from '@contexts/auth'
// import { greenToRed } from '@helpers/green-to-red'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import { BorrowingBaseResponse } from '@interfaces/manage-monitor-borrowing-base'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

import { extractValueByDisplayName } from '../helper'

const SurplusChart = ({
  facility,
  facilityCalculationDate,
}: {
  facility?: Facility
  facilityCalculationDate?: string
}) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { facilities = [] } = optionFilters
  const { activeFacilityId: appliedactiveFacilityId } = appliedFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedactiveFacilityId
  )

  const calculationDate =
    facilityCalculationDate ?? appliedFilters.calculationDate

  const filters = {
    slug_name: facility?.slug_name ?? company?.slug_name ?? '',
    facility_id: facility?.facility_id ?? appliedFacility.facility_id,
    calculation_date: calculationDate,
  }

  const { data, isFetching: isSurplusFetching } = useQuery(
    ['surplus', filters],
    () => BorrowingBaseService.get(filters),
    { ...getStaleMins(), enabled: !!calculationDate }
  )

  const percent = extractValueByDisplayName(
    'Collateralization Percentage',
    data
  )

  const surplusDeficiency = extractSurplusDeficiency(data)

  const graphPercent = percent / 2
  // const color = greenToRed(
  //   percent
  //     ? 100 - graphPercent - (graphPercent >= 50 ? 20 : 0)
  //     : (Number(surplusDeficiency) ?? 0) > 0
  //     ? 0
  //     : 100,
  //   true
  // )
  const daysDeficientObj = data?.find(
    (d: BorrowingBaseResponse) => d.display_name == 'Days Deficient'
  )
  const daysDeficient: string = daysDeficientObj?.display_value ?? '-'

  if (isSurplusFetching) {
    return (
      <>
        <Typography className="text-xl font-medium mb-4 text-center">
          Borrowing Base
        </Typography>
        <div className="flex justify-center mt-2 w-full border border-neutral-border-2 relative">
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        </div>
      </>
    )
  }
  return (
    <div className="flex flex-col gap-6 items-center justify-between h-full p-6">
      <Typography className="font-semibold text-cc-primary-gray-dark">
        Borrowing Base
      </Typography>

      <div className="w-full">
        <RadarChart
          value={surplusDeficiency}
          subtitle={percent ? percent + '%' : '-'}
          percent={graphPercent}
          id={`radar-chart${facility ? `-${facility?.facility_id}` : ''}`}
        />
      </div>
      <div className="flex flex-col items-center">
        <Typography className="font-light text-sm text-cc-text-primary">
          Days Deficient
        </Typography>
        <div
          className={`text-2xl font-medium ${colorDeficient(daysDeficient)}`}
        >
          {daysDeficient === '-'
            ? daysDeficient
            : Number(daysDeficient).toFixed(0)}
        </div>
      </div>
    </div>
  )
}

export default SurplusChart

const extractSurplusDeficiency = (data: any) => {
  const foundObject = data?.find(
    (d: BorrowingBaseResponse) =>
      d.display_name === 'Surplus' || d.display_name === 'Deficiency'
  )
  return foundObject ? Number(foundObject.value.toFixed(2)) : 0
}

const colorDeficient = (daysDeficient: string) => {
  if (daysDeficient === '-') {
    return ''
  }
  if (Number(daysDeficient) >= 15) {
    return 'text-danger-main'
  } else if (Number(daysDeficient) >= 5) {
    return 'text-warning-main'
  } else {
    return 'text-success-main'
  }
}
