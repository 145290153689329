import React, { useContext, useEffect, useState } from 'react'
import { useInfiniteQuery, useMutation } from 'react-query'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

import { renderDecimal, SEPARATOR_COLUMN } from './helper'

enum Component {
  'Borrowing Base',
  'Historical Surplus',
  Collaterals,
}
const SKIPPED_COLUMNS = [SEPARATOR_COLUMN]

const ExportDialog = () => {
  const [is_downloading, setIsDownloading] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const _open = () => {
    setOpen(!open)
  }

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId, calculationDate } = appliedFilters
  const { facilities = [] } = optionFilters

  const activeFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === activeFacilityId
  )

  const { get_access } = useUserAccessFeature()
  const FEATURE = `${activeFacilityId}_manage_monitor_borrowing-base`
  const can_export_bb = get_access(`${FEATURE}_borrowing-base`, 'export')
  const can_export_collateral = get_access(`${FEATURE}_collateral`, 'export')

  const LIMIT = 1000
  const filters = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    calculation_date: calculationDate,
  }

  const API = (component: number, params: any): Promise<any> => {
    switch (component) {
      case Component['Borrowing Base']:
        return BorrowingBaseService.get(params)
      case Component['Historical Surplus']:
        return BorrowingBaseService.historicalSurplus(params)
      case Component.Collaterals:
        return BorrowingBaseService.collateral(params)
      default:
        return new Promise(resolve => resolve([]))
    }
  }

  const default_query = (component: number, params: any) => {
    return useMutation({
      mutationKey: [component, params],
      mutationFn: () => {
        return API(component, params)
      },
    })
  }

  const paginated_query = (component: number, params: any) => {
    return useInfiniteQuery({
      queryKey: [component, params],
      queryFn: ({ pageParam = 0 }) => {
        return API(component, {
          ...params,
          page: pageParam + 1,
          per_page: LIMIT,
        })
      },
      getNextPageParam: (lastPage, pages) => {
        return pages?.length
      },
      enabled: !!calculationDate && open,
    })
  }

  const raw_parts = [
    ...(can_export_bb
      ? [Component['Borrowing Base'], Component['Historical Surplus']]
      : []),
    ...(can_export_collateral ? [Component.Collaterals] : []),
  ]

  const parts = raw_parts.map(p => {
    return {
      component: p,
      paginated: p === Component.Collaterals,
      ...(p === Component.Collaterals
        ? paginated_query(p, filters)
        : default_query(p, filters)),
    }
  })

  const current_part: any = parts.find((p: any) => {
    let total = 0,
      maxPage = 0,
      processedPage = 0

    if (p.paginated) {
      total = p.paginated ? p.data?.pages?.[0]?.total : 1
      maxPage = Math.ceil(total / LIMIT)
      processedPage = (p.data?.pages ?? []).length
    }
    const fetching = p.paginated ? processedPage < maxPage : p.isIdle
    return fetching
  })

  const prepare_export = () => {
    if (open && current_part) {
      if (current_part?.fetchNextPage) {
        current_part?.fetchNextPage()
      }
      if (current_part?.mutate) {
        current_part?.mutate()
      }
    }
  }

  useEffect(() => {
    if (open) {
      prepare_export()
    } else {
      setIsDownloading(false)
    }
  }, [open, current_part])

  const is_download_ready = !current_part
  const _download = async () => {
    setIsDownloading(true)

    const workbook = XLSX.utils.book_new()

    parts.forEach(p => {
      const data = p.paginated
        ? (p as any).data?.pages?.reduce((prev: any, cur: any) => {
            return [...prev, ...cur.data]
          }, [])
        : (p as any).data

      const all_contents = data.reduce(
        (pd: { [key: string]: any[] }, d: any) => {
          const { detail_data, ...res_d } = d
          return {
            ...pd,
            [Component[p.component]]: [
              ...(pd[Component[p.component]] ?? []),
              Object.keys(res_d).reduce((prd, rd) => {
                return SKIPPED_COLUMNS.includes(rd)
                  ? prd
                  : { ...prd, [rd]: res_d[rd] }
              }, {}),
            ],
            ...(detail_data?.length > 0
              ? { [res_d.display_name]: detail_data }
              : {}),
          }
        },
        {}
      )
      Object.keys(all_contents).forEach(c => {
        const worksheet = XLSX.utils.json_to_sheet(all_contents[c] ?? [])
        XLSX.utils.book_append_sheet(workbook, worksheet, c)
      })
    })

    await XLSX.writeFile(
      workbook,
      `${company?.legal_name} - ${activeFacility?.facility_name} Borrowing Base ${calculationDate}.xlsx`
    )

    setIsDownloading(false)
  }
  return (
    <>
      <Button
        className="mr-0.25!shadow-none border border-primary-main text-primary-main py-2 px-6 hover:bg-primary-main hover:text-neutral-white"
        onClick={_open}
      >
        <Typography className="capitalize font-medium text-sm">
          Export
        </Typography>
      </Button>
      <Dialog open={open} handler={() => undefined}>
        <DialogHeader className="justify-between">
          <Typography className="capitalize font-medium text-xl">
            Export Borrowing base
          </Typography>
          <XMarkIcon
            onClick={_open}
            className="w-6 h-6 cursor-pointer hover:opacity-50"
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col p-6">
          <Typography className="text-sm text-center font-bold">
            Please DO NOT close this dialog or the browser tab until exporting
            is complete
          </Typography>
          <div className="mt-8 flex flex-col gap-4 max-h-[50vh] overflow-y-auto">
            {parts.map((p: any) => {
              const current = p.paginated
                ? p.data?.pages?.reduce(
                    (prev: any, cur: any) => prev + cur.data.length,
                    0
                  )
                : 0
              const total = p.paginated ? p.data?.pages?.[0]?.total : 1
              const fetching = p.paginated ? current < total : p.isLoading
              return (
                <div
                  key={p.component}
                  className="rounded-lg bg-neutral-border-1 px-4 py-2 flex gap-4 justify-between items-center"
                >
                  <Typography className="capitalize text-sm flex-1">
                    {Component[p.component]}
                    {p.paginated && total > 0 && (
                      <span className="text-sm flex-1 ml-2 normal-case">
                        {`(${renderDecimal(current, 0)} of ${renderDecimal(
                          total,
                          0
                        )})`}
                      </span>
                    )}
                  </Typography>

                  {fetching && (
                    <div className="rounded h-4 flex-1 overflow-hidden border border-primary-main">
                      <div
                        className="bg-primary-main animate-pulse"
                        style={{
                          height: '100%',
                          width: p.paginated
                            ? `${(current / total) * 100}%`
                            : '100%',
                        }}
                      />
                    </div>
                  )}
                  {!fetching && p.isSuccess && (
                    <CheckCircleIcon className="text-primary-main w-6 h-6" />
                  )}
                  {!fetching && p.isError && (
                    <ExclamationTriangleIcon className="text-secondary-main w-6 h-6" />
                  )}
                </div>
              )
            })}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={_download}
            disabled={!is_download_ready || is_downloading}
            className="!shadow-none border py-2 px-6 border-primary-main text-primary-main active:border-1 hover:bg-primary-main hover:text-neutral-white disabled:border-neutral-border-3 disabled:bg-neutral-border-2 disabled:text-neutral-border-3"
          >
            {is_downloading && (
              <ArrowPathIcon className="w-4 h-4 mr-4 text-primary-main animate-spin" />
            )}
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ExportDialog
