import React, { useContext, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { chartColors } from '@components/chart'
import { Part } from '@components/table/type'
import AuthContext from '@contexts/auth'
import { greenToRed } from '@helpers/green-to-red'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import {
  CovenantDetailRequest,
  CovenantDetailResponse,
  CovenantListRequest,
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { Tooltip } from '@material-tailwind/react'
import { CovenantService } from '@services/api-manage/monitor-covenant'

export const useCovenantList = ({
  type,
  facility,
}: {
  type: CovenantType
  facility?: Facility
}) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeFacilityId: appliedFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedFacilityId
  )
  const filters: CovenantListRequest = {
    slug_name:
      facility?.slug_name ??
      appliedFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility_id: facility?.facility_id ?? appliedFacilityId,
    type,
  }

  const { data, isLoading } = useQuery(
    ['covenant-list', filters],
    () => CovenantService.getList(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && !!appliedFacilityId,
    }
  )
  return { data, isLoading }
}

export const getLabel = (covenant: CovenantDetailResponse) => {
  if (covenant?.params?.label) {
    return covenant.params.label
  } else {
    return ''
  }
}

export const getUnit = (covenant: CovenantListResponse) => {
  if (covenant?.unit) {
    return ' ' + covenant.unit
  }
  const covenantName = covenant?.covenant_name?.toLowerCase()

  switch (covenantName) {
    case 'minimum cash balance':
      return ' USD'
    case 'minimum cash runway':
      return 'm'
    default:
      return '%'
  }
}

export const getLabelFormat = (covenant: CovenantListResponse) => {
  if (covenant?.params?.label_format) {
    return covenant?.params?.label_format
  } else {
    return '#.00a%'
  }
}

export const useCovenantDetail = ({
  type,
  id,
}: {
  type: CovenantType
  id: number
}) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const filters: CovenantDetailRequest = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    type,
    id,
  }

  const { data, isLoading } = useQuery(
    ['covenant-detail', filters],
    () => CovenantService.getDetail(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && !!activeFacilityId && !!id,
    }
  )
  return { data, isLoading }
}

export const useCovenantAudit = ({
  type,
  id,
  open,
}: {
  type: CovenantType
  id: number
  open: boolean
}) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const filters: CovenantDetailRequest = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    type,
    id,
  }

  const { data, isLoading } = useQuery(
    ['covenant-raw', filters],
    () => CovenantService.getRaw(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && !!activeFacilityId && !!id && open,
    }
  )
  return { data, isLoading }
}

export const getRandomColor = () =>
  '#00' +
  Math.floor(Math.random() * 16777215)
    .toString(16)
    .slice(2, -2) +
  'ff'

export const getMultiplier = (covenant: CovenantListResponse) => {
  if (
    covenant?.params.multiplier &&
    !['Vintage', 'Table'].includes(covenant.params.special_covenent)
  ) {
    return Number(covenant?.params.multiplier)
  } else {
    return 1
  }
}

export const useSpecialCovenantDetail = (
  covenant: CovenantDetailResponse,
  summary: CovenantListResponse
) => {
  const [selectedCohort, setSelectedCohort] = useState<string>('Summary')
  const cohortInterval = summary.params.x_axis[1]
  const unprepColumns = [covenant].reduce((p: any[], c) => {
    const newColumns = c.data.map(x => x[cohortInterval])
    return [...p, ...newColumns]
  }, [])
  const specialCovenant = covenant.params.special_covenent

  const setColor = (field: string, data: any) => {
    if (data.x > moment.utc(summary.date_as_of).valueOf()) {
      return '#757d90'
    } else if ((summary.triggers ?? []).length === 0) {
      return greenToRed(0, true).toString()
    }
    const denominator = data.triggers
    const numerator = summary.params.invert_colours
      ? denominator - (data.trigger_value ?? 0)
      : data.trigger_value ?? 0
    const percent = (numerator / denominator) * 100
    return greenToRed(percent, true).toString()
  }

  const setTextColor = (data: any, i: number) => {
    const triggerVal = data[`trigger_${i}`]

    if ((summary.triggers ?? []).length === 0) {
      return greenToRed(0, true).toString()
    }
    const denominator = (summary.triggers ?? []).length
    const numerator = summary.params.invert_colours
      ? denominator - (triggerVal ?? 0)
      : triggerVal ?? 0
    const percent = (numerator / denominator) * 100
    return greenToRed(percent, true).toString()
  }
  /**prepare table columns to be appended to table structure for table type */
  const preppedColumns = unprepColumns
    .filter((c, i) => unprepColumns.indexOf(c) === i)
    .map(x => ({
      title: x,
      field: `value_${x}`,
      align: 'center',
      className: 'min-w-[100px]',
      render: (r: any, i: number, part: Part) => {
        const divElements = []
        //trigger value display
        if (
          (typeof r[`value_${x}`] !== 'undefined' || r.cohort == 'Summary') &&
          specialCovenant == 'Table'
        ) {
          for (let i = 1; i <= r.cohort_trigger_count; i++) {
            divElements.push(
              <div
                key={i}
                className={`border-b border-neutral-border-2 flex justify-center items-center -mx-3 py-2 ${
                  (part === 'head' && Number(r[`value_${x}`])) ||
                  (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                    ? r.cohort == 'Summary' && i == r.cohort_trigger_count
                      ? 'border-b-0'
                      : ''
                    : '!bg-neutral-border-2'
                }`}
              >
                {typeof r[`value_${x}`] !== 'undefined'
                  ? r.cohort != 'Summary'
                    ? Intl.NumberFormat(undefined, {
                        style: 'percent',
                        notation: 'compact',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(r[`trigger_${x}_${i}_val`] / 100)
                    : r[`trigger_${x}_${i}_val`]
                  : ''}
              </div>
            )
          }
        }
        //actual value
        if (typeof r[`value_${x}`] !== 'undefined' && r.cohort != 'Summary') {
          const textCol = setTextColor(r, x)
          divElements.push(
            <div
              className={`flex justify-center items-center -mx-3 py-2 ${
                (part === 'head' && Number(r[`value_${x}`])) ||
                (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                  ? part === 'body'
                    ? `text-[${textCol}]`
                    : ''
                  : 'bg-neutral-border-2'
              }`}
            >
              {(part === 'head' && r[`value_${x}`]) ||
              (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                ? r.cohort != 'Summary'
                  ? Intl.NumberFormat(undefined, {
                      style: 'percent',
                      notation: 'compact',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(r[`value_${x}`] / 100)
                  : r[`value_${x}`]
                : ' '}
            </div>
          )
        }
        // Render the div elements inside a container div
        return divElements
      },
      props: (r: any, i: number, part: Part) => {
        return specialCovenant == 'Vintage'
          ? {
              className: `${
                selectedCohort == r.cohort &&
                typeof r[`value_${x}`] !== 'undefined'
                  ? `!bg-primary-surface-2`
                  : ``
              } ${
                (part === 'head' && Number(r[`value_${x}`])) ||
                (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                  ? part === 'body'
                    ? r[`trigger_${x}`] === 1
                      ? '!bg-danger-surface !text-danger-main'
                      : '!bg-success-surface !text-success-main'
                    : ''
                  : '!bg-neutral-border-2'
              }`,
            }
          : {
              className: `  ${
                selectedCohort == r.cohort &&
                typeof r[`value_${x}`] !== 'undefined'
                  ? `!bg-primary-surface-2`
                  : ``
              } ${
                (part === 'head' && Number(r[`value_${x}`])) ||
                (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                  ? ''
                  : '!bg-neutral-border-2'
              }`,
            }
      },
    }))
    .sort((a, b) => (a.title > b.title ? 1 : -1))

  const cohortCol =
    specialCovenant == 'Table'
      ? {
          title: 'Cohort',
          field: 'cohort',
          align: 'center',
          className: 'min-w-[100px] sticky left-0',
          render: (r: any, i: number, part: Part) => {
            return part === 'body' ? (
              <div>
                <Tooltip
                  content={<span>{'Click here to chart this cohort'}</span>}
                  placement="right"
                >
                  {moment(r.cohort).isValid()
                    ? moment.utc(r.cohort).format('DD-MMM-YY')
                    : r.cohort}
                </Tooltip>
              </div>
            ) : (
              r.cohort
            )
          },
          props: (r: any, i: number, part: Part) => {
            return {
              onClick: () => setSelectedCohort(r.cohort),
              className: `${
                part === 'body'
                  ? selectedCohort == r.cohort
                    ? `cursor-pointer !bg-primary-surface-2`
                    : `cursor-pointer hover:bg-secondary-surface`
                  : ''
              }`,
            }
          },
        }
      : {
          title: 'Cohort',
          field: 'cohort',
          align: 'center',
          className: 'min-w-[100px] sticky left-0',
          render: (r: any, i: number, part: Part) => {
            return part === 'body'
              ? moment.utc(r.cohort).format('MMM-YY')
              : r.cohort
          },
        }

  const triggerCol = {
    title: 'Triggers',
    field: 'triggers',
    align: 'center',
    render: (r: any) => {
      const divElements = []

      for (let i = 1; i <= r.cohort_trigger_count; i++) {
        divElements.push(
          <div
            key={i}
            className={`flex justify-center items-center trigger-div -mx-3 py-2 ${
              r.cohort == 'Summary' && i == r.cohort_trigger_count
                ? ''
                : 'border-b '
            } border-neutral-border-2`}
          >
            Trigger {i}
          </div>
        )
      }
      r.cohort != 'Summary' &&
        divElements.push(
          <div className="flex justify-center items-center -mx-3 py-2">
            Value
          </div>
        )
      return divElements
    },
    props: (r: any) => {
      return {
        className: `min-w-[100px] sticky left-0  ${
          selectedCohort == r.cohort ? `!bg-primary-surface-2` : ``
        }`,
      }
    },
  }

  /** adds cohort and above prepped columns to table structure */
  const tableColumns =
    specialCovenant == 'Table'
      ? [cohortCol, triggerCol, ...preppedColumns]
      : [cohortCol, ...preppedColumns]
  let maxVal = 5
  let minVal = 0

  const summaryVals: any = {}

  const specialSeriesLabels = (summary.params.special_series_label ?? '').split(
    ','
  )

  const specialSeriesFields = (summary.params.special_series_field ?? '').split(
    ','
  )

  const unpreppedtableData = [covenant]
    .reduce((p: any[], c) => {
      return [...p, ...(c.data ?? [])]
    }, [])
    .reduce((p, c) => {
      const key = `value_${c[cohortInterval]}`
      const trigger_key = `trigger_${c[cohortInterval]}`
      const existingIndex = p.findIndex((x: any) => x.cohort === c.cohort)

      if (existingIndex >= 0) {
        const newObj = {
          ...p[existingIndex],
          [key]: c[summary.params.column[0]],
          [trigger_key]: c.trigger,
          trigger_value: c.trigger_value,
        }
        for (let i = 1; i <= countTriggerKeys(c); i++) {
          c[`trigger_${i}`] > maxVal && (maxVal = c[`trigger_${i}`] + 10)
          c[`trigger_${i}`] < minVal && (minVal = c[`trigger_${i}`] - 10)

          newObj[`${trigger_key}_${i}_val`] = c[`trigger_${i}`]
          newObj[`cohort_trigger_count`] = countTriggerKeys(c)

          // Update summary for each trigger
          summaryVals[`cohort`] = 'Summary'
          summaryVals[`cohort_trigger_count`] = countTriggerKeys(c)

          summaryVals[key] =
            c.trigger == i ? (summaryVals[key] || 0) + 1 : summaryVals[key] || 0

          summaryVals[`${trigger_key}_${i}_val`] =
            c.trigger == i ? (summaryVals[key] || 0) + 1 : summaryVals[key] || 0
        }
        //ensures special series plotted are visible by enforcing min & max chart values
        for (let j = 1; j <= specialSeriesFields.length; j++) {
          c[specialSeriesFields[j]] > maxVal &&
            (maxVal = c[specialSeriesFields[j]] + 10)
          c[specialSeriesFields[j]] < minVal &&
            (minVal = c[specialSeriesFields[j]] - 10)
        }

        p[existingIndex] = newObj

        return p
      } else {
        const newObj = {
          cohort: c.cohort,
          [key]: c[summary.params.column[0]],
          [trigger_key]: c.trigger,
          trigger_value: c.trigger_value,
        }
        for (let i = 1; i <= countTriggerKeys(c); i++) {
          newObj[`${trigger_key}_${i}_val`] = c[`trigger_${i}`]
          newObj[`cohort_trigger_count`] = countTriggerKeys(c)

          // Update summary for each trigger
          summaryVals[`cohort`] = 'Summary'
          summaryVals[`cohort_trigger_count`] = countTriggerKeys(c)
          summaryVals[key] =
            c.trigger == i ? (summaryVals[key] || 0) + 1 : summaryVals[key] || 0

          summaryVals[`${trigger_key}_${i}_val`] =
            c.trigger == i ? (summaryVals[key] || 0) + 1 : summaryVals[key] || 0
        }
        return [...p, newObj]
      }
    }, [])
    .sort((a: any, b: any) => (a.cohort > b.cohort ? 1 : -1))

  const tableData =
    specialCovenant == 'Table'
      ? [summaryVals, ...unpreppedtableData]
      : [...unpreppedtableData]

  const unfilteredData = [covenant]
    .reduce((p: any[], c) => {
      return [...p, ...(c.data ?? [])]
    }, [])
    .sort((a, b) => (a.cohort > b.cohort ? 1 : -1))

  const unprepedData =
    specialCovenant == 'Table'
      ? unfilteredData.filter(
          p =>
            moment.utc(p.cohort).format('DD-MM-YYYY') ==
            moment.utc(selectedCohort).format('DD-MM-YYYY')
        )
      : unfilteredData

  const unpreppedTriggers = unprepedData
    .sort((a, b) => (a[`${cohortInterval}`] > b[`${cohortInterval}`] ? 1 : -1))

    .reduce((p, c) => {
      if (!Number(c.trigger_1)) {
        return p
      }
      const existingIndex = p.findIndex((x: any) => x.trigger_1 === c.trigger_1)
      if (existingIndex >= 0) {
        if (
          !p[existingIndex][`${cohortInterval}s`].includes(
            c[`${cohortInterval}`]
          )
        ) {
          p[existingIndex][`${cohortInterval}s`].push(c[`${cohortInterval}`])
        }
        return p
      } else {
        return [
          ...p,
          {
            [`${cohortInterval}s`]: [c[`${cohortInterval}`]],
            ...(summary.triggers
              ? Array(summary.triggers.length)
                  .fill('')
                  .reduce((_p, _, _i) => {
                    return {
                      ..._p,
                      [`trigger_${_i + 1}`]: c[`trigger_${_i + 1}`],
                    }
                  }, {})
              : {}),
          },
        ]
      }
    }, [])
    .sort((a: any, b: any) =>
      Number(Math.min(...a.mobs)) < Number(Math.min(...b.mobs)) ? -1 : 1
    )

  const triggers = unpreppedTriggers.map((x: any) => ({
    ...x,
    [cohortInterval]: Math.max(...x[`${cohortInterval}s`]),
  }))

  const defaultSeries = {
    tooltipValueFormat: getLabelFormat(summary),
    type: 'SmoothedXLineSeries',
    hasBullet: false,
  }

  const triggerSeries =
    specialCovenant == 'Table'
      ? Array(summary.triggers?.length)
          .fill('')
          .map((_, _i) => {
            return {
              tooltipValueFormat:
                selectedCohort == 'Summary' ? '' : getLabelFormat(summary),
              type:
                selectedCohort == 'Summary' ? 'ColumnSeries' : 'StepLineSeries',
              field: `trigger_${_i + 1}`,
              label: `Trigger ${_i + 1}`,
              hasBullet: false,
              noRisers: true,
            }
          })
      : triggers.reduce((p: any[], c: any) => {
          const mob_triggers = Array(
            summary?.triggers ? summary.triggers[0].trigger : {}
          )
            .fill('')
            .map((_, _i) => {
              return {
                tooltipValueFormat: getLabelFormat(summary),
                type: 'StepLineSeries',
                field: `trigger_${_i + 1}_${c[cohortInterval]}`,
                label: `MoB ${c[`${cohortInterval}s`]?.[0]}${
                  c[`${cohortInterval}s`].length > 1
                    ? `-${
                        c[`${cohortInterval}s`]?.[
                          c[`${cohortInterval}s`]?.length - 1
                        ]
                      }`
                    : ``
                } Trigger ${_i + 1}`,
                color: '#' + Math.floor(Math.random() * 16777215).toString(16),
                hasBullet: false,
                noRisers: true,
              }
            })
          return [...p, ...mob_triggers]
        }, [])

  const unpreppedChartSeries: any[] = [
    ...(specialCovenant == 'Table'
      ? tableData.filter(
          (t: any) =>
            moment.utc(t.cohort).format('DD-MM-YYYY') ==
            moment.utc(selectedCohort).format('DD-MM-YYYY')
        )
      : tableData
    ).map((x: any) =>
      specialCovenant == 'Table'
        ? {
            type: 'ColumnSeries',
            label:
              selectedCohort == 'Summary'
                ? 'Summary'
                : moment.utc(x.cohort).format('DD-MMM-YY'),
            field: moment.utc(x.cohort).format(),
            setColor,
          }
        : {
            ...defaultSeries,
            label: moment.utc(x.cohort).format('MMM-YY'),
            field: x.cohort,
            color: '#' + Math.floor(Math.random() * 16777215).toString(16),
          }
    ),
    ...triggerSeries,
  ]

  const specialSeries = (specialSeriesFields ?? []).map((s: any, i: number) => {
    return {
      label: specialSeriesLabels[i],
      tooltipValueFormat: getLabelFormat(summary),
      type: 'SmoothedXLineSeries',
      field: s,
      color:
        chartColors[i + unpreppedChartSeries.length] ??
        '#' + Math.floor(Math.random() * 16777215).toString(16),
      hasBullet: false,
      isSpecial: true,
    }
  })

  let chartSeries = [...unpreppedChartSeries]

  if (specialSeriesFields[0] !== '') {
    chartSeries = [...chartSeries, ...specialSeries]
  }
  const summaryData = tableData.filter(td => td.cohort == 'Summary')[0] ?? []
  const triggerCount = summary.triggers?.length ?? 0

  const regex = /trigger_\d+_\d+_val/
  const matchingKeys = Object.keys(summaryData).filter(s => regex.test(s))

  const xArray: string[] = []

  matchingKeys.forEach(
    (mk: string) =>
      !xArray.includes(mk.split('_')[1]) && xArray.push(mk.split('_')[1])
  )

  const chartData: any[] =
    specialCovenant == 'Table' && selectedCohort == 'Summary'
      ? xArray
          .sort((a, b) => (parseFloat(a) > parseFloat(b) ? 1 : -1))
          .map(xa => {
            const newObj: any = {}
            for (let i = 1; i <= triggerCount; i++) {
              newObj[`trigger_${i}`] = summaryData[`trigger_${xa}_${i}_val`]

              summaryData[`trigger_${xa}_${i}_val`] > maxVal &&
                (maxVal =
                  parseFloat(summaryData[`trigger_${xa}_${i}_val`]) + 10)
              summaryData[`trigger_${xa}_${i}_val`] < minVal &&
                (minVal =
                  parseFloat(summaryData[`trigger_${xa}_${i}_val`]) - 10)
            }
            return {
              x: xa,
              ...newObj,
            }
          })
      : unprepedData
          .reduce((p, c) => {
            const key = moment.utc(c.cohort).toISOString()
            c[summary.params.column[0]] > maxVal &&
              (maxVal = parseFloat(c[summary.params.column[0]]) + 10)
            c[summary.params.column[0]] < minVal &&
              (minVal = parseFloat(c[summary.params.column[0]]) - 10)

            return [
              ...p,
              {
                x: c[cohortInterval],
                [cohortInterval]: c[cohortInterval],
                trigger_value: c.trigger_value,
                triggers: summary.triggers?.length ?? 0,
                [key]: c[summary.params.column[0]],
                ...specialSeriesFields.reduce((_s: any, _c: any) => {
                  return {
                    ..._s,
                    ...Array(specialSeriesFields.length)
                      .fill('')
                      .reduce(__p => {
                        return {
                          ...__p,
                          [`${_c}`]: c[`${_c}`],
                        }
                      }, {}),
                  }
                }, {}),
                ...triggers.reduce((_p: any, _c: any) => {
                  return _c[`${cohortInterval}s`].includes(c[cohortInterval]) &&
                    summary?.triggers
                    ? {
                        ..._p,
                        ...Array(summary?.triggers.length)
                          .fill('')
                          .reduce((__p, __, __i) => {
                            return {
                              ...__p,
                              [`trigger_${__i + 1}`]: _c[`trigger_${__i + 1}`],
                            }
                          }, {}),
                      }
                    : _p
                }, {}),
              },
            ]
          }, [])
          .sort((a: any, b: any) =>
            a[cohortInterval] > b[cohortInterval] ? 1 : -1
          )

  const overviewColumns = [
    {
      title: 'Trigger',
      field: 'trigger',
      align: 'center',
    },
    { title: 'Breach', field: 'breach', align: 'center' },
  ]
  const overviewData = triggers.reduce((px: any[], cx: any) => {
    const mob_trigger = Array(
      summary?.triggers ? summary?.triggers[0].trigger : {}
    )
      .fill('')
      .map((_, _i) => {
        return {
          ...cx,
          trigger: `MoB ${cx.mobs?.[0]}${
            cx.mobs.length > 1 ? `-${cx.mobs?.[cx.mobs?.length - 1]}` : ``
          } Trigger ${_i + 1}`,
          breach: unprepedData?.reduce((p, c) => {
            const trigger = Number(c.trigger)
            return cx.mobs.includes(c.mob) ? p + trigger : p
          }, 0),
        }
      })
    return [...px, ...mob_trigger]
  }, [])
  const tableHeaderData = Array(summary?.triggers?.length)
    .fill('')
    .map((_, i) => {
      return {
        cohort: `Trigger ${i + 1}`,
        ...triggers.reduce((p: any, c: any) => {
          const value_mob = c.mobs.reduce((_p: any, _c: any) => {
            return {
              ..._p,
              [`value_${_c}`]: c[`trigger_${i + 1}`],
            }
          }, {})
          return { ...p, ...value_mob }
        }, {}),
      }
    })
  return {
    tableColumns,
    tableData,
    chartData,
    chartSeries,
    maxVal,
    minVal,
    selectedCohort,
    specialSeries,
    overviewColumns,
    overviewData,
    tableHeaderData,
  }
}

export const countTriggerKeys = (obj: any) => {
  let count = 0
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      key.startsWith('trigger_') &&
      !isNaN(parseInt(key.split('_')[1]))
    ) {
      count++
    }
  }
  return count
}
