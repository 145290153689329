import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { numberFormatter } from '@helpers/number-formatter'
import { useGetRiskData } from '@helpers/risk-charts-data-hook'
import { DataResponse, RiskData } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { Switch } from '@material-tailwind/react'
import { CollectionService } from '@services/api-analytics/risk-collection'

const CashCollectedL5 = ({ exportable }: { exportable: boolean }) => {
  const { appliedFilters, optionFilters } = useContext(AuthContext)
  const { categoryTypes = [], activeType } = appliedFilters
  const { categoryTypeOptions } = optionFilters
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const {
    fetchedDataAggregated,
    data,
    cohort,
    error,
    isFetching,
    isAggregate,
  } = useGetRiskData(CollectionService.getCashCollected, 'cash_collected', true)

  const { showTable, setShowTable } = useContext(ApplicationCustomContext)

  let maxVal = 0

  const chartData = cohort.map((c: RiskData<DataResponse>) => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
    }
    const cohortData = (data || []).filter(
      (d: RiskData<DataResponse>) => d.cohort === c.cohort
    )
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      result.type_all_outgoing_cash = parseFloat(
        (cohortData?.[0]?.outgoing_cash ?? 0).toString()
      )
      result.type_all_incoming_cash = parseFloat(
        (cohortData?.[0]?.incoming_cash ?? 0).toString()
      )
      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find((cd: RiskData<DataResponse>) => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        result[`type_${ct}_outgoing_cash`] = parseFloat(
          (cohortCategory?.outgoing_cash ?? 0).toString()
        )
        result[`type_${ct}_incoming_cash`] = parseFloat(
          (cohortCategory?.incoming_cash ?? 0).toString()
        )
        if (result[`type_${ct}`] > maxVal) {
          maxVal = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const cashCollectedSeries: any[] = isAggregate
    ? [
        {
          label: 'All',
          tooltipValueFormat: '#.00a%',
          type: 'ColumnSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.00a%',
          field: `type_${ct}`,
          color,
          type: 'ColumnSeries',
        }
      })

  const header_data = [
    cashCollectedSeries.reduce((p: any, s: any) => {
      return {
        ...p,
        [`${s.field}_outgoing_cash`]: 'Total Cash Disbursed',
        [`${s.field}_incoming_cash`]: `Total Payments`,
        [s.field]: 'Cash Collected (%)',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...cashCollectedSeries.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_incoming_cash`,
          title: header_data[0][`${s.field}_incoming_cash`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_incoming_cash`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_outgoing_cash`,
          title: header_data[0][`${s.field}_outgoing_cash`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_outgoing_cash`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `Cash Collected (%) ${isAggregate ? '' : `(${s.label})`}`,
          head: { colSpan: 3, align: 'center' },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]

  return (
    <L5ChartstatLayout
      chart={
        <>
          <Chart
            loading={isFetching || isProcessing}
            id={`riskCollectionCashCollected_by_${activeType}`}
            yLabel="Percentage"
            ySetting={maxVal < 5 ? { max: 5, maxPrecision: 1 } : {}}
            yFormat="#.00a%"
            data={chartData}
            series={cashCollectedSeries as any}
            exportable={exportable}
            exportableColumn={table_columns}
            error={error as { message: string }}
          />
          <div className="flex justify-end mt-8">
            <Switch
              label="Show Data"
              checked={showTable}
              onChange={() => {
                setShowTable(!showTable)
              }}
              defaultChecked
              color="light-blue"
              crossOrigin={undefined}
            />
          </div>
          {showTable && (
            <Table
              containerClass="mt-8 [&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
              loading={isFetching || isProcessing}
              data={chartData}
              headerData={header_data}
              columns={table_columns}
            />
          )}
        </>
      }
    />
  )
}

export default CashCollectedL5
