import moment, { Moment } from 'moment'

import { CategoriesResult } from '@interfaces/manage-reporting'

import { useCoignBackupInterestReportData } from './coign/backup-interest-report-data'
import { useCoignComplianceCertData } from './coign/compliance-cert-data'
import { useOrangeBankComplianceCertData } from './orange-bank/compliance-cert-data'
import { useOrangeBankQuarterlyPaymentDemandCertData } from './orange-bank/quarterly-payment-demand'

export const getCustomData = (
  category: CategoriesResult,
  effectiveDate?: Moment
): [boolean, Record<string, any>] => {
  switch (category.category) {
    case 'Borrowing Base Certificate': {
      switch (category.subcategory) {
        default:
          return [false, {}]
      }
    }

    case 'Compliance Certificate': {
      switch (category.subcategory) {
        case 'coign-compliance-cert':
          return useCoignComplianceCertData()
        default:
          return [false, {}]
      }
    }

    case 'Financial Covenant Certificate': {
      switch (category.subcategory) {
        default:
          return [false, {}]
      }
    }
    case 'Portfolio Covenant Certificate': {
      switch (category.subcategory) {
        default:
          return [false, {}]
      }
    }
    case 'Unaudited Financial Statements': {
      switch (category.subcategory) {
        default:
          return [false, {}]
      }
    }

    default:
      switch (category.subcategory) {
        case 'coign-backup-interest-report':
          return useCoignBackupInterestReportData(effectiveDate ?? moment.utc())
        case 'orange-bank-compliance-monthly':
          return useOrangeBankComplianceCertData('monthly')
        case 'orange-bank-compliance-quarterly':
          return useOrangeBankComplianceCertData('quarterly')
        case 'orange-bank-payment-demand':
          return useOrangeBankQuarterlyPaymentDemandCertData('quarterly', true)

        default:
          return [false, {}]
      }
  }
}
