import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { DataResponse, RisksFilters } from '@interfaces/analytics-risk'
import { Option, Select } from '@material-tailwind/react'
import CohortService from '@services/api-analytics/risk-cohort'

const RollRatesL5 = ({ exportable }: { exportable: boolean }) => {
  const RETURNED_DATE_FORMAT = 'MMM-YY'
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { rates = {}, display_rates = [] } = optionFilters
  const isAggregate = activeType === 'All'

  const [isVolume, setIsVolume] = useState<boolean>(true)
  const [cohort, setCohort] = useState<string>('All')

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    is_volume: isVolume,
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortRollRates', filters],
    () => CohortService.getRollRates(filters),
    getStaleMins()
  )

  const cohorts = ((data?.data as unknown as any[]) ?? []).reduce(
    (prev: { cohort: string | number }[], cur: { cohort: string }) => {
      return prev.findIndex(
        (x: { cohort: string | number }) => x.cohort === cur.cohort.toString()
      ) < 0
        ? cur.cohort.toLowerCase() === 'all'
          ? [{ cohort: cur.cohort }, ...prev]
          : [...prev, { cohort: cur.cohort }]
        : prev
    },
    []
  )

  cohorts.sort((a: moment.MomentInput, b: moment.MomentInput) => {
    return moment(a, 'MMM-YY').diff(moment(b, 'MMM-YY'))
  })

  const dpds = (data?.labels as unknown as any) ?? []

  const currentData = ((data?.data as unknown as any) ?? []).filter(
    (d: { cohort: string }) => d.cohort === cohort
  )

  const chartData = dpds.map((x: DataResponse) => {
    const currentDPD: any = (currentData ?? []).find(
      (_x: { dpd_bucket: number }) => _x.dpd_bucket === x.id
    )
    const res: { x: string; [key: string]: number | string } = {
      x: x.val.toString(),
    }
    if (currentDPD) {
      Object.keys(currentDPD).forEach(c => {
        res[c] = currentDPD[c] ?? 0
      })
    }

    return res
  })
  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [cohort, data])
  /** end */

  const maxCohort = moment.max(
    cohorts
      .filter((c: { cohort: moment.MomentInput }) =>
        moment.utc(c.cohort, RETURNED_DATE_FORMAT).isValid()
      )
      .map((c: { cohort: moment.MomentInput }) =>
        moment.utc(c.cohort, RETURNED_DATE_FORMAT)
      )
  )
  const conversionCohort = moment.utc(cohort, RETURNED_DATE_FORMAT).isValid()
    ? moment.utc(cohort, RETURNED_DATE_FORMAT)
    : maxCohort

  return (
    <L5ChartstatLayout
      chart={
        <div className="flex flex-col gap-6">
          <div className="flex justify-end -mt-12">
            {[
              ...(data
                ? [
                    {
                      type: 'dropdown',
                      title: 'Cohort',
                      value: cohort,
                      options: cohorts.map(
                        (c: { cohort: moment.MomentInput }) => ({
                          value: c.cohort,
                          title:
                            c.cohort?.toString().toLowerCase() === 'all'
                              ? 'All'
                              : c.cohort,
                        })
                      ),
                      action: (val: string) => setCohort(val),
                    },
                  ]
                : []),
              {
                type: 'toggle',
                title: 'Measurement',
                options: [
                  {
                    title: '# of Loans',
                    active: !isVolume,
                    action: () => setIsVolume(false),
                  },
                  {
                    title: 'Volume',
                    active: isVolume,
                    action: () => setIsVolume(true),
                  },
                ],
              },
            ].map((group, i) => {
              switch (group.type) {
                case 'dropdown':
                  return (
                    <div key={i} className="flex flex-col ml-4 min-w-[150px]">
                      <span className="mb-2 text-sm font-semibold">
                        {group.title}
                      </span>
                      <Select
                        value={group.value}
                        onChange={(val: any) => group.action?.(val)}
                      >
                        {group.options.map((o: any) => {
                          return (
                            <Option key={o.value} value={o.value}>
                              {o.title}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                  )
                default:
                  return (
                    <div key={i} className="flex flex-col ml-4">
                      <span className="mb-2 text-sm font-semibold">
                        {group.title}
                      </span>
                      <div className="bg-neutral-border-1 rounded-md p-1 flex">
                        {group.options.map(
                          (
                            o: {
                              active: boolean
                              action: () => void
                              title: string
                            },
                            j: React.Key | null | undefined
                          ) => {
                            const option = o as {
                              active: boolean
                              action: () => void
                              title: string
                            }
                            return (
                              <button
                                key={j}
                                className={`flex-1 text-sm rounded-md px-3 py-1.5 min-w-[100px] ${
                                  option.active
                                    ? 'bg-primary-hover text-white'
                                    : ''
                                }`}
                                onClick={option.action}
                              >
                                {option.title}
                              </button>
                            )
                          }
                        )}
                      </div>
                    </div>
                  )
              }
            })}
          </div>
          <Chart
            loading={isFetching || isProcessing}
            id={`riskCohortRollRates_by_${activeType}`}
            yLabel={!isVolume ? 'Number of Loans' : 'Volume'}
            yFormat="#.00a%"
            xLabel="DPD Bucket"
            xAxisType="CategoryAxis"
            data={chartData}
            series={dpds.map((d: DataResponse) => {
              return {
                label: d.val,
                tooltipValueFormat: '#.00a%',
                type: 'ColumnSeries',
                field: `bucket_${d.id}`,
                isStack: true,
              }
            })}
            exportable={exportable}
            error={error as { message: string }}
          />

          {showHistoricalRateIndicator && isVolume && (
            <div className="flex gap-4 mt-4">
              {display_rates.map((r: any) => (
                <div
                  key={r}
                  className="rounded-full p-2 border border-neutral-border-1 text-xs text-center bg-neutral-surface-1"
                >
                  <span className="font-medium">{`USD:${r}`}</span>
                  {` = 
                  ${Intl.NumberFormat(undefined, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  }).format(
                    rates[
                      conversionCohort
                        .endOf('month')
                        .startOf('date')
                        .toISOString()
                    ]?.[r] ?? 1
                  )}`}
                </div>
              ))}
            </div>
          )}
          <div
            className={`mt-4 overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)] cascade-table`}
          >
            <table className="border-separate border-spacing-0">
              <thead className="sticky top-0 z-10">
                <tr>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[120px] sticky left-0 bg-neutral-white"></th>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[120px] sticky left-[120px] bg-neutral-white">
                    {`${!isVolume ? 'Number of Loans' : 'Volume'}`}
                  </th>
                  {dpds.map((d: DataResponse) => (
                    <th
                      key={d.id}
                      className="p-3 min-w-[120px] border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
                    >
                      {d.val}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dpds.map((d: DataResponse) => {
                  const currentCohort = currentData.find(
                    (x: { dpd_bucket: number }) => x.dpd_bucket === d.id
                  )

                  return (
                    <tr key={d.id}>
                      <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 bg-neutral-white text-sm text-center">
                        {d.val}
                      </td>
                      <td className="p-3 border-r border-b border-neutral-border-1 sticky left-[120px] bg-neutral-white text-sm text-right">
                        {`${Intl.NumberFormat(undefined, {
                          style: 'decimal',
                          maximumFractionDigits: 1,
                          currency,
                          notation: 'compact',
                        }).format(
                          parseFloat(
                            (currentCohort?.['loans_total'] ?? 0).toString()
                          )
                        )} ${!isVolume ? '' : currency}`}
                      </td>
                      {dpds.map((_d: DataResponse) => {
                        const current = currentCohort?.[`bucket_${_d.id}`]
                        return (
                          <td
                            key={_d.id}
                            className="p-3 border-r border-b border-neutral-border-1 text-sm text-right"
                          >
                            {Intl.NumberFormat(undefined, {
                              style: 'percent',
                              maximumFractionDigits: 2,
                            }).format(
                              parseFloat((current ?? 0).toString()) / 100
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      }
    />
  )
}

export default RollRatesL5
