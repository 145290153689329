import React, { useState } from 'react'

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

interface MultiLevelTableProps {
  dataSet: any
  currency: string
  showTotals?: boolean
  itemsTitle?: string
  headerText?: string
}
interface Info {
  item: string
  info: any
  values: number[]
}
const MultiLevelTable = ({
  dataSet,
  currency,
  showTotals,
  itemsTitle,
  headerText,
}: MultiLevelTableProps) => {
  const [expanded, setExpanded] = useState<string[]>([])
  const [hover, setHover] = useState<string | undefined>()

  const onExpand = (item: string) => {
    const items = expanded.includes(item)
      ? expanded.filter(i => i !== item)
      : [...expanded, item]
    setExpanded(items)
  }

  const zoneNumbers = (num: number) => {
    const value = Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'code',
      currencySign: 'accounting',
      currency: currency,
    }).format(num)
    return value
  }

  return (
    <div className="w-full overflow-x-auto">
      {headerText}
      <table className="w-full">
        <thead>
          <tr>
            <th className="border-r-2 bg-white sticky left-0 bg-neutral-white text-sm text-neutral-subtitle-3 font-bold text-end p-2 text-inline">
              {itemsTitle}
            </th>
            {dataSet.cols?.map((date: any, i: number) => (
              <th
                key={i}
                className="whitespace-nowrap text-sm text-neutral-subtitle-3 font-bold text-end p-2 w-fit"
              >
                {date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSet.data?.map((di: any, i: number) => {
            const isExpanded = expanded.includes(di.item)
            const Icon = isExpanded ? ChevronDownIcon : ChevronRightIcon
            const expandable = !!di.info?.length
            return (
              <>
                <tr
                  key={i}
                  className={`text-sm border-b border-neutral-border-1 ${
                    !expandable && 'border-y-2 border-neutral-border-3'
                  }`}
                  onMouseEnter={() => setHover(di.item)}
                  onMouseLeave={() => setHover('')}
                >
                  <td
                    className={`${
                      hover === di.item && 'bg-primary-surface-2 z-[1]'
                    } border-r-2 bg-white sticky left-0 flex cursor-pointer font-bold ${
                      !expandable ? 'pl-10 py-2' : 'p-2'
                    }`}
                    onClick={() => expandable && onExpand(di.item)}
                  >
                    {!!di.info?.length && <Icon className="w-4 mx-2" />}
                    {di.item.toUpperCase()}
                  </td>
                  {di.values.map((mi: number, j: number) => (
                    <td
                      key={j}
                      className={`${
                        hover === di.item && 'bg-primary-surface-2 z-[1]'
                      } text-sm font-bold text-end p-2 ${
                        isExpanded && showTotals && 'hidden'
                      }`}
                    >
                      {zoneNumbers(mi)}
                    </td>
                  ))}
                </tr>
                {expanded.includes(di.item) &&
                  di.info?.map((p: any, x: number) => {
                    const isExpanded = expanded.includes(p.item)
                    const Icon = isExpanded ? ChevronDownIcon : ChevronRightIcon
                    const expandable = !!p.info?.length
                    return (
                      <>
                        <tr
                          key={x}
                          className={`bg-neutral-white border-b border-neutral-border-1`}
                          onMouseEnter={() => setHover(p.item)}
                          onMouseLeave={() => setHover('')}
                        >
                          <td
                            className={`${
                              hover === p.item && 'bg-primary-surface-2 z-[1]'
                            } border-r-2 bg-white sticky left-0  flex  text-start py-2  ${
                              expandable
                                ? 'pl-8 cursor-pointer font-bold text-sm'
                                : 'pl-14 font-medium text-xs'
                            }`}
                            onClick={() => !!p.info?.length && onExpand(p.item)}
                          >
                            {expandable && <Icon className="w-4 mx-2" />}
                            {p.item}
                          </td>
                          {p.values.map((pi: number, i: number) => (
                            <td
                              key={i}
                              className={`${
                                hover === p.item && 'bg-primary-surface-2 z-[1]'
                              } text-xs text-end p-2 font-medium ${
                                isExpanded && showTotals && 'hidden'
                              } ${expandable && 'text-sm font-bold'}`}
                            >
                              {zoneNumbers(pi)}
                            </td>
                          ))}
                        </tr>
                        {!!p.info?.length &&
                          expanded.includes(p.item) &&
                          p.info.map(
                            (pi: Info, i: number) =>
                              !pi.item.includes('Total') && (
                                <tr
                                  key={i}
                                  className="border-b border-neutral-border-1"
                                  onMouseEnter={() => setHover(pi.item)}
                                  onMouseLeave={() => setHover('')}
                                >
                                  <td
                                    className={`${
                                      hover === pi.item &&
                                      !expanded.includes(pi.item) &&
                                      'bg-primary-surface-2 z-[1]'
                                    } pr-4 border-r-2 bg-white sticky left-0 flex text-xs text-start pl-20 py-2 font-medium`}
                                  >
                                    {pi.item}
                                  </td>
                                  {pi.values.map((ni: number, i: number) => (
                                    <td
                                      key={i}
                                      className={`${
                                        hover === pi.item &&
                                        'bg-primary-surface-2 z-[1]'
                                      } text-xs text-end p-2 font-medium`}
                                    >
                                      {zoneNumbers(ni)}
                                    </td>
                                  ))}
                                </tr>
                              )
                          )}
                        {showTotals &&
                          !!p.info?.length &&
                          expanded.includes(p.item) && (
                            <tr
                              className={`border-y border-neutral-border-2`}
                              onMouseEnter={() => setHover('t' + p.item)}
                              onMouseLeave={() => setHover('')}
                            >
                              <td
                                className={`${
                                  hover === 't' + p.item &&
                                  'bg-primary-surface-2 z-[1]'
                                }
                                   border-r-2 bg-white sticky left-0 flex pl-16 py-2 text-sm font-bold`}
                              >
                                {`Total ${p.item}`}
                              </td>
                              {p.values.map((li: number, i: number) => (
                                <td
                                  key={i}
                                  className={`${
                                    hover === 't' + p.item &&
                                    'bg-primary-surface-2 z-[1]'
                                  } text-sm text-end font-bold p-2`}
                                >
                                  {zoneNumbers(li)}
                                </td>
                              ))}
                            </tr>
                          )}
                      </>
                    )
                  })}
                {showTotals && expanded.includes(di.item) && (
                  <tr
                    className="border-y border-neutral-border-3"
                    onMouseEnter={() => setHover('t' + di.item)}
                    onMouseLeave={() => setHover('')}
                  >
                    <td
                      className={`${
                        hover === 't' + di.item && 'bg-primary-surface-2 z-[1]'
                      } py-2 flex border-r-2 bg-white sticky left-0 pl-10 text-sm font-bold`}
                    >
                      {`total ${di.item}`.toUpperCase()}
                    </td>
                    {di.values.map((ti: number, i: number) => (
                      <td
                        key={i}
                        className={`${
                          hover === 't' + di.item &&
                          'bg-primary-surface-2 z-[1]'
                        } text-sm text-end font-bold p-2`}
                      >
                        {zoneNumbers(ti)}
                      </td>
                    ))}
                  </tr>
                )}
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
export default MultiLevelTable
