import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import ExchangeRateBadge from '@components/exchange-rate-badge'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import Table from '@components/table'
import { AVG_MONTH_THRESHOLD_DAYS } from '@constants/app'
import { REMOUNT_MS } from '@constants/config'
import { Role } from '@constants/role'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { Switch, Tooltip } from '@material-tailwind/react'
import FilterService from '@services/api-analytics/risk-filter'
import { TractionService } from '@services/api-analytics/risk-traction'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const BalanceL5 = () => {
  const { userCompanyAccess, role } = useUserAccessFeature()
  const activeAccess = userCompanyAccess?.access ?? {}
  const FEATURE = `analytics_risk_traction_balance`

  const [isTotal, setIsTotal] = useState<boolean>(false)
  const [isAdjusted, setIsAdjusted] = useState<boolean>(false)
  const { showTable, setShowTable } = useContext(ApplicationCustomContext)

  const exportable =
    role !== Role.custom
      ? true
      : role === Role.custom &&
        activeAccess[
          `${FEATURE}_${isTotal ? 'balance-volume' : '#-of-loans'}`
        ].includes('export')

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    currency = 'USD',
    activeType,
  } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = {},
    display_rates = [],
  } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const avgFilters = {
    slug_name: company?.slug_name ?? '',
    filters: categoryTypes,
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    ...avgFilters,
    filters: isAggregate ? [] : categoryTypes,
    is_aggregate: isAggregate,
    is_adjusted: isTotal ? false : isAdjusted,
    currency,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['balance', isTotal, filters],
    () =>
      isTotal
        ? TractionService.getTotalLoans(filters)
        : TractionService.getOutstandingBalance(filters),
    {
      ...getStaleMins(),
      enabled: avgTermIsFetched,
    }
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result = historicalExchgList(rates, c, c.cohort)
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [currency, data])

  const series = isAggregate
    ? [
        {
          label: 'All',
          field: 'type_all',
          tooltipLabel: isTotal ? `Loan Count` : `Balance (${currency})`,
          type: 'ColumnSeries',
        },
      ]
    : (categoryTypes || []).map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: typeVal?.type,
          tooltipLabel: `${typeVal?.type} ${isTotal ? `` : `(${currency})`}`,
          field: `type_${ct}`,
          isStack: true,
          isTotal: false,
          color,
          type: 'ColumnSeries',
        }
      })

  const table_columns = [
    {
      title: 'Month',
      field: 'x',
      align: 'center',
      width: 200,
      render: (r: any) => {
        return moment.utc(r.x).format('YYYY-MM-DD')
      },
    },
    ...series.map((s: any) => {
      return {
        width: 200,
        align: 'right',
        field: s.field,
        title: s.label,
        render: (r: any) => numberFormatter(r[s.field]),
      }
    }),
  ]

  const showAdjustedToggle = avgTermIsFetched
    ? Number(avgTerm ?? 0) <= AVG_MONTH_THRESHOLD_DAYS
    : false

  return (
    <div className="[&>div>div:nth-child(2)]:mt-10">
      <L5ChartstatLayout
        chart={
          <>
            <div className="flex mb-8 justify-between">
              <div className="flex justify-center items-center">
                <Tooltip
                  content={
                    <span>
                      Description:
                      <br />
                      {isTotal
                        ? 'The number of loans with an outstanding balance > 0 (not written-off)'
                        : 'Sum of to_principal (schedules) - to_principal (payments) for all non-written off loans at a given date'}
                      <br />
                      <br />
                      Outstanding Balance:
                      <br />
                      All unpaid principal as of a specific date.
                      <br />
                      <br />
                      {showAdjustedToggle && (
                        <>
                          Adjusted Outstanding Balance:
                          <br />
                          All unpaid principal as of specific date + paid
                          principal for loans disbursed and paid in same month
                          <br />
                          <br />
                        </>
                      )}
                      Formula:
                      <br />
                      {isTotal
                        ? 'Count of loans with outstanding_balance > 0 at a given date'
                        : 'Sum of to_principal (schedules) - to_principal (payments) for all non-written off loans at a given date'}
                      <br />
                      <br />
                      Write Offs = {company?.writeoff_days} days
                      <br />
                    </span>
                  }
                  placement="right"
                >
                  <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
                </Tooltip>
                {showHistoricalRateIndicator && !isTotal && (
                  <ExchangeRateBadge />
                )}
              </div>
              <div className="gap-4 flex flex-col 2xl:flex-row">
                {[
                  ...(showAdjustedToggle
                    ? [
                        {
                          options: [
                            {
                              title: 'Outstanding',
                              active: !isAdjusted,
                              action: () => setIsAdjusted(false),
                            },
                            {
                              title: 'Adjusted Outstanding',
                              active: isAdjusted,
                              action: () => setIsAdjusted(true),
                            },
                          ],
                        },
                      ]
                    : []),
                  {
                    options: [
                      {
                        title: 'Balance Volume',
                        active: !isTotal,
                        action: () => setIsTotal(false),
                      },
                      {
                        title: '# of Loans',
                        active: isTotal,
                        action: () => setIsTotal(true),
                      },
                    ].filter(t => {
                      return role !== Role.custom
                        ? true
                        : role === Role.custom &&
                            Object.keys(activeAccess).find(
                              aa =>
                                aa.includes(
                                  `${FEATURE}_${t.title
                                    .toLowerCase()
                                    .split(' ')
                                    .join('-')}`
                                ) && activeAccess[aa].includes('access')
                            )
                    }),
                  },
                ].map((group, i) => (
                  <div key={i} className={`flex `}>
                    <div className="bg-neutral-border-1 rounded-md p-1 flex">
                      {group.options.map((g, j) => (
                        <button
                          key={j}
                          className={`block text-sm rounded-md px-8 py-0.5 font-semibold ${
                            g.active ? 'bg-secondary-main text-white' : ''
                          }`}
                          onClick={g.action}
                        >
                          {g.title}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Chart
              loading={isFetching || isProcessing || avgTermIsFetching}
              data={chartData}
              id={`riskTractionBalance${
                isTotal ? '_total' : '_'
              }by_${activeType}`}
              yLabel={isTotal ? 'Count' : currency}
              series={series}
              tooltipSubtitle={
                showHistoricalRateIndicator && !isTotal
                  ? displayRates(display_rates)
                  : undefined
              }
              error={error as { message: string }}
              exportable={exportable}
              exportableColumn={table_columns}
            />
            <div className="flex justify-end mt-8">
              <Switch
                label="Show Data"
                checked={showTable}
                onChange={() => {
                  setShowTable(!showTable)
                }}
                defaultChecked
                color="light-blue"
                crossOrigin={undefined}
              />
            </div>
            {showTable && (
              <Table
                containerClass="mt-8"
                loading={isFetching || isProcessing}
                columns={table_columns}
                data={chartData}
              />
            )}
          </>
        }
        stat={<StatisticL5 {...growthRatios} />}
      />
    </div>
  )
}

export default BalanceL5
