import React from 'react'

import { AdvanceRequestResponse } from '@interfaces/manage-monitor-action'

const statuses = {
  pending: 'Pending',
  signed: 'Signed / Not Funded',
  funded: 'Funded',
  nullified: 'Nullified',
}

const StatusLabel = ({ request }: { request: AdvanceRequestResponse }) => {
  const status = request.status
    ? statuses[request.status as keyof typeof statuses]
    : null
  return (
    <div className="flex justify-center">
      <span
        className={`px-3 py-1 font-medium rounded-full capitalize text-xs ${
          request.status === 'funded'
            ? 'text-success-main border border-success-main  bg-success-main/10'
            : request.status === 'pending'
            ? 'text-warning-main border-warning-border bg-warning-surface'
            : request.status === 'signed'
            ? 'text-white font-medium bg-purple'
            : request.status === 'nullified'
            ? 'text-cc-primary-gray-medium border border-cc-primary-gray-medium bg-cc-primary-gray-medium/10'
            : ''
        }`}
      >
        {status}
      </span>
    </div>
  )
}

export default StatusLabel
