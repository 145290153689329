import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import CollectionCurveL5 from './collection-curve'

const CollectionCurveL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'All Payments',
      desc: `All payments collected for loans disbursed in a given month (principal, interest, fees, others) divided by the original principal balance.`,
      component: (
        <CollectionCurveL5 exportable={exportable} type={'scheduled'} />
      ),
    },
    {
      label: 'Principal Only',
      desc: `All principal payments collected for loans disbursed in a given month divided by the original principal balance.`,
      component: (
        <CollectionCurveL5 exportable={exportable} type={'principal'} />
      ),
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default CollectionCurveL4
