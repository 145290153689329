import React from 'react'

import {
  CategoriesResult,
  FinancialCovenantTemplateProps,
} from '@interfaces/manage-reporting'

// import FsvnFinancialCovenantTemplate from './fsvn/fsvn-financial-covenant-template'
import FinancialCovenantTemplate from './default'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: FinancialCovenantTemplateProps
) => {
  switch (category.subcategory) {
    // case 'fsvn-alteriq': {
    //   return <FsvnFinancialCovenantTemplate {...templateProps} />
    // }

    case 'default':
    default: {
      return <FinancialCovenantTemplate {...templateProps} />
    }
  }
}
