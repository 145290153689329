import React from 'react'

import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import MonitorDonutChartItem from './items/donut-chart'
import MonitorDashboardGroupedGraphItem from './items/grouped-graph'
import MonitorDashboardHorizontalColumnGraphItem from './items/horizontal-column-graph'
import MonitorDashboardMultilineGraphItem from './items/multiline-graph'
import MonitorDashboardStackedAreaItem from './items/stacked-area'
import MonitorDashboardStackedGraphItem from './items/stacked-graph'
import MonitorDashboardTableItem from './items/table'
import CovenantL4 from './covenant'

const MonitorDashboardTabL4 = ({ items }: { items: DashboardResponse[] }) => {
  const _renderItem = (i: DashboardResponse) => {
    switch (i.display) {
      case 'multiline_graph':
        return <MonitorDashboardMultilineGraphItem item={i} />
      case 'total_stacked_graph':
      case 'stacked_graph':
        return <MonitorDashboardStackedGraphItem item={i} />
      case 'grouped_graph':
        return <MonitorDashboardGroupedGraphItem item={i} />
      case 'donut_chart':
        return <MonitorDonutChartItem item={i} />
      case 'stacked_area':
      case 'total_stacked_area':
      case 'line_graph':
        return <MonitorDashboardStackedAreaItem item={i} />
      case 'covenant':
        return <CovenantL4 type={i.display_type as any} />
      case 'table':
        return <MonitorDashboardTableItem item={i} />
      case 'horizontal_column_graph':
        return <MonitorDashboardHorizontalColumnGraphItem item={i} />
      case 'combo_series':
        return <MonitorDashboardGroupedGraphItem item={i} />
      default:
        return <></>
    }
  }
  return (
    <div className="flex flex-col gap-8">
      {items
        .sort((a, b) => (a.dashboard_id < b.dashboard_id ? -1 : 1))
        .map(i => _renderItem(i))}
    </div>
  )
}
export default MonitorDashboardTabL4
