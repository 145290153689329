import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import Chart from '@components/chart'
import ExchangeRateBadge from '@components/exchange-rate-badge'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import Table from '@components/table'
import { AVG_MONTH_THRESHOLD_DAYS } from '@constants/app'
import { REMOUNT_MS } from '@constants/config'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { useGetRiskData } from '@helpers/risk-charts-data-hook'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { DataResponse, RiskData } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { Switch, Tooltip } from '@material-tailwind/react'
import { CollectionService } from '@services/api-analytics/risk-collection'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const NetYieldL5 = ({ exportable }: { exportable: boolean }) => {
  const [isAdjusted, setIsAdjusted] = useState<boolean>(false)

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { categoryTypes = [], activeType, currency = 'USD' } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = {},
    display_rates = [],
  } = optionFilters
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const { showTable, setShowTable } = useContext(ApplicationCustomContext)

  const {
    fetchedDataAggregated,
    data,
    growthRatios,
    cohort,
    avgTerm,
    avgTermIsFetched,
    error,
    isFetching,
    isAggregate,
  } = useGetRiskData(CollectionService.getNetYield, 'net_yield', true, true, {
    is_adjusted: isAdjusted,
  })

  let minRange = 0
  let maxRange = 0

  const chartData = cohort.map((c: RiskData<DataResponse>) => {
    const result = historicalExchgList(rates, c, c.cohort)
    const cohortData = (data || []).filter(
      (d: RiskData<DataResponse>) => d.cohort === c.cohort
    )
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      result.type_all_outstanding_balance = parseFloat(
        (
          cohortData?.[0]?.[
            `${isAdjusted ? 'adjusted_' : ''}outstanding_balance`
          ] ?? 0
        ).toString()
      )
      result.type_all_non_principal_payments = parseFloat(
        (cohortData?.[0]?.non_principal_payments ?? 0).toString()
      )
      result.type_all_new_written_off = parseFloat(
        (cohortData?.[0]?.new_written_off ?? 0).toString()
      )
      if (result.type_all < minRange) {
        minRange = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find((cd: RiskData<DataResponse>) => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        result[
          `type_${ct}_${isAdjusted ? 'adjusted_' : ''}outstanding_balance`
        ] = parseFloat((cohortCategory?.outstanding_balance ?? 0).toString())
        result[`type_${ct}_non_principal_payments`] = parseFloat(
          (cohortCategory?.non_principal_payments ?? 0).toString()
        )
        result[`type_${ct}_new_written_off`] = parseFloat(
          (cohortCategory?.new_written_off ?? 0).toString()
        )
        if (result[`type_${ct}`] < minRange) {
          minRange = result[`type_${ct}`]
        }
        if (result[`type_${ct}`] > maxRange) {
          maxRange = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const netYieldSeries: any[] = isAggregate
    ? [
        {
          label: 'Net Yield',
          tooltipValueFormat: '#.00a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.00a%',
          field: `type_${ct}`,
          color,
          type: 'SmoothedXLineSeries',
          isStack: false,
        }
      })

  const header_data = [
    netYieldSeries.reduce((p: any, s: any) => {
      return {
        ...p,
        [`${s.field}_non_principal_payments`]: 'Non-principal Payments',
        [`${s.field}_new_written_off`]: 'New Write-offs',
        [`${s.field}_outstanding_balance`]: `Outstanding Balance`,
        [s.field]: 'Net Yield (%)',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...netYieldSeries.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_non_principal_payments`,
          title: header_data[0][`${s.field}_non_principal_payments`],
          head: { colSpan: 0 },
          render: (r: any) =>
            numberFormatter(r[`${s.field}_non_principal_payments`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_new_written_off`,
          title: header_data[0][`${s.field}_new_written_off`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_new_written_off`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_outstanding_balance`,
          title: header_data[0][`${s.field}_outstanding_balance`],
          head: { colSpan: 0 },
          render: (r: any) =>
            numberFormatter(r[`${s.field}_outstanding_balance`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `Net Yield (%) ${isAggregate ? '' : `(${s.label})`}`,
          head: { colSpan: 4, align: 'center' },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]

  const showAdjustedToggle = avgTermIsFetched
    ? Number(avgTerm ?? 0) <= AVG_MONTH_THRESHOLD_DAYS
    : false

  return (
    <div className="[&>div>div:nth-child(2)]:mt-10">
      <L5ChartstatLayout
        chart={
          <>
            <div className="flex mb-8 justify-between">
              <div className="flex justify-center items-center">
                <Tooltip
                  content={
                    <span>
                      Description:
                      <br />
                      The net profits a loan portfolio is generating each month.
                      <br />
                      <br />
                      Outstanding Balance:
                      <br />
                      All unpaid principal as of a specific date.
                      <br />
                      <br />
                      {showAdjustedToggle && (
                        <>
                          Adjusted Outstanding Balance:
                          <br />
                          All unpaid principal as of specific date + paid
                          principal for loans disbursed and paid in same month
                          <br />
                          <br />
                        </>
                      )}
                      Formula:
                      <br />
                      [(non-principal payments - new writeoff) in a month / end
                      of month outstanding balance] x 12
                      <br />
                      <br />
                      Write Offs = {company?.writeoff_days} days
                      <br />
                    </span>
                  }
                  placement="right"
                >
                  <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
                </Tooltip>
                {showHistoricalRateIndicator && <ExchangeRateBadge />}
              </div>
              <div className="gap-4 flex flex-col 2xl:flex-row">
                {[
                  ...(showAdjustedToggle
                    ? [
                        {
                          options: [
                            {
                              title: 'Outstanding Balance',
                              active: !isAdjusted,
                              action: () => setIsAdjusted(false),
                            },
                            {
                              title: 'Adjusted Outstanding Balance',
                              active: isAdjusted,
                              action: () => setIsAdjusted(true),
                            },
                          ],
                        },
                      ]
                    : []),
                ].map((group, i) => (
                  <div key={i} className={`flex `}>
                    <div className="bg-neutral-border-1 rounded-md p-1 flex">
                      {group.options.map((g, j) => (
                        <button
                          key={j}
                          className={`block text-sm rounded-md px-8 py-0.5 font-semibold ${
                            g.active ? 'bg-secondary-main text-white' : ''
                          }`}
                          onClick={g.action}
                        >
                          {g.title}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Chart
              loading={isFetching || isProcessing}
              id={`riskCollectionNetYield_by_${activeType}`}
              yLabel="Percentage"
              ySetting={
                minRange < 0 ? { min: minRange - 5, maxPrecision: 1 } : {}
              }
              yFormat="#.00a%"
              data={chartData}
              series={netYieldSeries as any}
              tooltipSubtitle={
                showHistoricalRateIndicator
                  ? displayRates(display_rates)
                  : undefined
              }
              exportable={exportable}
              exportableColumn={table_columns}
              error={error as { message: string }}
            />
            <div className="flex justify-end mt-8">
              <Switch
                label="Show Data"
                checked={showTable}
                onChange={() => {
                  setShowTable(!showTable)
                }}
                defaultChecked
                color="light-blue"
                crossOrigin={undefined}
              />
            </div>
            {showTable && (
              <Table
                containerClass="mt-8 [&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
                loading={isFetching || isProcessing}
                data={chartData}
                headerData={header_data}
                columns={table_columns}
              />
            )}
          </>
        }
        stat={<StatisticL5 {...growthRatios} />}
      />
    </div>
  )
}

export default NetYieldL5
