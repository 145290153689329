import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import Typography from '@components/atoms/typography'
import Chart from '@components/chart'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import { useDashboardData } from '../helper'

const MonitorDashboardHorizontalColumnGraphItem = ({
  item,
}: {
  item: DashboardResponse
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate } = appliedFilters
  const {
    chartData,
    chartSeries,
    unit,
    xAxisType,
    displayName,
    yAxisLabel,
    yAxisFormat,
    dashboardId,
    title,
    xAxisLabel,
    dateFilterField,
    ySetting,
  } = useDashboardData(item)

  /** simulate processing to remount chart component when filters change */
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [appliedFilters])

  return (
    <>
      {displayName && (
        <Typography className="text-center text-lg font-semibold mt-8">
          {displayName}{' '}
          {dateFilterField &&
            `as of ${moment.utc(calculationDate).format('YYYY-MM-DD')}`}
        </Typography>
      )}
      <Chart
        data={chartData}
        series={chartSeries as any}
        loading={isProcessing}
        yLabel={`${yAxisLabel} ${unit ? `(${unit})` : ''}`}
        yFormat={yAxisFormat ?? '#.00a'}
        id={`${title}-${dashboardId}` ?? ''}
        xAxisType={xAxisType}
        ySetting={ySetting}
        xLabel={xAxisLabel ?? ''}
        exportable={false}
        isHorizontal={true}
      />
    </>
  )
}
export default MonitorDashboardHorizontalColumnGraphItem
