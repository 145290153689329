import { useContext } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { useMutation } from 'react-query'

import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { Facility } from '@interfaces/facility'
import { CollateralListUploadRequest } from '@interfaces/manage-monitor-borrowing-base'
import { CovenantRawResponse } from '@interfaces/manage-monitor-covenant'
import { ActionService } from '@services/api-manage/monitor-action'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

export const useCreateNegativeAdvanceRequest = () => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )
  const { displayToastError } = useToastNotification()

  const { mutate: createAR } = useMutation(
    (params: FormData) => {
      return ActionService.createAdvanceRequest(
        company?.slug_name ?? '',
        activeFacility?.facility_id,
        params
      )
    },
    {
      onError: (err: AxiosError) => {
        return displayToastError(
          err.response?.status,
          'Collateral file upload failed'
        )
      },
    }
  )

  const { mutate: uploadFile } = useMutation(
    (params: CollateralListUploadRequest) => {
      return BorrowingBaseService.uploadCollateralReportData(params)
    },
    {
      onError: (err: AxiosError) => {
        return displayToastError(
          err.response?.status,
          'Collateral file upload failed'
        )
      },
    }
  )

  const afterEffect = (
    effectiveDate: string,
    customData: Record<string, any>
  ) => {
    const covData = customData?.covenantData?.find(
      (a: CovenantRawResponse) =>
        moment.utc(a.cohort_date).format('YYYY-MM-DD') === effectiveDate
    )

    const formData = new FormData()
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facility', activeFacility?.facility_name ?? '')
    formData.append('date', moment.utc(effectiveDate).format('YYYY-MM-DD'))
    formData.append('currency', customData.currency)
    const amount =
      Number(covData?.charge_off_amount_paidback?.replaceAll(',', '')) * -1 || 0
    formData.append('amount', amount.toString())
    const lenders_info = activeFacility?.lenders_info
      ? JSON.parse(activeFacility.lenders_info)
      : {}
    Array(Number(activeFacility?.lender ?? 0))
      .fill('')
      ?.forEach((l: any, i: number) => {
        const ratio =
          lenders_info[`lender_${i + 1}`]?.[
            `lender_${i + 1}_advance_request_ratio`
          ]
        const payoutAmount = l.payout_amount || amount * ratio
        formData.append(`lenders[${i}][payout_amount]`, payoutAmount)
        formData.append(`lenders[${i}][lender_ratio]`, ratio.toString())
      })

    formData.append('status', 'signed')

    createAR(formData)
    if (customData.fileUrl) {
      uploadFile({
        facility_id: activeFacility?.facility_id,
        slug_name: activeFacility?.slug_name ?? company?.slug_name,
        calculation_date: moment(effectiveDate)
          .endOf('month')
          .format('YYYY-MM-DD'),
        file_name: customData.fileUrl,
      })
    }
  }

  return { afterEffect }
}
