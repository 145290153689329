import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'

import Button from '@components/atoms/button'
import DynamicMenu from '@components/dynamic-menu'
import Pagination from '@components/pagination'
import CustomTable from '@components/table'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowDownTrayIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import {
  FinancialUpload,
  FinancialUploadStatus,
} from '@interfaces/accounting/financials'
import { Alert, Tooltip } from '@material-tailwind/react'
import { AccountingService } from '@services/api-admin/data-source-accounting'

import FinancialUploadsStatusLabel from './status-label'

const AccoutingUploadsL5 = () => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const { company, appliedFilters } = useContext(AuthContext)
  const { displayToastError } = useToastNotification()

  const {
    dateStart,
    dateEnd,
    activeEntityId: appliedActiveEntityId,
    financialUploadStatus,
  } = appliedFilters

  const PER_PAGE = 10
  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const filters = {
    slugName: company?.slug_name ?? '',
    page: page,
    size: perPage,
    legalEntityID: appliedActiveEntityId,
    status: financialUploadStatus,
    startDate: moment.utc(dateStart).format('YYYY-MM-DD'),
    endDate: moment.utc(dateEnd).format('YYYY-MM-DD'),
  }

  const {
    data: uploadHistoryData,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ['allEntities', filters],
    () => AccountingService.listFinancialsHistory(filters),
    { ...getStaleMins(), enabled: !!appliedActiveEntityId }
  )

  const { mutate: reprocess } = useMutation(
    async () => {
      return AccountingService.reprocessFinancials({
        legalEntityID: appliedActiveEntityId,
        slugName: company?.slug_name ?? '',
      })
    },
    {
      onSuccess: () => {
        refetch()
      },
      onError: () => {
        refetch()
      },
    }
  )

  const { mutate: downloadFile } = useMutation(
    (upload: any) => {
      const params = {
        slugName: company?.slug_name ?? '',
        pathName: upload.s3Key,
      }
      return AccountingService.downloadDoc(params)
    },
    {
      onSuccess: data => {
        window.open(data, '_blank')
      },
      onError: (err: AxiosError) => displayToastError(err.response?.status),
    }
  )

  const expandedRowRender = (record: any) => {
    return (
      <div className="[&>div]:!mb-0 mx-40">
        <div className="flex grid grid-cols-2 gap-2 text-xs font-light">
          <div>Date Range</div>
          <div>
            {record.date_start} - {record.date_end}
          </div>
          <div>Historical Count Difference</div>
          <div>{record.diff_count}</div>
          <div>Download Links</div>
          <div className="flex flex-col text-blue">
            <div className="flex grid grid-cols-3">[Original Upload]</div>
            <div className="flex grid grid-cols-3">[Delta Log]</div>
          </div>
        </div>
      </div>
    )
  }

  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }

  const columns = [
    {
      title: 'File Name',
      align: 'start',
      field: 'fileName',
      className: 'border-none',
    },
    {
      title: 'Submitted',
      align: 'start',
      field: 'dateUploaded',
      className: 'border-none',
      render: (upload: FinancialUpload) => {
        return <div>{moment.utc(upload.dateUploaded).format('YYYY-MM-DD')}</div>
      },
    },
    {
      title: 'Status',
      align: 'start',
      field: 'state',
      className: 'border-none',
      render: (upload: FinancialUpload) => {
        return <FinancialUploadsStatusLabel upload={upload} />
      },
    },
    {
      title: '',
      align: 'center',
      field: '',
      className: 'border-none',

      render: (upload: FinancialUpload) => {
        const entityMenuItems = [
          ...(upload.state === FinancialUploadStatus.INREVIEW
            ? [
                {
                  icon: <ArrowPathIcon className="w-4" />,
                  label: 'Reprocess',
                  provider: () => {
                    reprocess()
                  },
                },
              ]
            : []),
          {
            icon: <ArrowDownTrayIcon className="w-4" />,
            label: 'Download',
            provider: () => {
              downloadFile(upload)
            },
          },
        ]

        return <DynamicMenu menuItems={entityMenuItems} />
      },
    },
  ]

  useEffect(() => {
    refetch()
  }, [appliedActiveEntityId])

  return (
    <div className="mx-5">
      <div className="flex w-full justify-end my-4">
        <Tooltip content={<div>Refresh</div>} placement="top">
          <div>
            <Button className="mx-2" onClick={() => refetch()}>
              <ArrowPathIcon className={'w-7'} />
            </Button>
          </div>
        </Tooltip>
      </div>
      {isLoading ? (
        <div className="w-full flex justify-center mt-2">
          {' '}
          <ArrowPathIcon className="w-7 animate-spin" />
        </div>
      ) : error ? (
        <div>
          <Alert className="border border-danger-main text-danger-main text-center mb-6">
            {((error as AxiosError).response?.data as any)?.error ??
              (error as AxiosError).message}
          </Alert>
        </div>
      ) : !!uploadHistoryData?.body?.length ? (
        <>
          <CustomTable
            data={uploadHistoryData.body}
            columns={columns as any}
            borderless={true}
            expandable={{
              expandedIndexes,
              expandedRowRender,
              onExpand,
            }}
          />
          {uploadHistoryData?.page?.total > perPage && (
            <Pagination
              id="accounting-uploads-table-pagination"
              total={uploadHistoryData?.page?.total ?? 0}
              page={page}
              onChangePage={p => setPage(p)}
              limit={perPage}
              limits={Array(2)
                .fill('')
                .map((_, i) => PER_PAGE * (i + 1))}
              onChangeLimit={l => {
                setPage(0)
                setPerPage(l)
              }}
            />
          )}
        </>
      ) : (
        <div>
          <div className="w-full mt-6 mb-1 ml-4 text-center">
            No data to display.
          </div>
        </div>
      )}
    </div>
  )
}
export default AccoutingUploadsL5
