import React from 'react'

import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
interface MenuItem {
  icon: any
  label: any
  provider: () => void
}
interface DynamicMenuProps {
  menuItems: MenuItem[]
}

const DynamicMenu = ({ menuItems }: DynamicMenuProps) => {
  return (
    <Menu placement="left-end">
      <MenuHandler>
        <button className="p-2 text-sm cursor-pointer rounded hover:bg-primary-surface-2 hover:text-primary-main">
          <EllipsisHorizontalIcon className="w-4 h-4" />
        </button>
      </MenuHandler>
      <MenuList>
        {menuItems.map(
          (
            item: {
              icon: any
              label: any
              provider: () => void
            },
            idx: number
          ) => {
            return (
              <MenuItem key={idx} className="flex" onClick={item.provider}>
                {item.icon}
                <div className="ml-2"> {item.label} </div>
              </MenuItem>
            )
          }
        )}
      </MenuList>
    </Menu>
  )
}
export default DynamicMenu
