import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { isArray } from 'lodash'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import Button from '@components/atoms/button'
import OptionSearch from '@components/atoms/search'
import FormInput from '@components/form/form-input'
import Stepper from '@components/stepper'
import Step from '@components/stepper/step'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Entity } from '@interfaces/accounting/financials'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { EntityService } from '@services/api-admin/settings-entities'

import { CURRENCY_CODES } from '../../constants'
import { COUNTRIES_LIST } from '../../constants/countries-list'

interface AddEntityDialogProps {
  open: boolean
  selectedEntityInfo: Entity
  handleOpen: () => void
  refetch: () => void
}

interface Country {
  name: string
  code: string
}

const AddEditEntityDialog = ({
  open,
  handleOpen,
  selectedEntityInfo,
  refetch,
}: AddEntityDialogProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm()
  const { company } = useContext(AuthContext)
  const { displayToastError, displayToastSuccess } = useToastNotification()

  const [activeStep, setActiveStep] = useState<number>(0)
  const [isLastStep, setIsLastStep] = useState<boolean>(false)
  const [isFirstStep, setIsFirstStep] = useState<boolean>(false)
  const [selectedCountries, setSelectedCountries] = useState<Country[]>([])
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [countries, setCountries] = useState<Country[]>(COUNTRIES_LIST)

  const onSelect = (countryCode: string) => {
    setShowOptions(false)

    if (countries && countryCode) {
      const selectedCountryIdx = countries.findIndex(
        (country: Country) => country.code === countryCode
      )

      if (selectedCountryIdx >= 0 && Array.isArray(selectedCountries)) {
        const selectedCountry = { ...countries[selectedCountryIdx] }

        const isDuplicate = selectedCountries.some(
          (country: Country) => country.code === selectedCountry.code
        )

        if (!isDuplicate) {
          setSelectedCountries([...selectedCountries, selectedCountry])

          const updatedCountries = countries.filter(
            (_, index) => index !== selectedCountryIdx
          )

          setCountries(updatedCountries)
        }
      }
    }
  }

  const removeCountry = (index: number) => {
    const newCountryList = [...selectedCountries]
    const removed = newCountryList.splice(index, 1)[0]

    setSelectedCountries(newCountryList)
    setCountries(prevCountries => {
      if (
        !prevCountries.some((country: Country) => country.code === removed.code)
      ) {
        return [...prevCountries, removed]
      }
      return prevCountries
    })
  }
  const handleSuccess = () => {
    handleOpen()
    reset
    refetch()
    displayToastSuccess('Operation Successful')
  }
  const handleError = (status?: number, customeMessage?: string) => {
    displayToastError(status, customeMessage)
  }

  const { mutate: createEntity, isLoading: isCreating } = useMutation(
    (data: any) => {
      const params = {
        slugName: company?.slug_name ?? '',
        entityLegalName: data.legalName ?? '',
        entityNickname: data.nickname ?? '',
        geographies: data.geographies ?? [],
        currency: data.currency,
      }
      return EntityService.createEntity(params)
    },
    {
      onSuccess: () => handleSuccess(),
      onError: (err: AxiosError) => handleError(err.response?.status),
    }
  )

  const { mutate: editEntity, isLoading: isEditing } = useMutation(
    (data: any) => {
      const params = {
        ID: selectedEntityInfo.ID,
        slugName: company?.slug_name ?? '',
        entityLegalName: data.legalName ?? '',
        entityNickname: data.nickname ?? '',
        geographies: data.geographies ?? [],
        currency: data.currency,
      }
      return EntityService.updateEntity(params)
    },
    {
      onSuccess: () => handleSuccess(),
      onError: (err: AxiosError) => handleError(err.response?.status),
    }
  )

  const onSubmit = async () => {
    const data = {
      legalName: getValues('legalName'),
      nickname: getValues('nickname'),
      geographies: selectedCountries.map(country => country.code),
      currency: getValues('currency'),
    }
    if (!!selectedEntityInfo) {
      editEntity(data)
    } else {
      createEntity(data)
    }
  }

  const nextDisabled =
    Object.keys(errors).length > 0 ||
    !getValues('legalName') ||
    !getValues('nickname') ||
    !getValues('currency') ||
    !(selectedCountries.length > 0)

  useEffect(() => {
    setCountries(COUNTRIES_LIST)
    setSelectedCountries([])
    reset()
    setActiveStep(0)

    if (!!selectedEntityInfo) {
      setValue('legalName', selectedEntityInfo.entityLegalName)
      setValue('nickname', selectedEntityInfo.entityNickname)
      setValue('currency', selectedEntityInfo.currency)
      selectedEntityInfo.geographies.map((geo: Country['code']) =>
        onSelect(geo)
      )
      const editSelectedCountries: Country[] = []

      COUNTRIES_LIST.forEach((country: Country) => {
        if (selectedEntityInfo.geographies.includes(country.code)) {
          editSelectedCountries.push(country)
        }
      })

      setSelectedCountries(editSelectedCountries)
    }
  }, [handleOpen])

  return (
    <>
      <Dialog
        open={open}
        handler={handleOpen}
        className="flex flex-col h-[90%]"
        size="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>
                {!!selectedEntityInfo
                  ? `Edit Accounting Entity : ${selectedEntityInfo.entityNickname}`
                  : 'Add New  Accounting Entity'}
              </span>
              <XMarkIcon
                onClick={handleOpen}
                className="w-8 h-8 cursor-pointer hover:opacity-50"
              />
            </div>
          </DialogHeader>
          <DialogBody className="mx-5">
            <div className="w-full">
              <Stepper
                setActiveStep={(e: number) => {
                  setActiveStep(e)
                }}
                setIsLastStep={value => setIsLastStep(value)}
                setIsFirstStep={value => setIsFirstStep(value)}
                activeStep={activeStep}
              >
                <Step
                  onClick={() => setActiveStep(0)}
                  isActive={true}
                  title={'Entity Information'}
                >
                  <div className="font-bold my-3">Enter Entity Information</div>
                  <div className="flex w-full justify-center">
                    <div className="flex flex-col w-full">
                      <FormInput
                        label={{
                          start: 'Legal Name*',
                        }}
                        containerClass="shadow-md -mt-1 w-full"
                        type="text"
                        value={getValues('legalName')}
                        {...register('legal_name', {
                          required: {
                            value: true,
                            message: 'This is required',
                          },
                          onChange: (e: any) => {
                            setValue('legalName', e.target.value, {
                              shouldValidate: true,
                            })
                          },
                        })}
                        error={errors?.['legalName']?.message as string}
                      />
                      <FormInput
                        label={{
                          start: 'Nickname*',
                        }}
                        containerClass="shadow-md -mt-1 w-full"
                        type="text"
                        value={getValues('nickname') ?? ''}
                        {...register('nickname', {
                          required: {
                            value: true,
                            message: 'This is required',
                          },
                          onChange: (e: any) => {
                            setValue('nickname', e.target.value, {
                              shouldValidate: true,
                            })
                          },
                        })}
                        error={errors?.['nickname']?.message as string}
                      />
                      <div className="grid grid-cols-3 gap-2">
                        <FormInput
                          value={getValues('currency') ?? ''}
                          containerClass="shadow-lg"
                          label={{
                            start: 'Select Currency*',
                          }}
                          type="select"
                          options={CURRENCY_CODES.map((val: string) => {
                            return {
                              title: val.toString(),
                              value: val,
                            }
                          })}
                          onSelected={val => {
                            clearErrors('currency')
                            setValue('currency', val)
                          }}
                          {...register('currency', {
                            required: {
                              value: true,
                              message: 'This is required',
                            },
                          })}
                          error={errors?.['currency']?.message as string}
                        />
                        <div className="col-span-2">
                          <div className="text-neutral-black text-sm pb-2">
                            Select Geography*
                          </div>
                          <div className="w-full z-[9999] relative">
                            {isArray(countries) && (
                              <OptionSearch
                                availableOptions={
                                  countries.map((country: Country) => {
                                    return {
                                      actionIcon: null,
                                      label: country.name,
                                      value: country.code,
                                      disabledHint: '',
                                      displayhint: false,
                                    }
                                  }) ?? [
                                    {
                                      actionIcon: null,
                                      label: 'No Options available',
                                      value: '',
                                      disabledHint: 'No Options available',
                                      displayhint: false,
                                    },
                                  ]
                                }
                                searchParam={'Country'}
                                handleChange={onSelect}
                                showOptions={showOptions}
                              />
                            )}
                          </div>
                          <div className="flex absolute top-96 flex-wrap z-[5000] max-h-[300px] w-[62%] -mt-10">
                            <div className="flex w-full flex-wrap max-h-[150px] overflow-y-auto">
                              {selectedCountries.map(
                                (country: Country, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      className="flex flex-row bg-primary-surface-2 rounded-full px-2 pr-4 py-2 text-sm font-semibold m-2"
                                    >
                                      <div className="flex flex-row ml-1">
                                        <div>{country && country.name}</div>
                                        <div className="ml-2 font-light">
                                          ({country && country.code})
                                        </div>
                                      </div>
                                      <div className="flex flex-col ml-1 justify-center">
                                        <XMarkIcon
                                          className="w-5 cursor-pointer hover:text-primary-main"
                                          onClick={() => removeCountry(idx)}
                                        />
                                      </div>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Step>
                <Step
                  onClick={() => setActiveStep(2)}
                  isActive={false}
                  title={'Submit'}
                >
                  <div className="flex flex-col">
                    <div className="font-bold mt-3">Review & Submit</div>
                    <div className="text-sm font-light mb-10">
                      Review the following information
                    </div>
                    <div className="w-full grid grid-cols-2 gap-2">
                      <span className="text-sm font-bold">Legal Name</span>
                      <span className="text-sm">{getValues('legalName')}</span>
                      <span className="text-sm font-bold">Nickname</span>
                      <span className="text-sm">{getValues('nickname')}</span>
                      <span className="text-sm font-bold">Currency</span>
                      <span className="text-sm">{getValues('currency')}</span>
                      <span className="text-sm font-bold">Geography</span>
                      <div className="flex flex-wrap max-h-[300px] w-full -ml-3">
                        <div className="flex w-full flex-wrap max-h-[100px] overflow-y-auto">
                          {selectedCountries.map(
                            (country: Country, idx: number) => {
                              return (
                                <div
                                  key={idx}
                                  className="flex flex-row bg-primary-surface-2 rounded-full px-2 pr-4 py-2 text-sm font-semibold m-2"
                                >
                                  <div className="flex flex-row ml-1">
                                    <div>{country && country.name}</div>
                                    <div className="ml-2 font-light">
                                      ({country && country.code})
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Step>
              </Stepper>
            </div>
          </DialogBody>
          <DialogFooter className="flex flex-row justify-between px-10 absolute bottom-0 w-full">
            <Button
              className="mx-2 px-10"
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={isFirstStep}
            >
              Back
            </Button>

            {isLastStep ? (
              <Button
                color="primary"
                className="mx-2 px-10"
                disabled={isCreating || isEditing}
                onClick={onSubmit}
              >
                {isCreating || isEditing ? (
                  <ArrowPathIcon className="w-5 animate-spin" />
                ) : (
                  'Submit'
                )}
              </Button>
            ) : (
              <Button
                className="mx-2 px-10"
                disabled={isLastStep || nextDisabled}
                onClick={() => {
                  trigger(['legalName', 'nickname', 'currency'])

                  !nextDisabled && setActiveStep(activeStep + 1)
                }}
              >
                Next
              </Button>
            )}
          </DialogFooter>
        </form>
      </Dialog>
    </>
  )
}
export default AddEditEntityDialog
