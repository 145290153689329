import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { Switch } from '@material-tailwind/react'
import { TractionService } from '@services/api-analytics/risk-traction'

const ClientL5 = ({
  isTotal,
  exportable,
}: {
  isTotal: boolean
  exportable: boolean
}) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
  } = appliedFilters
  const { categoryTypeOptions } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const { showTable, setShowTable } = useContext(ApplicationCustomContext)

  const filters: RisksFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    is_aggregate: isAggregate,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['clients', isTotal, filters],
    () =>
      isTotal
        ? TractionService.getTotalUniqueClients(filters)
        : TractionService.getMonthlyActiveClients(filters),
    getStaleMins()
  )
  const { data, ...growthRatios } = fetchedData ?? {}
  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const series = isAggregate
    ? [
        {
          label: 'Clients',
          type: 'ColumnSeries',
          field: 'type_all',
        },
      ]
    : (categoryTypes || []).map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: typeVal?.type,
          type: 'ColumnSeries',
          field: `type_${ct}`,
          isStack: true,
          isTotal: false,
          color,
        }
      })

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      width: 200,
      render: (r: any) => {
        return moment.utc(r.x).format('YYYY-MM-DD')
      },
    },
    ...series.map((s: any) => {
      return {
        width: 200,
        align: 'right',
        field: s.field,
        title: s.label,
        render: (r: any) => numberFormatter(r[s.field]),
      }
    }),
  ]

  return (
    <L5ChartstatLayout
      chart={
        <>
          <Chart
            loading={isFetching || isProcessing}
            data={chartData}
            id={`riskTractionClient${isTotal ? '_total' : '_'}by_${activeType}`}
            yLabel="Count"
            series={series}
            error={error as { message: string }}
            exportable={exportable}
            exportableColumn={table_columns}
          />
          <div className="flex justify-end mt-8 mb-4">
            <Switch
              label="Show Data"
              checked={showTable}
              onChange={() => {
                setShowTable(!showTable)
              }}
              defaultChecked
              color="light-blue"
              crossOrigin={undefined}
            />
          </div>
          {showTable && (
            <Table
              loading={isFetching || isProcessing}
              columns={table_columns}
              data={chartData}
            />
          )}
        </>
      }
      stat={<StatisticL5 {...growthRatios} />}
    />
  )
}

export default ClientL5
