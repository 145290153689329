import React, { useContext, useEffect, useState } from 'react'

// import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
// import { AvailableFeatures } from '@components/app-routes/routes'
import OptionSearch from '@components/atoms/search'
import { FAV_COMPANY } from '@constants/app'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  StarIcon as StarOutlined,
} from '@heroicons/react/24/outline'
import {
  ArrowRightOnRectangleIcon,
  StarIcon as StarSolid,
} from '@heroicons/react/24/solid'
import {
  // Avatar,
  Popover,
  PopoverContent,
  PopoverHandler,
  Tooltip,
} from '@material-tailwind/react'

import SidebarProps from './typing'

const SidebarFooter = ({
  expanded,
  companies,
  onSelectCompany,
}: SidebarProps) => {
  // const navigate = useNavigate()
  const {
    // user,
    logout,
  } = useAuth0()
  const { userMetadata, company } = useContext(AuthContext)
  const [favCompany, setFavCompany] = useState<string>()

  const [openSwitcher, setOpenSwitcher] = useState<boolean>(false)

  const ArrowIcon = openSwitcher ? ChevronUpIcon : ChevronDownIcon
  const StarIcon = favCompany == company?.slug_name ? StarSolid : StarOutlined
  const searchOptions = companies.map(c => {
    return {
      label: c.legal_name,
      value: c.slug_name,
      displayhint: true,
      disabledHint:
        !c.currencies_available || c.currencies_available.length === 0
          ? '(No Currency)'
          : '',
      actionIcon: favCompany == c.slug_name && (
        <StarSolid className="w-5 hover:bg-primary-surface-2 hover:cursor-pointer text-peach" />
      ),
    }
  })

  const setFavourite = (slugName: string) => {
    if (favCompany == slugName) {
      localStorage.removeItem(FAV_COMPANY)
      setFavCompany('')
    } else {
      localStorage.setItem(FAV_COMPANY, slugName)
      setFavCompany(slugName)
    }
  }
  useEffect(() => {
    const fav = localStorage.getItem(FAV_COMPANY)
    fav && setFavCompany(fav)
  }, [])

  return (
    <div
      className={`bg-neutral-white flex border-t border-neutral-border-1 z-10 shadow-md lg:shadow-none`}
    >
      {/* This is the 'profile icon'. Temporarily disabling it until it's functional. */}
      {/* <div className="w-[70px]">
        <Tooltip content={`${user?.name} - ${user?.email}`} placement="top">
          <button
            className={`border-r border-neutral-border-1 ${
              expanded ? 'p-2' : 'w-sidebar py-2'
            }`}
          >
            <Avatar
              onClick={() => {
                if (AvailableFeatures().includes('administrations_profile')) {
                  navigate('/administrations/profile')
                }
              }}
              src={user?.picture}
              alt="avatar"
              variant="circular"
              size="sm"
              color="lime"
            />
          </button>
        </Tooltip>
      </div> */}
      {expanded && (
        <>
          <Popover placement="top-start" open={openSwitcher}>
            <PopoverHandler>
              <div className="flex-grow border-neutral-border-1 flex relative px-3 gap-3">
                <Tooltip
                  content={`Always view ${company?.legal_name} when logging in`}
                  placement="top"
                >
                  <StarIcon
                    className="w-5 hover:bg-primary-surface-2 hover:cursor-pointer text-cc-primary-orange ml-1"
                    onClick={() =>
                      company?.slug_name && setFavourite(company.slug_name)
                    }
                  />
                </Tooltip>
                <button
                  className="flex items-center justify-between w-full"
                  onClick={() =>
                    companies.length > 1 && setOpenSwitcher(!openSwitcher)
                  }
                >
                  <div className="flex flex-col">
                    <span className="text-neutral-body-1 text-sm capitalize text-left truncate text-ellipsis max-w-[100px]">
                      {company?.legal_name || '-'}
                    </span>
                    <span className="text-neutral-body-1 text-xs text-left">
                      {userMetadata?.isSuperadmin
                        ? Role.superadmin
                        : userMetadata?.companies?.[company?.slug_name ?? '']
                            ?.control || 'user'}
                    </span>
                  </div>
                  {companies.length > 1 && (
                    <div>
                      <ArrowIcon className="w-4 text-neutral-body-2" />
                    </div>
                  )}
                </button>
              </div>
            </PopoverHandler>
            <PopoverContent className="fixed border-neutral-border-1 lg:border-l-0 lg:border-r-0 z-[999] rounded-none w-side shadow-none translate-y-1.5  h-[318px] px-2.5 pt-2.5 pb-0 ">
              <OptionSearch
                availableOptions={searchOptions}
                searchParam={'Company name'}
                handleChange={(value: string) => {
                  const selectedCom = companies.find(
                    company => company.slug_name === value
                  )
                  selectedCom && onSelectCompany(selectedCom)
                  setOpenSwitcher(false)
                }}
                showOptions={true}
                setOpenSwitcher={setOpenSwitcher}
              />
            </PopoverContent>
          </Popover>
          <Tooltip content="Logout" placement="top">
            <button
              className="w-[54px] h-[54px] border-l border-neutral-border-1 rounded-none justify-center flex items-center"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              <ArrowRightOnRectangleIcon className="w-4 text-neutral-body-2" />
            </button>
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default SidebarFooter
