import React from 'react'

import { capitalizeFirst } from '@amcharts/amcharts5/.internal/core/util/Utils'
import {
  FinancialUpload,
  FinancialUploadStatus,
} from '@interfaces/accounting/financials'

const FinancialUploadsStatusLabel = ({
  upload,
}: {
  upload: FinancialUpload
}) => {
  return (
    <div className="flex justify-center">
      <span
        className={`py-0.5 px-4 rounded-full capitalize text-xs ${
          upload.state === FinancialUploadStatus.PROCESSED
            ? 'text-success-main border border-success-main  bg-success-surface'
            : upload.state === FinancialUploadStatus.INREVIEW
            ? 'text-danger-main border border-danger-main bg-danger-surface'
            : upload.state === FinancialUploadStatus.PROCESSING
            ? 'text-primary-main border border-primary-main  bg-primary-surface-2'
            : upload.state === FinancialUploadStatus.VOIDED
            ? 'text-neutral-body-2 border-neutral-border-3 bg-neutral-surface-2'
            : ''
        }`}
      >
        {capitalizeFirst(upload.state.replace('_', ' '))}
      </span>
    </div>
  )
}

export default FinancialUploadsStatusLabel
