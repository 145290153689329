import React, { useContext, useEffect } from 'react'
import moment from 'moment'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { EntityInfo } from '@interfaces/financial'
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from '@material-tailwind/react'

import { FilterCell } from './filter-cell'
const FORMAT = 'YYYY-MM-DD'

const FilterDateRange = ({
  isCohort = false,
  isCurrentDate = false,
  type,
}: {
  isCohort?: boolean
  isCurrentDate?: boolean
  type?: string
}) => {
  const { activeFilters, setActiveFilters, company, optionFilters } =
    useContext(AuthContext)

  const {
    dateStart,
    dateEnd = moment.utc(),
    dateStartCohort,
    endCohort = moment.utc(),
    range,
    financialEntity,
    entityStartDate,
    entityEndDate,
  } = activeFilters

  const isFinancials = type == 'financials'

  const activeEntity = optionFilters.financialEntities?.find(
    (entity: EntityInfo) => entity.entity_id == financialEntity
  )

  const company_end = moment.utc(company?.date_end)
  const end = isCurrentDate
    ? moment.utc()
    : company_end.isAfter(moment.utc())
    ? moment.utc(dateEnd)
    : company_end

  const start =
    range <= 0
      ? isFinancials
        ? moment.utc(activeEntity?.start_date)
        : moment.utc(company?.date_start)
      : end.clone().subtract(range, 'years')

  const end_cohort = moment.utc(company?.date_end_cohort)

  const startCohort =
    range <= 0
      ? moment.utc(company?.date_start)
      : end_cohort.clone().subtract(range, 'years')

  useEffect(() => {
    if (company) {
      setActiveFilters((prev: LayoutFilter) => {
        return {
          ...prev,
          range: range ?? 0,
          dateEnd: end.format(),
          dateStart: start.format(),
          endCohort: end_cohort.format(),
          dateStartCohort: startCohort.format(),
          dateEndCohort: moment(endCohort).format(),
          entityStartDate:
            !!activeEntity && moment.utc(activeEntity?.start_date).format(),
          entityEndDate:
            !!activeEntity && moment.utc(activeEntity?.end_date).format(),
        }
      })
    }
  }, [company, financialEntity])

  useEffect(() => {
    if (range !== -1) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        dateEnd: end.format(),
        dateStart: start.format(),
        endCohort: end_cohort.format(),
        dateStartCohort: startCohort.format(),
        dateEndCohort: moment(endCohort).format(),
        entityStartDate:
          !!activeEntity && start.clone().subtract(range, 'years').format(),
        entityEndDate:
          !!activeEntity && moment.utc(activeEntity?.end_date).format(),
      }))
    }
  }, [range])

  return (
    <FilterCell title="Date Range">
      <Popover placement="bottom">
        <PopoverHandler>
          <button className="border border-neutral-border-2 !bg-neutral-white rounded-lg min-w-[200px] min-h-[40px] !outline-none flex items-center px-4 justify-between gap-4 ">
            <div className="flex gap-2">
              <Typography className="text-sm !text-neutral-black font-medium">
                {moment
                  .utc(
                    isCohort
                      ? dateStartCohort
                      : isFinancials
                      ? entityStartDate
                      : dateStart
                  )
                  .format(FORMAT)}
              </Typography>
              <Typography className="text-sm !text-neutral-black">
                to
              </Typography>
              <Typography className="text-sm !text-neutral-black font-medium">
                {isCurrentDate
                  ? moment.utc().format(FORMAT)
                  : moment
                      .utc(
                        isCohort
                          ? endCohort
                          : isFinancials
                          ? entityEndDate
                          : dateEnd
                      )
                      .format(FORMAT)}
              </Typography>
            </div>
            <ChevronDownIcon className="h-3 w-3" />
          </button>
        </PopoverHandler>
        <PopoverContent className="z-[9999] border-t border-neutral-border-2 rounded-lg flex flex-col w-[270px] shadow-lg">
          <Typography className="text-sm !text-neutral-black">From</Typography>
          <input
            type="date"
            className="border border-neutral-border-2 rounded-lg px-4 py-2 !outline-none"
            value={moment
              .utc(
                isCohort
                  ? dateStartCohort
                  : isFinancials
                  ? entityStartDate
                  : dateStart
              )
              .format(FORMAT)}
            min={moment.utc(isCohort ? startCohort : start).format(FORMAT)}
            max={moment.utc(isCohort ? endCohort : dateEnd).format(FORMAT)}
            onChange={e => {
              const key = isCohort
                ? 'dateStartCohort'
                : isFinancials
                ? 'entityStartDate'
                : 'dateStart'
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                [key]: e.target.value,
                range: -1,
              }))
            }}
          />
          <Typography className="text-sm !text-neutral-black mt-4">
            To
          </Typography>
          <input
            type="date"
            className="border border-neutral-border-2 rounded-lg px-4 py-2 !outline-none"
            value={moment
              .utc(
                isCohort ? endCohort : isFinancials ? entityEndDate : dateEnd
              )
              .format(FORMAT)}
            min={moment.utc(isCohort ? startCohort : start).format(FORMAT)}
            max={moment.utc(isCohort ? endCohort : dateEnd).format(FORMAT)}
            onChange={e => {
              const key = isCohort
                ? 'endCohort'
                : isFinancials
                ? 'entityEndDate'
                : 'dateEnd'
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                [key]: e.target.value,
                range: -1,
              }))
            }}
          />
          <Typography className="text-sm !text-neutral-black mt-4">
            Last
          </Typography>
          <div className="flex w-full">
            {Array(3)
              .fill('')
              .map((x, i) => (
                <Button
                  key={i}
                  color={range === i + 1 ? 'primary' : 'default'}
                  onClick={() => {
                    setActiveFilters((prev: LayoutFilter) => ({
                      ...prev,
                      range: i + 1,
                    }))
                  }}
                  className={`flex-1 h-[40px] !outline-none rounded-lg ${
                    i === 0 ? 'rounded-r-none' : 'rounded-none'
                  }`}
                >{`${i + 1}Y`}</Button>
              ))}
            <Button
              className="rounded-lg rounded-l-none flex-1 !outline-none"
              color={range === 0 ? 'primary' : 'default'}
              onClick={() => {
                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  range: 0,
                }))
              }}
            >
              All
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </FilterCell>
  )
}
export default FilterDateRange
