import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import { styles } from '../template-style'

interface Props {
  formatCurrency: (amount: number) => string
  amount: number
  originationFee?: number
  lenderRatios: number[]
  borrower?: string
}

export const CalculationTable1 = ({
  formatCurrency,
  amount,
  originationFee,
  lenderRatios,
}: Props) => (
  <View style={styles.tableWrapper}>
    <View wrap={false} style={styles.tableView}>
      <View style={styles.table}>
        <View style={styles.tableBody}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Advance Request Amount</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {amount ? formatCurrency(amount) : '[X]'}
              </Text>
            </View>
          </View>
          {lenderRatios.map((ratio, index) => (
            <View key={`lender-ratio-${index}`} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {`Lender ${index + 1} Pro Rata Amount`}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellRightAligned}>
                  {amount && ratio
                    ? formatCurrency(amount * ratio)
                    : '[X]*[Lender 1 %]'}
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Borrower Equity Contribution</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {typeof originationFee === 'number' && !isNaN(originationFee)
                  ? formatCurrency(originationFee)
                  : '([X]/Advance Rate)-[X]'}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  </View>
)

export const CalculationTable2 = ({
  formatCurrency,
  amount,
  lenderRatios,
  borrower,
}: Props) => (
  <View style={styles.tableWrapper}>
    <View wrap={false} style={styles.tableView}>
      <View style={styles.table}>
        <View style={styles.tableBody}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                Advance Request Amount from {borrower || '[Borrower]'}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {amount ? formatCurrency(amount) : '$[X]'}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>1% Fee</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {amount ? formatCurrency(amount * 0.01) : '$([X * 0.01])'}
              </Text>
            </View>
          </View>
          {lenderRatios.map((ratio, index) => (
            <View key={`lender-ratio-${index}`} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {`Total Wire to Lender ${index + 1}`}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellRightAligned}>
                  {amount && ratio
                    ? formatCurrency(amount * 0.99 * ratio)
                    : `$[X * 0.99]*${ratio}`}
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                Principal Outstanding on Advance
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {amount ? formatCurrency(amount) : '$[X]'}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  </View>
)

export const CalculationTable3 = ({
  formatCurrency,
  amount,
  lenderRatios,
  borrower,
}: Props) => (
  <View style={styles.tableWrapper}>
    <View wrap={false} style={styles.tableView}>
      <View style={styles.table}>
        <View style={styles.tableBody}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                Advance Request Amount from {borrower || '[Borrower]'}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {amount ? formatCurrency(amount) : '$[X]'}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>2% Upfront Fee</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {amount
                  ? formatCurrency(amount / 0.98 - amount)
                  : '$([X / 0.98] - X)'}
              </Text>
            </View>
          </View>
          {lenderRatios.map((ratio, index) => (
            <View key={`lender-ratio-${index}`} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {`Total Wire to Lender ${index + 1}`}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellRightAligned}>
                  {amount && ratio
                    ? formatCurrency(amount * ratio)
                    : `$[X * ${ratio}]`}
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                Principal Outstanding on Advance
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRightAligned}>
                {amount ? formatCurrency(amount / 0.98) : '$[X / 0.98]'}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  </View>
)
