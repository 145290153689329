import React, { useContext, useEffect } from 'react'
import moment from 'moment'

import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import Company from '@interfaces/company'
import { Facility } from '@interfaces/facility'
import { LayoutFilter } from '@interfaces/filter'
import { Option, Select, Typography } from '@material-tailwind/react'
import DocumentService from '@services/api-manage/docs'

const statuses = [
  {
    status: [
      'signed',
      'awaiting_signature',
      'completed',
      'upcoming',
      'created',
      'overdue',
      'due',
      'voided',
      'signed_and_retracted',
    ],
    display: 'All',
  },
  { status: 'overdue', display: 'Overdue' },
  { status: 'due', display: 'Due' },

  {
    status: ['awaiting_signature', 'created'],
    display: 'Awaiting Signature',
  },
  { status: 'upcoming', display: 'Upcoming' },
  {
    status: ['signed', 'completed'],
    display: 'Completed',
  },
  { status: ['voided', 'signed_and_retracted'], display: 'Nullified' },
]

export const setFilterDocCentre = (
  activeFacility: Facility,
  company: Company | undefined,
  setActiveFilters: (filters: LayoutFilter) => void,
  optionFilters: LayoutFilter,
  setOptionFilters: (filters: LayoutFilter) => void,
  shouldReset: boolean
) => {
  if (activeFacility) {
    DocumentService.listCategories({
      slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
      facility_id: activeFacility?.facility_id,
      facility: activeFacility?.facility_name ?? '',
      action: 'upload',
    })
      .then(response => {
        const allValues: any = { category: 'All', subcategory: [] }
        response.forEach(i => allValues.subcategory.push(i.category))
        response.unshift(allValues)
        if (
          optionFilters?.initialFilters === undefined &&
          !optionFilters?.facilitiesIsFetching
        ) {
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            initialDocCentreFilters: {
              dateStart: moment(company?.date_start).format('YYYY-MM-DD'),
              dateEnd: moment(company?.date_end).isAfter(moment())
                ? moment()
                : moment(company?.date_end),
              docKeyword: '',
              docCategory: response.filter(
                (cat: any) => cat.category == 'All'
              )[0],
              docStatus: statuses.filter(
                (status: any) => status.display == 'All'
              )[0],
            },
          }))
        }
        setActiveFilters((prev: LayoutFilter) => ({
          ...prev,
          docKeyword:
            !shouldReset && prev?.docKeyword !== '' ? prev?.docKeyword : '',
          docCategory:
            !shouldReset && prev?.docCategory?.category !== 'All'
              ? prev?.docCategory
              : response.filter((cat: any) => cat.category == 'All')[0],
          docStatus:
            !shouldReset && prev?.docStatus?.display !== 'All'
              ? prev?.docStatus
              : statuses.filter((status: any) => status.display == 'All')[0],
        }))
        setOptionFilters((prev: LayoutFilter) => ({
          ...prev,
          docKeyword: '',
          docCategories: response,
          docStatuses: statuses,
        }))
      })
      .catch(() => {
        console.error('error')
      })
  }
}

const FilterDocCentre = () => {
  const {
    company,
    activeFilters,
    appliedFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { docCategory, docStatus, docKeyword } = activeFilters
  const { activeFacilityId } = appliedFilters
  const {
    facilities = [],
    docCategories = [],
    docStatuses = [],
  } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  useEffect(() => {
    setFilterDocCentre(
      activeFacility,
      company,
      setActiveFilters,
      optionFilters,
      setOptionFilters,
      false
    )
  }, [company, activeFacility])

  const getStatusDisplayName = (val: any) => {
    const status = statuses.find((s: any) => s.display === val?.display)
    return status ? status.display : 'All'
  }

  const getCategoryDisplayName = (val: any) => {
    const category = docCategories.find(
      (s: any) => s.category === val?.category
    )
    return category ? category.category : 'All'
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1">
        <Typography className="text-neutral-body-2 text-sm">
          Document
        </Typography>
        <div className="w-full">
          <FormInput
            value={docKeyword}
            {...{
              placeholder: 'Enter Document Name..',
              onChange: (e: any) => {
                setActiveFilters({
                  ...activeFilters,
                  docKeyword: e.target.value,
                })
              },
            }}
          />
        </div>
      </div>
      {!!docCategories && docCategories.length > 0 && (
        <div className="flex flex-col gap-1">
          <Typography className="text-neutral-body-2 text-sm">
            Category
          </Typography>
          <div className="w-full">
            <Select
              className="w-full [&~ul]:max-h-[100px] border border-neutral-border-1 mb-0"
              onChange={(val: any) => {
                setActiveFilters({
                  ...activeFilters,
                  docCategory: val,
                })
              }}
              selected={() => (
                <span>{getCategoryDisplayName(docCategory)}</span>
              )}
            >
              {docCategories.map((e: any, i: number) => (
                <Option key={i} value={e}>
                  {e.category}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-1">
        <Typography className="text-neutral-body-2 text-sm">Status</Typography>
        <div className="w-full">
          <Select
            className="w-full [&~ul]:max-h-[100px] border border-neutral-border-1"
            onChange={val => {
              setActiveFilters({
                ...activeFilters,
                docStatus: val,
              })
            }}
            selected={() => <span>{getStatusDisplayName(docStatus)}</span>}
          >
            {docStatuses.map((e: any, i: number) => (
              <Option key={i} value={e}>
                {e.display}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  )
}
export default FilterDocCentre
