import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart, { SeriesData } from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import { REMOUNT_MS } from '@constants/config'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { CharacteristicService } from '@services/api-analytics/risk-characteristic'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'
const SEPARATOR = '_'

const ValueL5 = () => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const [isDistribution, setIsDistribution] = useState<boolean>(true)

  const { userCompanyAccess, role } = useUserAccessFeature()
  const activeAccess = userCompanyAccess?.access ?? {}
  const FEATURE = `analytics_risk_characteristics_value`

  const exportable =
    role !== Role.custom
      ? true
      : role === Role.custom &&
        activeAccess[
          `${FEATURE}_${isDistribution ? 'distribution' : 'average'}`
        ].includes('export')

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes,
    activeType,
    currency = 'USD',
  } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = {},
    display_rates = [],
  } = optionFilters
  const isAggregate = activeType === 'All'

  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []
  let maxVal = isDistribution ? 100 : 0

  const filters: RisksFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    filters: isAggregate ? [] : categoryTypes,
    is_distribution: isDistribution,
    is_effective: false,
    slug_name: company?.slug_name ?? '',
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['interestRateDistribution', filters],
    () => CharacteristicService.getValues(filters),
    getStaleMins()
  )
  const { bar_data, labels, ...growthRatios } = data ?? {}
  const cohort = ((bar_data || []) as any[]).filter(
    (v: { cohort: any }, i: any, a: { cohort: any }[]) =>
      a.findIndex((v2: { cohort: any }) => v2.cohort === v.cohort) === i
  )

  let chartData: SeriesData[]
  if (isDistribution) {
    chartData = (bar_data ?? []).map(x => ({
      ...x,
      x: moment(x.cohort).valueOf(),
    }))
  } else {
    chartData = cohort.map(c => {
      const result = historicalExchgList(rates, c, c.cohort)
      const cohortData = ((bar_data || []) as any[]).filter(
        d => d.cohort === c.cohort
      )
      if (isAggregate) {
        result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
        if (result.type_all > maxVal) {
          maxVal = result.type_all
        }
      } else {
        categoryTypes.forEach((ct: number) => {
          const cohortCategory = cohortData.find(
            (cd: { id: number }) => cd.id === ct
          )
          result[`type_${ct}`] = parseFloat(
            (cohortCategory?.val ?? 0).toString()
          )
          if (result[`type_${ct}`] > maxVal) {
            maxVal = result[`type_${ct}`]
          }
        })
      }

      return result
    })
  }

  const valueAvgSeries = isAggregate
    ? [
        {
          label: 'All',
          tooltipLabel: `Value`,
          tooltipValueFormat: `#.00a ${currency}`,
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : (categoryTypes || []).map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal?.type}`,
          tooltipValueFormat: `#.00a ${currency}`,
          field: `type_${ct}`,
          color,
          type: 'SmoothedXLineSeries',
          hasBullet: true,
        }
      })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  return (
    <L5ChartstatLayout
      chart={
        <>
          <div className="flex mb-8 justify-end">
            <div className="gap-4 flex flex-col 2xl:flex-row">
              {[
                {
                  options: [
                    {
                      title: 'Distribution',
                      active: isDistribution,
                      action: () => setIsDistribution(true),
                    },
                    {
                      title: 'Average',
                      active: !isDistribution,
                      action: () => setIsDistribution(false),
                    },
                  ].filter(t => {
                    return role !== Role.custom
                      ? true
                      : role === Role.custom &&
                          Object.keys(activeAccess).find(
                            aa =>
                              aa.includes(
                                `${FEATURE}_${t.title
                                  .toLowerCase()
                                  .split(' ')
                                  .join('-')}`
                              ) && activeAccess[aa].includes('access')
                          )
                  }),
                },
              ].map((group, i) => (
                <div key={i} className={`flex `}>
                  <div className="bg-neutral-border-1 rounded-md p-1 flex">
                    {group.options.map((g, j) => (
                      <button
                        key={j}
                        className={`block text-sm rounded-md px-20 py-0.5 font-semibold ${
                          g.active ? 'bg-secondary-main text-white' : ''
                        }`}
                        onClick={g.action}
                      >
                        {g.title}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Chart
            loading={isFetching || isProcessing}
            id={`riskCharacteristicInterestRate_by_${activeType}_isDistribution${isDistribution}`}
            yLabel={isDistribution ? 'Percentage' : `${currency}`}
            ySetting={
              isDistribution
                ? {}
                : maxVal < 5
                ? { max: 5, maxPrecision: 1 }
                : {}
            }
            yFormat={isDistribution ? '#.00a%' : undefined}
            data={chartData}
            series={
              isDistribution
                ? (labels ?? [])
                    .sort((a, b) => {
                      const aFromRange = a.val.toString().split(SEPARATOR)?.[0]
                      const bFromRange = b.val.toString().split(SEPARATOR)?.[0]
                      return Number(aFromRange) < Number(bFromRange) ? -1 : 1
                    })
                    .map(l => ({
                      label: l.val,
                      tooltipValueFormat: `#.00a%`,
                      type: 'ColumnSeries',
                      isStack: true,
                      field: `val_${l.id}`,
                    }))
                : valueAvgSeries
            }
            tooltipSubtitle={
              showHistoricalRateIndicator
                ? displayRates(display_rates)
                : undefined
            }
            exportable={exportable}
            error={error as { message: string }}
          />
        </>
      }
      stat={isDistribution ? <></> : <StatisticL5 {...growthRatios} />}
    />
  )
}

export default ValueL5
