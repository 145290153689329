import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { Option, Select } from '@material-tailwind/react'
import { MonitorFilterService } from '@services/api-manage/monitor-filter'

const FilterFacility = ({
  is_filter_access = true,
}: {
  is_filter_access?: boolean
}) => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const facilityParam = searchParams.get('facility')
  const { activeFacilityId: appliedActiveFacilityId } = appliedFilters
  const { activeFacilityId } = activeFilters
  const { facilities = [], facilityCompanySlugName } = optionFilters

  const { data, isFetching, error } = useQuery(
    ['facilities', company?.slug_name],
    () => MonitorFilterService.list({ slug_name: company?.slug_name ?? '' }),
    {
      ...getStaleMins(),
      enabled:
        !!company?.slug_name && company?.slug_name !== facilityCompanySlugName,
    }
  )

  useEffect(() => {
    const cleaned_facilities = data?.reduce((p: FacilityFilter[], c) => {
      const is_exists = p.find(x => x.facility_id === c.facility_id)
      return is_exists ? p : [...p, c]
    }, [])
    const facilityParamId = cleaned_facilities?.find(
      (f: FacilityFilter) => f.facility_name === facilityParam
    )?.facility_id
    if (
      !appliedActiveFacilityId &&
      (facilityParamId || cleaned_facilities?.[0]?.facility_id)
    ) {
      setAppliedFilters((prev: LayoutFilter) => ({
        ...prev,
        activeFacilityId:
          facilityParamId ?? cleaned_facilities?.[0]?.facility_id,
      }))
    }
    setActiveFilters((prev: LayoutFilter) => ({
      ...prev,
      activeFacilityId:
        facilityParamId ??
        appliedActiveFacilityId ??
        cleaned_facilities?.[0]?.facility_id,
    }))
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      facilityCompanySlugName: company?.slug_name,
      facilities: cleaned_facilities,
    }))
    if (!appliedFilters.activeFacilityId && facilityParamId) {
      setAppliedFilters((prev: LayoutFilter) => ({
        ...prev,
        activeFacilityId:
          facilityParamId ??
          appliedActiveFacilityId ??
          cleaned_facilities?.[0]?.facility_id,
      }))
      if (searchParams.has('facility')) {
        searchParams.delete('facility')
        setSearchParams(searchParams.toString())
      }
    }
  }, [data])

  useEffect(() => {
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      facilitiesIsFetching: isFetching,
      facilitiesError: error,
    }))
  }, [isFetching, error])

  const { userCompanyAccess, role } = useUserAccessFeature()
  const activeAccess = userCompanyAccess?.access
  const available_facilities = Object.keys(activeAccess ?? {}).reduce(
    (p: string[], c) => {
      const splitted_key = c.split('_')
      const facility = splitted_key[0]?.toLowerCase()
      const module = splitted_key[1]?.toLowerCase()
      return module === 'manage' && !p.includes(facility) ? [...p, facility] : p
    },
    []
  )

  const str = Role.custom.toString()
  const options =
    activeAccess && is_filter_access && role === str
      ? facilities?.filter((x: any) =>
          available_facilities.includes(String(x.facility_id))
        )
      : facilities

  const activeFacilityObject = options?.find(
    (f: FacilityFilter) => f.facility_id === activeFacilityId
  )

  return (
    <div className="flex flex-col gap-1 min-w-[160px]">
      <Typography className="text-neutral-body-2 text-sm">
        Facilities
      </Typography>

      <Select
        className="[&~ul]:max-h-[100px] bg-neutral-white text-neutral-black"
        disabled={options.length === 0}
        onChange={val => {
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            activeFacilityId: val ? parseInt(val.toString()) : undefined,
          }))
        }}
        selected={() => {
          return (
            <span>
              {isFetching ? 'loading' : activeFacilityObject?.facility_name}
            </span>
          )
        }}
      >
        {options.map((c: FacilityFilter) => (
          <Option key={c.facility_id} value={c.facility_id.toString()}>
            {c.facility_name}
          </Option>
        ))}
      </Select>
    </div>
  )
}
export default FilterFacility
