import React, { useContext } from 'react'
import moment from 'moment'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import { useDashboardData } from '../helper'

const MonitorDashboardTableItem = ({ item }: { item: DashboardResponse }) => {
  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate } = appliedFilters

  const { tableData, tableColumns, displayName, dateFilterField } =
    useDashboardData(item)

  return (
    <>
      {item.external_name && (
        <Typography className="text-center text-lg font-semibold mt-8">
          {displayName}{' '}
          {dateFilterField &&
            `as of ${moment.utc(calculationDate).format('YYYY-MM-DD')}`}
        </Typography>
      )}
      <Table data={tableData} columns={tableColumns as any} />
    </>
  )
}
export default MonitorDashboardTableItem
