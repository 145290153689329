import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import Button from '@components/atoms/button'
import FilesUploadDropZone from '@components/file-upload-modal/drop-zone'
import Stepper from '@components/stepper'
import Step from '@components/stepper/step'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Template } from '@interfaces/accounting/financials'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { MAX_FILE_SIZE_TEXT } from '@pages/manage/monitor/actions/document-centre/constants'
import { TemplatesService } from '@services/api-admin/settings-templates'

import { MAX_FILE_SIZE } from '../../constants'

interface AddTemplateDialogProps {
  open: boolean
  selectedInfo: Template
  handleOpen: () => void
  refetch: () => void
}

interface Params {
  slugName: string
  legalEntityID: string
  file?: File
  templateId?: string
}

const AddTemplateDialog = ({
  open,
  selectedInfo,
  handleOpen,
  refetch,
}: AddTemplateDialogProps) => {
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm()
  const { company } = useContext(AuthContext)
  const { displayToastError, displayToastSuccess } = useToastNotification()

  const [activeStep, setActiveStep] = useState<number>(0)
  const [isLastStep, setIsLastStep] = useState<boolean>(false)
  const [isFirstStep, setIsFirstStep] = useState<boolean>(false)
  const [uploads, setUploads] = useState<any>([])

  const onSubmit = async (data: any) => {
    editTemplate(data)
  }

  const nextDisabled =
    Object.keys(errors).length > 0 ||
    !getValues('entity') ||
    !(Object.keys(uploads).length > 0)

  const cancelSelection = (itemKey: any) => {
    const updatedUploads = { ...uploads }
    delete updatedUploads[itemKey]
    setUploads(updatedUploads)
  }

  const handleSuccess = () => {
    handleOpen()
    refetch()
    displayToastSuccess('Template Edited')
  }
  const handleError = (status?: number, customeMessage?: string) => {
    displayToastError(status, customeMessage)
  }

  const { mutate: editTemplate, isLoading: isEditing } = useMutation(
    () => {
      const params: Params = {
        slugName: company?.slug_name ?? '',
        legalEntityID: selectedInfo.entityID,
      }
      //only upload new template if a new template upload is selected
      params.file = uploads.template[0]

      return TemplatesService.uploadTemplate(params)
    },
    {
      onSuccess: data => {
        if (
          data != true &&
          data?.some((p: any) => Object.keys(p).includes('code'))
        ) {
          data.some((p: any) => handleError(406, p.message))
          return
        }
        handleSuccess()
      },
      onError: (err: AxiosError) => handleError(err.response?.status),
    }
  )

  useEffect(() => {
    reset()
    setUploads([])
    setActiveStep(0)
    if (!!selectedInfo) {
      setValue('name', selectedInfo.name)
      setValue('entity', selectedInfo.entityNickname)
    }
  }, [open])

  return (
    <>
      <Dialog
        open={open}
        handler={handleOpen}
        className="flex flex-col h-[70%]"
        size="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>
                {`Configure Template  ${
                  !!selectedInfo && !!selectedInfo.entityNickname
                    ? `for ${selectedInfo.entityNickname}`
                    : ''
                }`}{' '}
              </span>
              <XMarkIcon
                onClick={handleOpen}
                className="w-8 h-8 cursor-pointer hover:opacity-50"
              />
            </div>
          </DialogHeader>
          <DialogBody className="mx-5">
            <div className="w-full">
              <Stepper
                setActiveStep={(e: number) => {
                  setActiveStep(e)
                }}
                setIsLastStep={value => setIsLastStep(value)}
                setIsFirstStep={value => setIsFirstStep(value)}
                activeStep={activeStep}
              >
                {/* <Step
                  onClick={() => setActiveStep(0)}
                  isActive={true}
                  title={'Information'}
                >
                  <div className="font-bold my-3">Configure New Template</div>
                  <div className="flex w-full justify-center">
                    <div className="flex flex-col w-full">
                      <FormInput
                        label={{
                          start: 'Template Name*',
                        }}
                        containerClass="shadow-md -mt-1 w-full"
                        type="text"
                        value={getValues('name')}
                        {...register('templateName', {
                          required: {
                            value: true,
                            message: 'This is required',
                          },
                          onChange: (e: any) => {
                            setValue('name', e.target.value, {
                              shouldValidate: true,
                            })
                          },
                        })}
                        error={errors?.['name']?.message as string}
                      />
                    </div>
                  </div>
                </Step> */}
                <Step
                  onClick={() => setActiveStep(0)}
                  isActive={false}
                  title={'Template'}
                >
                  <div className="flex flex-col">
                    <div className="font-bold mt-3"> Upload new template</div>

                    <div className="flex mt-10">
                      <FilesUploadDropZone
                        id={''}
                        containerClass="relative border-dashed border-2 border-neutral-border-1 rounded-lg cursor-pointer bg-[#f5f5f5] h-20 px-2 -mt-7"
                        fileTypeText={'.pdf'}
                        isLoading={false}
                        maxSize={MAX_FILE_SIZE}
                        maxFiles={1}
                        titleMaxFilesSize={MAX_FILE_SIZE_TEXT}
                        fileTypes={{
                          'application/xlsx': ['.xlsx'],
                        }}
                        handleCancel={() => cancelSelection('financials')}
                        uploader={(file: Array<any>) => {
                          setUploads({
                            ['template']: file,
                          })
                        }}
                        isHintHidden={true}
                      />
                    </div>
                  </div>
                </Step>
                <Step
                  onClick={() => setActiveStep(1)}
                  isActive={false}
                  title={'Submit'}
                >
                  <div className="flex flex-col">
                    <div className="font-bold mt-3">Review & Connect</div>
                    <div className="text-sm font-light mb-10">
                      Review the following information
                    </div>
                    <div className="w-full grid grid-cols-2 gap-2">
                      {/* <span className="text-sm font-bold">Name</span>
                      <span className="text-sm">{getValues('name')}</span> */}
                      <span className="text-sm font-bold">Entity</span>
                      <span className="text-sm">{getValues('entity')}</span>

                      {!!uploads.template && (
                        <>
                          <span className="text-sm font-bold">Upload</span>
                          <span className="text-sm">
                            {!!uploads.template && uploads.template[0].path}
                          </span>{' '}
                        </>
                      )}
                    </div>
                  </div>
                </Step>
              </Stepper>
            </div>
          </DialogBody>
          <DialogFooter className="flex flex-row justify-between px-10 absolute bottom-0 w-full">
            <Button
              className="mx-2 px-10"
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={isFirstStep}
            >
              Back
            </Button>

            {isLastStep ? (
              <Button
                color="primary"
                className="mx-2 px-10"
                disabled={isEditing}
                onClick={() => editTemplate()}
              >
                {isEditing ? (
                  <ArrowPathIcon className="w-5 animate-spin" />
                ) : (
                  'Submit'
                )}
              </Button>
            ) : (
              <Button
                className="mx-2 px-10"
                disabled={isLastStep || nextDisabled}
                onClick={() => {
                  !nextDisabled && setActiveStep(activeStep + 1)
                }}
              >
                Next
              </Button>
            )}
          </DialogFooter>
        </form>
      </Dialog>
    </>
  )
}
export default AddTemplateDialog
