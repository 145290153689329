import { AllTableData, TableData } from '@interfaces/manage-reporting'

const bold_rows = [
  'borrowing base',
  'total lender',
  'surplus',
  'deficiency',
  'collateralization',
]

const getTableTemplate = (title: string) => {
  if (title.toLowerCase().includes('cash')) {
    return {
      tableWidth: '100%',
      columns: [
        {
          key: 'bank_name',
          title: 'Bank Name',
          width: '15%',
          position: 'center',
        },
        {
          key: 'account_number',
          title: 'Account Number',
          width: '14.5%',
          position: 'center',
        },
        {
          key: 'account_name',
          title: 'Account Name',
          width: '25%',
          position: 'center',
        },
        {
          key: 'account_type',
          title: 'Account Type',
          width: '12.5%',
          position: 'center',
        },
        {
          key: 'statement_date',
          title: 'Statement Date',
          width: '12.5%',
          position: 'center',
        },
        {
          key: 'closing_balance',
          title: 'Closing Balance',
          width: '12.5%',
          position: 'right',
        },
        { key: 'currency', title: 'Currency', width: '8%', position: 'center' },
      ],
    }
  }

  if (title.toLowerCase().includes('pool')) {
    return {
      tableWidth: '100%',
      columns: [
        { key: 'bucket', title: 'Bucket', width: '25%', position: 'center' },
        {
          key: 'outstanding_balance',
          title: 'Outstanding Balance',
          width: '25%',
          position: 'right',
        },
        { key: 'discount', title: 'Discount', width: '25%', position: 'right' },
        {
          key: 'discounted_outstanding_balance',
          title: 'Discounted Outstanding Balance',
          width: '25%',
          position: 'right',
        },
      ],
    }
  }

  if (title.toLowerCase().includes('interest')) {
    return {
      tableWidth: '45%',
      columns: [
        {
          key: 'month_date',
          title: 'Interest Period',
          width: '50%',
          position: 'center',
        },
        {
          key: 'interest',
          title: 'Interest Payment (USD)',
          width: '50%',
          position: 'right',
        },
      ],
    }
  }

  if (title.toLowerCase().includes('concentration')) {
    return {
      tableWidth: '100%',
      columns: [
        {
          key: 'calculation_date',
          title: 'Calculation Date',
          width: '50%',
          position: 'center',
        },
        { key: 'amount', title: 'Amount', width: '50%', position: 'right' },
      ],
    }
  }

  return {
    tableWidth: '100%',
    columns: [],
  }
}

export const generateTables = (data: any[]) => {
  const tables = []

  const parseTable = () => {
    const res: TableData[][] = []
    data.map(row => {
      if (row.detail_data.length) {
        const tableTemplate = getTableTemplate(row.display_name)
        const subtable = {
          title: row.display_name,
          tableWidth: tableTemplate.tableWidth,
          key: row.display_name.toLowerCase().replace(' ', '-'),
          titleRow: tableTemplate.columns.map(c => ({
            content: c.title,
            width: c.width,
          })),
          data: row.detail_data.map((subtableRow: any) => {
            return tableTemplate.columns.map(c => ({
              content: subtableRow[c.key],
              width: c.width,
              position: c.position,
              bold:
                subtableRow[tableTemplate.columns[0].key]?.toLowerCase() ===
                'total',
            }))
          }),
        }
        tables.push(subtable)
      }
      const rowRes = [
        {
          content: row.display_name,
          width: '40%',
          position: 'left' as const,
          bold: bold_rows.includes(row.display_name.toLowerCase()),
        },
        {
          content: bold_rows.includes(row.display_name.toLowerCase())
            ? ''
            : row.display_value,
          width: '30%',
          position: 'right' as const,
        },
        {
          content: bold_rows.includes(row.display_name.toLowerCase())
            ? row.display_value
            : '',
          width: '30%',
          position: 'right' as const,
          bold: true,
        },
      ]
      res.push(rowRes)
    })
    return res
  }

  const summaryTable: AllTableData = {
    title: 'Summary',
    key: 'summary',
    tableWidth: '75%',
    titleRow: [
      {
        content: 'USD',
        width: '100%',
      },
    ],
    data: parseTable(),
  }

  tables.unshift(summaryTable)
  return tables
}
