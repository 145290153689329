import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import DynamicMenu from '@components/dynamic-menu'
import Pagination from '@components/pagination'
import CustomTable from '@components/table'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
import { Alert, Card, CardBody, Tooltip } from '@material-tailwind/react'
import { TemplatesService } from '@services/api-admin/settings-templates'

import AddTemplateDialog from '../dialogs/add-template-dialog'

const TemplatesOverview = forwardRef((props, ref) => {
  const { company } = useContext(AuthContext)

  const [isTemplateDialogOpen, setIsTemplateDialogOpen] =
    useState<boolean>(false)
  const [selectedTemplateInfo, setSelectedTemplateInfo] = useState<any>()
  const PER_PAGE = 5
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const { displayToastError } = useToastNotification()

  const filters = {
    slugName: company?.slug_name ?? '',
    page: page + 1,
    size: perPage,
  }

  const {
    data: templateData,
    isLoading,
    error,
    refetch,
  } = useQuery(['allTemplates', filters], () =>
    TemplatesService.listTemplates(filters)
  )

  const handleOpenTemplateDialog = () =>
    setIsTemplateDialogOpen(!isTemplateDialogOpen)

  const { mutate: downloadTemplate } = useMutation(
    (template: any) => {
      const params = {
        slugName: company?.slug_name ?? '',
        pathName: template.path,
      }
      return TemplatesService.downloadTemplate(params)
    },
    {
      onSuccess: data => {
        window.open(data, '_blank')
      },
      onError: (err: AxiosError) => displayToastError(err.response?.status),
    }
  )

  const templateColumns = [
    {
      title: 'Entity',
      align: 'start',
      field: 'entityNickname',
      className: 'border-none',
    },
    {
      title: 'Template File name',
      align: 'start',
      field: 'fileName',
      className: 'border-none',
    },
    {
      title: '',
      align: 'center',
      field: '',
      className: 'border-none',
      render: (template: any) => {
        const entityMenuItems = [
          {
            icon: <PencilIcon className="w-4" />,
            label: 'Edit',
            provider: () => {
              setSelectedTemplateInfo(template)
              handleOpenTemplateDialog()
            },
          },
          {
            icon: <ArrowDownTrayIcon className="w-4" />,
            label: 'Download Template',
            provider: () => {
              downloadTemplate(template)
            },
          },
        ]

        return <DynamicMenu menuItems={entityMenuItems} />
      },
    },
  ]

  useEffect(() => {
    setPage(0)
  }, [perPage])

  useImperativeHandle(ref, () => ({
    refetchTemplates: () => {
      refetch()
    },
  }))

  return (
    <>
      <Card className="mt-5">
        <CardBody>
          <div className="flex justify-between mb-4">
            <div>
              <Typography variant="h6">Financials Mapping Templates</Typography>
            </div>

            <div className="flex items-center gap-2">
              <Tooltip content={<div>Refresh</div>} placement="top">
                <div>
                  <Button className="mx-2" onClick={() => refetch()}>
                    <ArrowPathIcon className={'w-4'} />
                  </Button>
                </div>
              </Tooltip>
            </div>
          </div>
          {isLoading ? (
            <div className="w-full flex justify-center mt-2">
              {' '}
              <ArrowPathIcon className="w-7 animate-spin" />
            </div>
          ) : error ? (
            <div>
              <Alert className="border border-danger-main text-danger-main text-center mb-6">
                {((error as AxiosError).response?.data as any)?.error ??
                  (error as AxiosError).message}
              </Alert>
            </div>
          ) : !!templateData.body.length ? (
            <>
              <CustomTable
                data={templateData.body}
                columns={templateColumns as any}
                borderless={true}
              />
              {templateData?.page.total > PER_PAGE && (
                <Pagination
                  id="accounting-entities-table-pagination"
                  total={templateData?.page.total ?? 0}
                  page={page}
                  onChangePage={p => setPage(p)}
                  limit={perPage}
                  limits={Array(2)
                    .fill('')
                    .map((_, i) => PER_PAGE * (i + 1))}
                  onChangeLimit={l => {
                    setPage(1)
                    setPerPage(l)
                  }}
                />
              )}
            </>
          ) : (
            <div>
              <div className="w-full mt-6 mb-1">No Templates to display.</div>
            </div>
          )}
        </CardBody>
      </Card>

      <AddTemplateDialog
        open={isTemplateDialogOpen}
        handleOpen={handleOpenTemplateDialog}
        selectedInfo={selectedTemplateInfo}
        refetch={refetch}
      />
    </>
  )
})

TemplatesOverview.displayName = 'TemplatesOverview'

export default TemplatesOverview
