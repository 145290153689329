import React, { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
} from '@material-tailwind/react'

interface L4BaseLayoutProps {
  /**
   * L5 tabs for this L4
   */
  tabs: {
    /**
     * Tab label
     */
    label: string
    /**
     * Tab description
     */
    desc?:
      | string
      | {
          description: string | React.ReactNode
          formula: string | React.ReactNode
        }
    /**
     * Tab body component
     */
    component: JSX.Element
  }[]
  /**
   * class applied to tab header
   */
  headerClassName?: string
  /**
   * class applied to tab header
   */
  additionalIndicator?: JSX.Element
}

/**
 * Base layout for L4 that use tab as main layout of its L5s
 * @param {L4BaseLayoutProps} props Component properties
 * @returns JSX
 */
const L4TabsLayout: FC<L4BaseLayoutProps> = ({
  tabs,
  headerClassName,
  additionalIndicator,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const tab2 = searchParams.get('tab2')
  const tabIndex = tabs.findIndex(
    t =>
      t.label.toLowerCase().replaceAll(' ', '-').replaceAll('&', 'and') === tab2
  )

  useEffect(() => {
    const nextActiveTab =
      tabIndex < 0 || tabIndex > tabs.length - 1 ? 0 : tabIndex
    setActiveTab(nextActiveTab)
    // force click
    setTimeout(() => {
      const tabElem = document.querySelector(
        `.l4-tabs-layout [data-value="${tabs[nextActiveTab]?.label}"]`
      ) as HTMLElement

      if (tabElem) {
        tabElem.click()
      }
    }, 250)
  }, [JSON.stringify(tabs.map(x => x.label)), tab2])

  const handleClick = (idx: number, label: string) => {
    setActiveTab(idx)
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(
      'tab2',
      label.toLowerCase().replaceAll(' ', '-').replaceAll('&', 'and')
    )

    setSearchParams(newSearchParams.toString())
  }

  const infoContent = tabs[activeTab]?.desc ? (
    typeof tabs[activeTab]?.desc === 'string' ? (
      tabs[activeTab].desc
    ) : (
      <span>
        Description:
        <br />
        {(tabs[activeTab]?.desc as any).description}
        <br />
        <br />
        Formula:
        <br />
        {(tabs[activeTab]?.desc as any).formula}
      </span>
    )
  ) : undefined

  return (
    <Tabs value={tabs[activeTab]?.label} className="flex flex-col gap-4">
      {/* note: this component L4 is the "correct" place for filters... we should NOT be creating filters in L5. Please move all toggles from L5 to L4 moving forward. */}
      <div className={`flex justify-between items-center ${headerClassName}`}>
        <span className="z-10 flex items-center">
          {tabs[activeTab]?.desc && (
            <Tooltip content={infoContent} placement="right">
              <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
            </Tooltip>
          )}
          {additionalIndicator && (
            <div className="ml-2">{additionalIndicator}</div>
          )}
        </span>
        <TabsHeader
          className={` ${
            tabs.length > 1
              ? 'flex w-auto bg-cc-primary-gray-light rounded-0.5'
              : 'hidden'
          }`}
        >
          {tabs.map(({ label }, idx) => (
            <Tab
              key={label}
              value={label}
              onClick={() => {
                handleClick(idx, label)
              }}
              className={` text-xs rounded-full px-4 py-0.5 text-cc-text-primary font-medium flex-grow border-2 border-neutral-white/0 ${
                activeTab === idx
                  ? '[&>div:nth-child(2)]:!bg-cc-primary-orange [&>div:nth-child(2)]:!opacity-100 text-neutral-white rounded-full'
                  : ''
              } w-auto`}
            >
              <Typography className="text-sm font-medium">{label}</Typography>
            </Tab>
          ))}
        </TabsHeader>
      </div>

      <TabsBody className="overflow-visible">
        {tabs.map(({ label, component }) => (
          <TabPanel key={label} value={label} className="p-0">
            {component}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  )
}

export default L4TabsLayout
