import {
  DataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'

export class CollectionService {
  static async getCollectionRate(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/collection/collection-rate',
      params
    )

    return data
  }
  static async getPrepaymentRate(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/collection/prepayment',
      params
    )

    return data
  }

  static async getNetYield(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/collection/net-yield',
      params
    )

    return data
  }

  static async getCashCollected(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/collection/cash-collected-cohort',
      params
    )

    return data
  }

  static async getCollectionByStatus(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/collection/collection-by-status',
      params
    )

    return data
  }

  static async getAggregatedRollRates(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/collection/aggregate-roll-rate',
      params
    )

    return data
  }
}
