import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { Option, Select } from '@material-tailwind/react'
import { MonitorFilterService } from '@services/api-manage/monitor-filter'

interface FilterCalculationDateProps {
  hideSelect?: boolean
}

const FilterCalculationDate = ({ hideSelect }: FilterCalculationDateProps) => {
  const {
    company,
    optionFilters,
    setOptionFilters,
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  const { calculationDate } = activeFilters
  const { activeFacilityId, calculationDate: appliedCalculationDate } =
    appliedFilters
  const { calculationDateOption = [] } = optionFilters

  const params = {
    slug_name: company?.slug_name ?? '',
  }

  const { data, isFetching } = useQuery(
    ['calculation-date', params],
    () => MonitorFilterService.calculationDate(params),
    {
      ...getStaleMins(),
      enabled: !!activeFacilityId,
    }
  )

  const onChange = (value: any) => {
    setActiveFilters((prev: LayoutFilter) => ({
      ...prev,
      calculationDate: value,
    }))
  }

  useEffect(() => {
    if ((data ?? []).length > 0) {
      const filtered_data = data?.filter(
        d => d.facility_id === activeFacilityId
      )
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDate: filtered_data?.[0]?.calculation_date,
        initialDate: filtered_data?.[0]?.calculation_date,
      }))
      setOptionFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDateOption: filtered_data,
        initialDate: filtered_data?.[0]?.calculation_date,
      }))
      setAppliedFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDate: filtered_data?.[0]?.calculation_date,
        initialDate: filtered_data?.[0]?.calculation_date,
      }))
    }
  }, [data, activeFacilityId])

  useEffect(() => {
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      calculationDateIsFetching: isFetching,
    }))
  }, [isFetching])

  useEffect(() => {
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      appliedCalculationDateOption: calculationDateOption,
    }))
  }, [appliedCalculationDate])

  // hide component if hideSelect is True
  if (hideSelect) {
    return <div></div>
  }

  return (
    <div className="filter__calcuation-date flex flex-col gap-1">
      <Typography className="text-neutral-body-2 text-sm">
        Calculation Date
      </Typography>
      {!isFetching ? (
        <Select
          className="[&~ul]:max-h-[100px] bg-neutral-white text-neutral-black"
          onChange={onChange}
          selected={() => {
            return (
              <span>
                {calculationDate
                  ? moment.utc(calculationDate).format('YYYY-MM-DD')
                  : '-'}
              </span>
            )
          }}
        >
          {(calculationDateOption ?? []).map(
            ({ calculation_date }: { calculation_date: string }, i: number) => (
              <Option key={i} value={calculation_date}>
                {moment.utc(calculation_date).format('YYYY-MM-DD')}
              </Option>
            )
          )}
        </Select>
      ) : (
        <Typography className="text-sm leading-[40px]">
          Loading Dates
        </Typography>
      )}
    </div>
  )
}
export default FilterCalculationDate
