import React, { useContext, useEffect, useState } from 'react'

import FilterFinancialUploadStatus from '@components/filters/filter-accounting-uploads-status'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterEntity from '@components/filters/filter-entity'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'

import AccoutingUploadsL5 from './accounting'

const tabs = [
  { label: 'Accounting', component: <AccoutingUploadsL5 /> },
  // { label: 'Banking', component: <div /> },
  // { label: 'Loantape', component: <div /> },
]

const UploadsOverviewL3 = () => {
  const { activeFilters, appliedFilters, company, setAppliedFilters } =
    useContext(AuthContext)
  const { dateStart, dateEnd, financialUploadStatus, activeEntityId } =
    activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)

  useEffect(() => {
    if (
      !filterReady &&
      dateStart &&
      dateEnd &&
      financialUploadStatus &&
      activeEntityId
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = false

  return (
    <L3TabsLayout
      title="Uploads"
      filters={{
        left: <FilterDateRange isCohort={true} />,
        middle: <FilterFinancialUploadStatus />,
        right: <FilterEntity />,
      }}
      tabs={tabs}
      disableReset={disableReset}
    />
  )
}
export default UploadsOverviewL3
