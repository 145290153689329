import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useFlaggedRoutes } from '@components/app-routes/routes'
import AuthContext from '@contexts/auth'
import { IconButton, Tooltip } from '@material-tailwind/react'

interface SidebarProps {
  /**
   * whether L1 navigation is expanded or not
   */
  expanded: boolean
}

const Sidebar = ({ expanded }: SidebarProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const context = useContext(AuthContext)
  const { setShowSidemenu } = context

  const getFlaggedRouting = useFlaggedRoutes(context, true)
  const flaggedRouting = getFlaggedRouting()

  return (
    <div className={`bg-neutral-white flex flex-col  w-sidebar h-full z-10`}>
      <div className="h-sidebar flex justify-center items-center mb-5">
        <img
          src="/svgs/cascade-logo-2024.svg"
          alt="Cascade"
          data-testid="cascade-logo"
          className="w-[35px] h-[35px] my-6"
        />
      </div>
      <div
        className={`${
          expanded ? 'visible' : 'invisible'
        } lg:visible flex flex-col items-center gap-3`}
      >
        {flaggedRouting.map(r => {
          const isActive = location.pathname.includes(r.path)
          const Icon = isActive ? r.activeIcon : r.icon
          return (
            <Tooltip key={r.path} content={r.title} placement="right">
              <IconButton
                variant="text"
                className={`w-[50px]  !important h-[50px] !important hover:bg-cc-secondary-hover-deselected transition-none rounded-md ${
                  isActive ? 'bg-cc-secondary-hover-selected' : ''
                }`}
                onClick={() => {
                  setShowSidemenu(true)
                  navigate(r.path)
                }}
              >
                <Icon className="w-6 text-primary-main" />
              </IconButton>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}

export default Sidebar
