import React from 'react'

import {
  CategoriesResult,
  PortfolioCovenantCertTemplateProps,
} from '@interfaces/manage-reporting'

// import FsvnPortfolioCovenantCertTemplate from './fsvn/fsvn-portfolio-covenant-template'
import PortfolioCovenantCertTemplate from './default'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: PortfolioCovenantCertTemplateProps
) => {
  switch (category.subcategory) {
    // case 'fsvn-alteriq': {
    //   return <FsvnPortfolioCovenantCertTemplate {...templateProps} />
    // }

    case 'default':
    default: {
      return <PortfolioCovenantCertTemplate {...templateProps} />
    }
  }
}
