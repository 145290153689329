import React, { useContext, useState } from 'react'
import moment, { Moment } from 'moment'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import FormInput from '@components/form/form-input'
import { SIGNER_EMAIL } from '@constants/config'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import {
  WaterfallDetailResponse,
  WaterfallListResponse,
} from '@interfaces/manage-monitor-waterfall'
import { SignatureRequest } from '@interfaces/manage-signature'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { FacilityDetailsService } from '@services/api-manage/facility-details'
import { WaterfallService } from '@services/api-manage/monitor-waterfall'
import SignatureService from '@services/api-manage/signature'

import Template from './report-template'

interface ReportDialogProps {
  open?: boolean
  handler: (needupdate?: boolean) => void
  fxItems: WaterfallDetailResponse[]
  data: WaterfallDetailResponse[]
  remainder: number
  footerData?: WaterfallDetailResponse[]
  date: Moment
  waterfall: WaterfallListResponse
  currency: string
}

const ReportDialog = ({
  open = false,
  handler,
  fxItems,
  data,
  remainder,
  footerData,
  date,
  waterfall,
  currency,
}: ReportDialogProps) => {
  const [selfSign, setSelfSign] = useState<boolean>(false)
  const [signerIndex, setSignerIndex] = useState<number>(0)
  const [disclosure, setDisclosure] = useState<string>('')
  const { user } = useAuth0()
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  const facilityFilter = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility_name ?? '',
    facility_id: activeFacility?.facility_id,
  }
  const { data: facilitylList } = useQuery(
    ['debt-facility-list', facilityFilter],
    () => FacilityDetailsService.getDealPartyDetailsRequest(facilityFilter),
    {
      enabled: !!activeFacility?.facility_id && !!company?.slug_name,
    }
  )
  const agent = facilitylList?.find(x => x.type.toLowerCase() === 'agent')
  const agentDistributionList = agent?.distribution_list
    ? JSON.parse(agent?.distribution_list)
    : []

  const agentSigner = {
    ...agentDistributionList?.[0],
    email: SIGNER_EMAIL ? SIGNER_EMAIL : agentDistributionList?.[0]?.email,
  }

  const agreementDate = moment(activeFacility?.agreement_date).format(
    'Do MMMM YYYY'
  )

  const borrower = facilitylList?.find(x => x.type.toLowerCase() === 'borrower')
  const borrowerDistributionList = borrower?.distribution_list
    ? JSON.parse(borrower?.distribution_list)
    : []

  const guarantors = facilitylList?.filter(
    x => x.type.toLowerCase() === 'guarantor'
  )

  const signer = selfSign
    ? { name: user?.name, email: user?.email, title: user?.title }
    : {
        name: borrowerDistributionList?.[signerIndex]?.name,
        email: borrowerDistributionList?.[signerIndex]?.email,
        title: borrowerDistributionList?.[signerIndex]?.title,
      }

  const handleError = () => {
    toast.dismiss()
    toast.error('An Error occurred during signature request creation', {
      autoClose: 5000,
    })
  }
  const handleSuccess = (data: any) => {
    toast.dismiss()
    if (data.data.signature_request_id) {
      _submitWaterfall(data.data)
    } else {
      handleError()
    }
  }

  const { mutate: requestSignature, isLoading: isLoadingSignature } =
    useMutation(
      (params: SignatureRequest) => {
        return SignatureService.createEmbeddedSignature(params)
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    )

  const _onSubmitSignature = async () => {
    const formData = new FormData()
    formData.append('slug_name', company?.slug_name ?? '')
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    formData.append('category', 'Waterfall Report')
    formData.append('facility', activeFacility?.facility_name)
    formData.append('effective_date', moment(date).format('DD-MM-YYYY'))
    formData.append(
      'title',
      `${waterfall?.waterfall} - ${waterfall?.period} - ${moment(date).format(
        'Do MMMM YYYY'
      )}`
    )
    formData.append(
      'files',
      await pdf(
        <Template
          agent={agent}
          agreementDate={agreementDate}
          borrower={borrower}
          guarantors={guarantors}
          fxItems={fxItems}
          data={data}
          footerData={footerData}
          date={moment(date).format('Do MMMM YYYY')}
          waterfall={waterfall}
          facility={activeFacility}
          currency={currency}
          signer={signer}
          disclosure={disclosure}
        />
      ).toBlob()
    )
    formData.append(
      'subject',
      `Form of Compliance Certificate ${waterfall?.waterfall} - ${
        waterfall?.period
      } - ${moment(date).format('Do MMMM YYYY')}`
    )
    formData.append(
      'message',
      `${signer?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify([
        { name: signer.name, emailAddress: signer.email },
        ...(activeFacility?.waterfall_includes_agent
          ? [{ name: agentSigner.name, emailAddress: agentSigner.email }]
          : []),
      ])
    )
    formData.append('tags', 'true')
    requestSignature(formData as SignatureRequest)
  }

  const { mutate: submitWaterfall, isLoading: isLoadingSubmit } = useMutation(
    (formData: FormData) => {
      return WaterfallService.submit(formData)
    },
    {
      onSuccess: () => {
        handler(true)
      },
      onError: handleError,
    }
  )

  const _submitWaterfall = (signatureRequestResponse: any) => {
    const formData = new FormData()
    formData.append(
      'slug',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facilityId', activeFacility?.facility_id ?? '')
    formData.append('facilityName', activeFacility?.facility_name ?? '')
    formData.append('waterfall', waterfall?.waterfall)
    formData.append('calculationDate', moment(date).format('YYYY-MM-DD'))
    formData.append('requesterName', user?.name ?? '')
    formData.append('status', 'submitted')
    formData.append(
      'signature',
      JSON.stringify({
        signature_request_id: signatureRequestResponse.signature_request_id,
        signatures: signatureRequestResponse.signatures.map((s: any) => ({
          signer_email_address: s.signer_email_address,
          signature_id: s.signature_id,
          status_code: s.status_code,
        })),
        signing_url: signatureRequestResponse.signing_url,
        details_url: signatureRequestResponse.details_url,
        final_copy_uri: signatureRequestResponse.final_copy_uri,
      })
    )
    const finalValueDetail = (footerData ?? []).find(
      detail => detail.operation === 'final_amount'
    )
    formData.append('finalValue', finalValueDetail?.value ?? remainder ?? 0)
    ;[...fxItems, ...data, ...(footerData ?? [])]?.forEach(
      (d: any, i: number) => {
        formData.append(`detail[${i}][defined_term]`, d.defined_term)
        formData.append(
          `detail[${i}][input_value]`,
          d.input_value ?? d.value ?? d.cascade_calculation ?? 0
        )
        d.component?.forEach((c: any, j: number) => {
          formData.append(`detail[${i}][component][${j}][item]`, c.item ?? '')
          formData.append(`detail[${i}][component][${j}][currency]`, c.currency)
          formData.append(
            `detail[${i}][component][${j}][amount]`,
            c.amount ?? 0
          )
          c.transactionProof &&
            formData.append(
              `detail[${i}][component][${j}][transactionProof]`,
              c.transactionProof ?? ''
            )
        })
      }
    )

    submitWaterfall(formData)
  }

  return (
    <Dialog open={open} handler={() => undefined} size="xl">
      <DialogHeader className="text-xl flex justify-between">
        Waterfall
        <XMarkIcon
          onClick={() => handler()}
          className="w-6 h-6 cursor-pointer"
        />
      </DialogHeader>
      <DialogBody
        divider
        className="flex flex-row overflow-y-auto h-[80vh] gap-4"
      >
        <PDFViewer showToolbar={false} className="h-full w-full">
          <Template
            agent={agent}
            agreementDate={agreementDate}
            borrower={borrower}
            guarantors={guarantors}
            fxItems={fxItems}
            data={data}
            footerData={footerData}
            date={moment(date).format('Do MMMM YYYY')}
            waterfall={waterfall}
            facility={activeFacility}
            currency={currency}
            signer={signer}
            disclosure={disclosure}
          />
        </PDFViewer>
        <div className="flex flex-col overflow-y-auto w-[20vw]">
          <div className="mb-4">
            <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
              <Typography className="font-semibold text-sm">Details</Typography>
            </div>
            <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
              <div className="flex justify-between items-center">
                <Typography className="font-medium text-sm">
                  I am the signee
                </Typography>
                <div>
                  <FormInput
                    type="checkbox"
                    value={selfSign || false}
                    {...{
                      onChange: (e: any) => {
                        setSelfSign(e.target.checked)
                      },
                    }}
                  />
                </div>
              </div>
              {!selfSign && borrowerDistributionList?.length > 1 && (
                <div className="flex justify-between items-center gap-2">
                  <Typography className="font-medium text-sm">
                    Signee
                  </Typography>
                  <div>
                    <FormInput
                      type="select"
                      value={signerIndex}
                      onSelected={(val: number) => {
                        setSignerIndex(val)
                      }}
                      options={borrowerDistributionList.map(
                        (s: { name: string; email: string }, i: number) => ({
                          value: i,
                          title: s.name,
                        })
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
              <Typography className="font-semibold text-sm">
                Disclosure Notice
              </Typography>
            </div>
            <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
              <div className="gap-8 grid grid-cols-3 items-center">
                <div className="col-span-3">
                  <FormInput
                    type="textarea"
                    value={disclosure}
                    {...{
                      rows: 10,
                      onBlur: (e: any) => {
                        setDisclosure(e.target.value)
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Button
            type="submit"
            color="primary"
            disabled={isLoadingSubmit || isLoadingSignature}
            onClick={async () => await _onSubmitSignature()}
          >
            {(isLoadingSubmit || isLoadingSignature) && (
              <ArrowPathIcon className="animate-spin text-primary-main w-4 mr-2" />
            )}
            Request
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default ReportDialog
