export enum CovenantType {
  portfolio = 'portfolio',
  financial = 'financial',
}

export interface CovenantListRequest {
  slug_name: string
  facility_id: number
  type: CovenantType
}

export interface CovenantListResponse {
  covenant_id: number
  covenant_name: string
  covenant_type: CovenantType
  label: string
  format: string
  multiplier: number
  date_as_of?: string
  current_trigger?: number
  unit?: string
  values?: {
    label: string
    value: number
  }[]
  params: {
    audit_columns: string[]
    cohort_based: boolean
    column: string[]
    invert_colours: boolean
    multiplier: number
    special_covenent: string
    label_format: string
    label: string
    x_axis: string[]
    special_series_label: string
    special_series_field: string
  }
  triggers?: {
    breach_count: number
    in_breach: number
    trigger: number
    value: number
  }[]
}

export interface CovenantDetailRequest extends CovenantListRequest {
  id: number
}

export enum CovenantChartType {
  vintage = 'Vintage',
  table = 'Table',
}

export enum CovenantCohortPool {
  year = 'year',
  month = 'month',
  week = 'week',
  day = 'day',
}

export interface CovenantDetailResponse {
  data: {
    cohort: string
    trigger_value: number
    mob: number
    /**
     * trigger_{counter}
     * value_{counter}
     */
    [key: string]: any
  }[]
  params: {
    /**
     * https://www.notion.so/cascadedebt/Covenant-ae85c453ee034f8eb75ffc4238eba429?pvs=4#7c8b7fa7b83344a5a430930cd93250f9
     */
    special_covenant?: CovenantChartType
    invert_colours?: boolean
    format?: string
    cohort_pool?: CovenantCohortPool
    label?: string
    [key: string]: any
  }
}

export interface CovenantRawResponse {
  [key: string]: any
}
