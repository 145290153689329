import React, { useContext, useState } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { useQuery } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import BaseLayout from '@components/layouts/base-layout'
import Pagination from '@components/pagination'
import CustomTable from '@components/table'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { Entity } from '@interfaces/accounting/financials'
import { Alert, Card, CardBody, Tooltip } from '@material-tailwind/react'
import { EntityService } from '@services/api-admin/settings-entities'

import FinancialUploadsStatusLabel from '../uploads/accounting/status-label'

import UploaderDialog from './uploader'

const AccountingOverviewL3 = () => {
  const { company } = useContext(AuthContext)
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const [isUploaderOpen, setIsUploaderOpen] = useState<boolean>(false)
  const [selectedEntity, setSelectedEntity] = useState<Entity>()

  const PER_PAGE = 10
  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const filters = {
    slugName: company?.slug_name ?? '',
    page: page,
    size: perPage,
  }

  const handleUploaderDialog = (entity?: Entity) => {
    setIsUploaderOpen(!isUploaderOpen)
    setSelectedEntity(entity)
  }

  const {
    data: entityData,
    isLoading,
    error,
    refetch,
  } = useQuery(['allEntities', filters], () =>
    EntityService.listEntities(filters)
  )

  const expandedRowRender = (record: any) => {
    const connType = record.data_source
    switch (connType) {
      default:
        const submission = record.lastUpload
        return (
          <div className="[&>div]:!mb-0 mx-40">
            <div className="flex grid grid-cols-2 gap-2 text-xs font-light">
              <div className="col-span-2 font-medium text-sm -ml-5">
                Most Recent Submission
              </div>

              <div>Date Uploaded</div>
              <div>
                {submission.dateUploaded
                  ? moment.utc(submission.dateUploaded).format('YYYY-MM-DD')
                  : 'N/A'}
              </div>
              <div>File Status</div>
              <div className="flex">
                {submission.state ? (
                  <FinancialUploadsStatusLabel upload={submission} />
                ) : (
                  'N/A'
                )}
              </div>
              {submission.state == 'processed' && (
                <>
                  {' '}
                  <div>Historical Count Difference</div>
                  <div>{submission.diff_count}</div>
                  <div>Download Links</div>
                  <div className="flex flex-col text-blue">
                    <div className="flex">[Original Upload]</div>
                    <div className="flex ">[Delta Log]</div>
                  </div>
                </>
              )}
            </div>
          </div>
        )
    }
  }

  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }
  const columns = [
    {
      title: 'Legal Name',
      align: 'start',
      field: 'entityLegalName',
      className: 'border-none',
    },
    {
      title: 'Nickname',
      align: 'start',
      field: 'entityNickname',
      className: 'border-none',
    },

    {
      title: 'Currency',
      align: 'start',
      field: 'currency',
      className: 'border-none',
    },
    {
      title: 'Geography',
      align: 'start',
      field: 'geographies',
      className: 'border-none',
    },
    {
      title: '',
      align: 'center',
      field: '',
      className: 'border-none',

      render: (row: any) => {
        return (
          <div className="flex">
            {/* appears by default */}
            {!['xero/api', 'quickbooks/api'].includes(row.data_source) && (
              <Tooltip content={<span>Upload XLSX file</span>} placement="top">
                <CloudArrowUpIcon
                  className="w-5 stroke-blue opacity-70 hover:opacity-100"
                  onClick={() => handleUploaderDialog(row)}
                />
              </Tooltip>
            )}
            {/* {['xero/api', 'quickbooks/api'].includes(row.data_source) && (
              <Tooltip content={<span>Resync</span>} placement="top">
                <ArrowPathIcon className="w-5 stroke-blue opacity-70 hover:opacity-100" />
              </Tooltip>
            )} */}
          </div>
        )
      },
    },
  ]
  return (
    <BaseLayout title="Accounting">
      <Card>
        <CardBody>
          <div className="flex justify-between mb-4">
            <div className="flex w-full justify-between">
              <Typography variant="h6">Upload Data for Entities</Typography>
              <Tooltip content={<div>Refresh</div>} placement="top">
                <div>
                  <Button className="mx-2" onClick={() => refetch()}>
                    <ArrowPathIcon className={'w-7'} />
                  </Button>
                </div>
              </Tooltip>
            </div>
          </div>
          {isLoading ? (
            <div className="w-full flex justify-center mt-2">
              {' '}
              <ArrowPathIcon className="w-7 animate-spin" />
            </div>
          ) : error ? (
            <div>
              <Alert className="border border-danger-main text-danger-main text-center mb-6">
                {((error as AxiosError).response?.data as any)?.error ??
                  (error as AxiosError).message}
              </Alert>
            </div>
          ) : !!entityData.body.length ? (
            <>
              <CustomTable
                data={entityData.body}
                columns={columns as any}
                expandable={{
                  expandedIndexes,
                  expandedRowRender,
                  onExpand,
                }}
                borderless={true}
              />
              {entityData?.page.total > PER_PAGE && (
                <Pagination
                  id="accounting-uploads-table-pagination"
                  total={entityData?.page.total ?? 0}
                  page={page}
                  onChangePage={p => setPage(p)}
                  limit={perPage}
                  limits={Array(1)
                    .fill('')
                    .map((_, i) => PER_PAGE * (i + 1))}
                  onChangeLimit={l => {
                    setPage(1)
                    setPerPage(l)
                  }}
                />
              )}
            </>
          ) : (
            <div>
              <div className="w-full mt-6 mb-1">
                No Active Entities to display.
              </div>
            </div>
          )}
        </CardBody>
      </Card>
      <UploaderDialog
        open={isUploaderOpen}
        handleOpen={handleUploaderDialog}
        entity={selectedEntity}
      />
    </BaseLayout>
  )
}
export default AccountingOverviewL3
