import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
//import StatisticL5 from '@components/layouts/l5-stat'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { FinancialsFilters } from '@interfaces/financial'
import { FinancialKeyIndicatorService } from '@services/api-analytics/financials-key-indicators'

const DebtEquityL5 = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { dateStart, dateEnd, financialEntity, currency } = appliedFilters

  const filters: FinancialsFilters = {
    dateFrom: moment.utc(dateStart).format('YYYY-MM-DD'),
    dateTo: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slugName: company?.slug_name ?? '',
    entityId: financialEntity,
    currency,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['debtToEquity', filters],
    () => FinancialKeyIndicatorService.getDebtEquityRatio(filters),
    getStaleMins()
  )
  let minVal = 0
  let maxVal = 100

  const {
    graphData,
    //...growthRatios
  } = fetchedData ?? {}

  const chartData = (graphData || []).map((c: any) => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.date).valueOf(),
    }
    result.type_all = parseFloat(c.val.toString())
    if (c.val > maxVal) {
      maxVal = c.val
    }
    if (c.val < minVal) {
      minVal = c.val
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [graphData])

  const series: any = [
    {
      label: 'All',
      field: 'type_all',
      tooltipLabel: `Debt:Equity`,
      hasBullet: true,
      type: 'SmoothedXLineSeries',
    },
  ]

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          data={chartData}
          id={`debtEquity`}
          yLabel={'Debt : Equity Ratio'}
          yFormat="#.00a"
          ySetting={{ min: minVal, max: maxVal }}
          series={series}
          exportable={exportable}
          error={error as { message: string }}
        />
      }
      //stat={<StatisticL5 {...growthRatios} />}
    />
  )
}

export default DebtEquityL5
