import React from 'react'

import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'

import {
  AutochekDeloreanNigeriaTemplate,
  AutochekLendableTemplate,
} from './autochek'
import CoignAdvanceRequestTemplate from './coign'
import FlexpathAdvanceRequestTemplate from './flexpath'
import { GenericTemplate } from './generic'

const renderTemplate = (
  subcategory: string,
  templateProps: AdvanceRequestTemplateProps
) => {
  switch (subcategory) {
    case 'autochek-lendable': {
      return <AutochekLendableTemplate {...templateProps} />
    }
    case 'autochek-delorean-nigeria': {
      return <AutochekDeloreanNigeriaTemplate {...templateProps} />
    }
    case 'coign': {
      return <CoignAdvanceRequestTemplate {...templateProps} />
    }
    case 'flexpath-facility1': {
      return <FlexpathAdvanceRequestTemplate {...templateProps} />
    }
    case 'generic2': {
      return <GenericTemplate {...templateProps} calcTableVersion={2} />
    }
    case 'generic3': {
      return <GenericTemplate {...templateProps} calcTableVersion={3} />
    }
    default: {
      return <GenericTemplate {...templateProps} calcTableVersion={1} />
    }
  }
}

export default renderTemplate
