import React, { useContext } from 'react'

import AuthContext from '@contexts/auth'
import { Tooltip } from '@material-tailwind/react'

interface ExchangeRateBadgeProps {
  isHistorical?: boolean
}
const ExchangeRateBadge = ({ isHistorical = true }: ExchangeRateBadgeProps) => {
  const { optionFilters } = useContext(AuthContext)
  const { rates = {} } = optionFilters

  const cohorts = Object.keys(rates)
  const latest_rates = rates[cohorts.slice(-1)[0]] ?? {}

  return isHistorical ? (
    <div className="text-xs rounded-full px-2 py-1 border border-primary-main text-primary-main flex items-center text-center">
      Converted with historical exchange rate
    </div>
  ) : (
    <Tooltip
      content={
        <>
          {Object.keys(latest_rates).map((c: string) => (
            <div key={c}>{`USD:${c} = ${Intl.NumberFormat(undefined, {
              style: 'decimal',
              maximumFractionDigits: 2,
            }).format(latest_rates[c])}`}</div>
          ))}
        </>
      }
      placement="right"
    >
      <div className="text-xs rounded-full px-2 py-1 border border-primary-main text-primary-main flex items-center text-center">
        Converted with spot exchange rate
      </div>
    </Tooltip>
  )
}

export default ExchangeRateBadge
