import {
  DownloadParams,
  FinancialsFileParams,
  ListTemplatesParams,
} from '@interfaces/accounting/financials'

import adminApiClient from './client'

export class TemplatesService {
  static async uploadTemplate(params: FinancialsFileParams) {
    if (params.legalEntityID) {
      const formData = new FormData()
      params.file && formData.append('files', params.file)

      const { data } = await adminApiClient.post(
        `accounting/financials-template/${params.slugName}/${params.legalEntityID}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      return data
    }
  }

  static async listTemplates(params: ListTemplatesParams) {
    const { data } = await adminApiClient.get(
      `accounting/list-financials-template/${params.slugName}`,
      { params }
    )
    return data
  }

  static async downloadTemplate(params: DownloadParams) {
    const { data } = await adminApiClient.get(
      `accounting/file-download-link/${params.slugName}`,
      { params }
    )
    return data
  }
}
