import { createContext } from 'react'
import { noop } from 'lodash'
import moment from 'moment'

import { User } from '@auth0/auth0-react'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import Company from '@interfaces/company'
import { LoadingData } from '@interfaces/loading'
import { UserMetadata } from '@interfaces/user-metadata'
import { UserService } from '@services/api-admin/settings-user'

export function setMetadata(
  user?: User,
  setUserMetadata?: any,
  setAuthResponse?: any
) {
  UserService.getUserById(user?.sub)
    .then(res => {
      const user_metadata = res?.user_metadata
      setUserMetadata({
        isSuperadmin: user_metadata?.is_superadmin,
        tosAccepted: user_metadata?.TOSAccepted,
        tosTimestamp: user_metadata?.TOSTimestamp,
        origin: user_metadata?.origin,
        companies: user_metadata?.companies,
      })
    })
    .catch(error => {
      if (setAuthResponse) {
        setAuthResponse(
          error?.response?.data?.error ??
            "Apologies, we're currently unable to retrieve your details; please try again later"
        )
      }
    })
}

/**
 * Hopefully this stucture versatile enough to accomodate all kind of filter.
 * The rest is just matter of UI
 */
export interface AuthContextValues {
  userMetadata?: UserMetadata
  setUserMetadata: (u: UserMetadata) => void

  company?: Company
  setCompanyDetails: (c: Company) => void

  optionFilters: LayoutFilter
  setOptionFilters: (filters: LayoutFilter) => void

  /**
   * Manage filters that were changed by a client but have not been applied yet. A state of the changed filters on the UI.
   */
  activeFilters: LayoutFilter
  setActiveFilters: (filters: LayoutFilter) => void

  /**
   * Applied filters are the information that is sent to the BE as parameters. Refreshed when a user hits `apply` button.
   */
  appliedFilters: LayoutFilter
  setAppliedFilters: (filters: LayoutFilter) => void

  showFilter: boolean
  setShowFilter: (isShow: boolean) => void

  showSidemenu: boolean
  setShowSidemenu: (isShow: boolean) => void

  loadingData: LoadingData
  setLoadingData: (loadingData: LoadingData) => void
}

const AuthContext = createContext<AuthContextValues>({
  userMetadata: {
    isSuperadmin: false,
    tosAccepted: false,
  },
  setUserMetadata: noop,

  company: {
    legal_name: '',
    slug_name: '',
    date_start: moment().format('YYYY-MM-DD'),
    date_end: moment().format('YYYY-MM-DD'),
    date_end_cohort: moment().format('YYYY-MM-DD'),
    writeoff_days: 0,
    percent_paidoff: 0,
    has_analytics: false,
    has_manage: false,
    has_raise: false,
    loanTapeAsOf: '',
    bankDataAsOf: '',
    financialsAsOf: '',
    lastAnalyticsPipeline: '',
    lastManagePipeline: '',
  },
  setCompanyDetails: noop,

  optionFilters: {},
  setOptionFilters: noop,

  appliedFilters: {},
  setAppliedFilters: noop,

  activeFilters: {},
  setActiveFilters: noop,

  showFilter: false,
  setShowFilter: noop,

  showSidemenu: true,
  setShowSidemenu: noop,

  loadingData: {},
  setLoadingData: noop,
})

export default AuthContext
