import React, { useState } from 'react'
import moment from 'moment'

import { numberFormatter } from '@helpers/number-formatter'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'

import Activity from '../../activity'

interface AuditInfoAccordionGroupProps {
  artefact: string
  showCols: string[]
  data: any
}

export const AuditInfoAccordionGroup = ({
  artefact,
  showCols,
  data,
}: AuditInfoAccordionGroupProps) => {
  const [openAccordion, setOpenAccordion] = useState(1)

  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpenAccordion(0)
    //avoids unexpect behaviour on height of the dialog
    setTimeout(() => {
      setOpenAccordion(openAccordion === value ? 0 : value)
    }, 200)
  }

  const AccordionIcon = ({ open }: { open: boolean }) => {
    const Icon = open ? ChevronUpIcon : ChevronDownIcon
    return <Icon className="w-4 h-4" />
  }

  const artefactInfo = Object.keys(data.data)
    .map((key: string) => {
      return {
        artefact_info: key,
        artefact_val: data.data[key],
      }
    })
    .filter((item: any) => {
      return showCols.includes(item.artefact_info)
    })

  return (
    <div>
      <>
        <Accordion
          icon={<AccordionIcon open={openAccordion == 1} />}
          open={openAccordion == 1}
          className={`flex flex-col ${
            openAccordion == 1 ? `[&>div]:!h-[unset]` : ``
          }`}
        >
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className="text-md text-left"
          >
            {`${artefact} Information`}
          </AccordionHeader>
          <AccordionBody className="flex flex-col p-0 pt-0 pb-2 pt-2 overflow-y-auto">
            {artefactInfo.map((artefact: any, idx: number) => (
              <div key={idx} className="flex grid grid-cols-2 gap-4 my-1">
                <div className="font-bold">
                  {artefact.artefact_info
                    .replace('artefact', artefact)
                    .toUpperCase()
                    .replace('_', ' ')}
                </div>
                <div>
                  {artefact.artefact_info.includes('date')
                    ? moment(artefact.artefact_val, 'DD-MM-YYYY').format(
                        'YYYY-MM-DD'
                      )
                    : typeof artefact.artefact_val == 'number'
                    ? numberFormatter(artefact.artefact_val)
                    : artefact.artefact_val}
                </div>
              </div>
            ))}
          </AccordionBody>
        </Accordion>
        <Accordion
          icon={<AccordionIcon open={openAccordion == 2} />}
          open={openAccordion == 2}
          className={`flex flex-col`}
        >
          <AccordionHeader
            onClick={() => handleOpen(2)}
            className="text-md text-left"
          >
            Activity
          </AccordionHeader>
          <AccordionBody className="flex flex-col py-2 h-[160px] overflow-y-auto">
            <Activity data={data.activity} />
          </AccordionBody>
        </Accordion>
      </>
    </div>
  )
}
