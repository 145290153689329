import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import Chart from '@components/chart'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { HistoricalSurplusResponse } from '@interfaces/manage-monitor-borrowing-base'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

const HistoricalSurplusChart = ({ expanded = true }: { expanded: boolean }) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId, calculationDate } = appliedFilters

  const filters = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
  }

  const { data, isFetching } = useQuery(
    ['historical-surplus', filters],
    () => BorrowingBaseService.historicalSurplus(filters),
    { ...getStaleMins(), enabled: !!calculationDate }
  )

  const unix_calc_date = moment.utc(calculationDate).valueOf()

  let minVal = 0
  const chart_data = (data ?? []).reduce(
    (_px: any[], x: HistoricalSurplusResponse) => {
      const current_unix = moment.utc(x.calculation_date).valueOf()
      const is_same_or_before = current_unix <= unix_calc_date
      minVal = Math.min(x.val, minVal)

      return is_same_or_before
        ? [..._px, { x: current_unix, y: x.val ?? 0 }]
        : _px
    },
    []
  )

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [calculationDate, expanded])

  const loading = isFetching || isProcessing || !calculationDate

  const tableHeight =
    document?.getElementById('summary-table')?.clientHeight ?? 0
  const height = (tableHeight < 500 ? 780 : tableHeight) - 100

  return (
    <>
      <Typography className="text-xl font-medium mb-4 text-center">
        Historic Borrowing Base Surplus/Deficiency
      </Typography>
      <Chart
        loading={loading}
        id="history"
        yLabel="USD"
        ySetting={{
          fontSize: 12,
          min: minVal,
        }}
        xLabel="Calculation Date"
        xSetting={{
          fontSize: 12,
          dateAxis: {
            baseInterval: { timeUnit: 'day', count: 1 },
          },
        }}
        data={chart_data}
        series={[
          {
            label: 'All',
            type: 'SmoothedXLineSeries',
            field: 'y',
            hasBullet: false,
            tooltipValueFormat: `#.0a USD`,
          },
        ]}
        scroll={{ x: expanded, y: expanded }}
        height={expanded ? height ?? 400 : 250}
        exportable={false}
      />
    </>
  )
}

export default HistoricalSurplusChart
