import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Option, Select } from '@material-tailwind/react'
import CohortService from '@services/api-analytics/risk-cohort'
import FilterService from '@services/api-analytics/risk-filter'

const CohortRollRatesL5 = ({ exportable }: { exportable: boolean }) => {
  const RETURNED_DATE_FORMAT = 'MMM-YY'
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { display_rates = [], rates = {} } = optionFilters
  const isAggregate = activeType === 'All'
  const [isVolume, setIsVolume] = useState<boolean>(true)
  const [cohort, setCohort] = useState<string>('All')

  const avgFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    filters: isAggregate ? [] : categoryTypes,
    slug_name: company?.slug_name ?? '',
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: categoryTypes,
    is_volume: isVolume,
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortRollRatesCohort', filters],
    () => CohortService.getCohortRollRates(filters),
    { ...getStaleMins(), enabled: avgTermIsFetched }
  )

  const cohorts = (data?.data ?? []).reduce(
    (prev: string[], cur) =>
      prev.includes(cur.cohort)
        ? prev
        : cur.cohort.toLowerCase().includes('all')
        ? [cur.cohort, ...prev]
        : [...prev, cur.cohort],
    []
  )

  const dpds = (data?.data ?? [])
    .reduce(
      (prev: string[], cur) =>
        prev.includes(cur.bucket_label) ? prev : [...prev, cur.bucket_label],
      []
    )
    .sort((a, b) =>
      a.toLowerCase() === 'paid off'
        ? -1
        : Number(a.split(/(?:[-+\s]+)/)[0]) - Number(b.split(/(?:[-+\s]+)/)[0])
    )

  const currentData = (data?.data ?? []).filter(d => d.cohort === cohort)
  const currentMoBs = currentData?.reduce(
    (prev: number[], cur) =>
      prev.includes(cur.mob) ? prev : [...prev, cur.mob],
    []
  )

  const chartData = currentMoBs.map(m => {
    const res: { x: number; [key: string]: number | string } = { x: m }
    dpds.forEach(d => {
      const current = currentData.find(x => x.bucket_label === d && x.mob === m)
      res[d] = !current ? 0 : current.val
    })
    return res
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [cohort, data, isVolume])
  /** end */

  /**
   * zoom
   */

  const xStart = 0
  const xSpan = Math.ceil(Number(avgTerm ?? 0) / 30) + 5
  const xEnd =
    chartData?.length === 0
      ? 0
      : xSpan >= chartData?.length
      ? chartData?.[chartData?.length - 1]?.x
      : chartData?.[xSpan - (xSpan > 0 ? 1 : 0)].x /
        chartData?.[chartData?.length - 1]?.x

  const maxCohort = moment.max(
    cohorts
      .filter(c => moment.utc(c, RETURNED_DATE_FORMAT).isValid())
      .map(c => moment.utc(c, RETURNED_DATE_FORMAT))
  )
  const conversionCohort = moment.utc(cohort, RETURNED_DATE_FORMAT).isValid()
    ? moment.utc(cohort, RETURNED_DATE_FORMAT)
    : maxCohort

  return (
    <L5ChartstatLayout
      chart={
        <>
          <div className="flex justify-end -mt-12">
            {[
              ...(data
                ? [
                    {
                      type: 'dropdown',
                      title: 'Cohort',
                      value: cohort,
                      options: cohorts.map(c => ({
                        value: c,
                        title: c.toLowerCase().includes('all')
                          ? c.split('_').join(' ')
                          : moment(c, 'MMM-YY').format('MMM-YY'),
                      })),
                      action: (val: string) => setCohort(val),
                    },
                  ]
                : []),
              {
                type: 'toggle',
                title: 'Measurement',
                options: [
                  {
                    title: '# of Loans',
                    active: !isVolume,
                    action: () => setIsVolume(false),
                  },
                  {
                    title: 'Volume',
                    active: isVolume,
                    action: () => setIsVolume(true),
                  },
                ],
              },
            ].map((group, i) => {
              switch (group.type) {
                case 'dropdown':
                  return (
                    <div key={i} className="flex flex-col ml-4 min-w-[150px]">
                      <span className="mb-2 text-sm font-semibold">
                        {group.title}
                      </span>
                      <Select
                        className="[&~ul]:max-h-[100px]"
                        value={group.value}
                        onChange={(val: any) => group.action?.(val)}
                      >
                        {group.options.map((o: any) => {
                          return (
                            <Option key={o.value} value={o.value}>
                              {o.title}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                  )
                default:
                  return (
                    <div key={i} className="flex flex-col ml-4">
                      <span className="mb-2 text-sm font-semibold">
                        {group.title}
                      </span>
                      <div className="bg-neutral-border-1 rounded-md p-1 flex">
                        {group.options.map((o, j) => {
                          const option = o as {
                            active: boolean
                            action: () => void
                            title: string
                          }
                          return (
                            <button
                              key={j}
                              className={`flex-1 text-sm rounded-md px-3 py-1.5 min-w-[100px] ${
                                option.active
                                  ? 'bg-primary-hover text-white'
                                  : ''
                              }`}
                              onClick={option.action}
                            >
                              {option.title}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  )
              }
            })}
          </div>
          <Chart
            loading={isFetching || isProcessing || avgTermIsFetching}
            id={`riskCohortRollRatesCohort_by_${activeType}`}
            yLabel={!isVolume ? 'Number of Loans' : 'Volume'}
            yFormat="#.00a%"
            xLabel="Months on Books"
            xAxisType="CategoryAxis"
            data={chartData}
            series={[...dpds].map(d => {
              return {
                label: d,
                tooltipValueFormat: '#.00a%',
                type: 'ColumnSeries',
                field: d,
                isStack: true,
              }
            })}
            exportable={exportable}
            error={error as { message: string }}
            scroll={{ y: true, x: true, xStart, xEnd }}
          />
          {showHistoricalRateIndicator && isVolume && (
            <div className="flex gap-4 mt-4">
              {display_rates.map((r: any) => (
                <div
                  key={r}
                  className="rounded-full p-2 border border-neutral-border-1 text-xs text-center bg-neutral-surface-1"
                >
                  <span className="font-medium">{`USD:${r}`}</span>
                  {` = 
                  ${Intl.NumberFormat(undefined, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  }).format(
                    rates[
                      conversionCohort
                        .endOf('month')
                        .startOf('date')
                        .toISOString()
                    ]?.[r] ?? 1
                  )}`}
                </div>
              ))}
            </div>
          )}
          <div
            className={`mt-4 overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)] cascade-table`}
          >
            <table className="border-separate border-spacing-0">
              <thead className="sticky top-0 z-10">
                <tr>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[50px] sticky left-0 bg-neutral-white">
                    MoB
                  </th>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[120px] sticky left-[50px] bg-neutral-white">
                    {`${!isVolume ? 'Number of Loans' : 'Volume'}`}
                  </th>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[120px] sticky left-[170px] bg-neutral-white">
                    {`Outstanding ${!isVolume ? 'Number of Loans' : 'Volume'}`}
                  </th>
                  {dpds.map((x, i) => (
                    <th
                      key={i}
                      className="p-3 min-w-[120px] border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
                    >
                      {x}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentMoBs.map(m => {
                  return (
                    <tr key={m}>
                      <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 bg-neutral-white text-sm text-center">
                        {m}
                      </td>
                      {dpds.map((_d, i) => {
                        const columnData = currentData.find(
                          x => x.mob === m && x.bucket_label === _d
                        )
                        const val = !columnData ? 0 : Number(columnData?.val)
                        const firstBucketData = currentData.find(
                          x => x.mob === m
                        )

                        return (
                          <>
                            <>
                              {i === 0 && (
                                <>
                                  <td className="p-3 border-r border-b border-neutral-border-1 text-sm text-right sticky left-[50px] bg-neutral-white">
                                    {`${Intl.NumberFormat(undefined, {
                                      style: 'decimal',
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                      currency,
                                      notation: 'compact',
                                    }).format(firstBucketData?.total ?? 0)} ${
                                      !isVolume ? '' : currency
                                    }`}
                                  </td>
                                  <td className="p-3 border-r border-b border-neutral-border-1 text-sm text-right sticky left-[170px] bg-neutral-white">
                                    {`${Intl.NumberFormat(undefined, {
                                      style: 'decimal',
                                      maximumFractionDigits: 2,
                                      currency,
                                      notation: 'compact',
                                    }).format(firstBucketData?.outstanding)} ${
                                      !isVolume ? '' : currency
                                    }`}
                                  </td>
                                </>
                              )}
                            </>
                            <td
                              key={i}
                              className="p-3 border-r border-b border-neutral-border-1 text-sm text-right"
                            >
                              {Intl.NumberFormat(undefined, {
                                style: 'percent',
                                notation: 'compact',
                                maximumFractionDigits: 2,
                              }).format(val / 100)}
                            </td>
                          </>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      }
    />
  )
}

export default CohortRollRatesL5
