import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import Chart from '@components/chart'
import ExchangeRateBadge from '@components/exchange-rate-badge'
// import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiOptionButtonGroup from '@components/selectors/multi-option-button'
import Table from '@components/table'
import { AVG_MONTH_THRESHOLD_DAYS } from '@constants/app'
import { REMOUNT_MS } from '@constants/config'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { useGetRiskData } from '@helpers/risk-charts-data-hook'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { DataResponse, RiskData } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { Switch, Tooltip } from '@material-tailwind/react'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const ParL5 = ({ exportable }: { exportable: boolean }) => {
  const [value, setValue] = useState<number>(7)
  const [isAdjusted, setIsAdjusted] = useState<boolean>(false)
  const { showTable, setShowTable } = useContext(ApplicationCustomContext)

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { categoryTypes = [], activeType, currency = 'USD' } = appliedFilters
  const {
    rates = {},
    display_rates = [],
    categoryTypeOptions = [],
  } = optionFilters
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const {
    fetchedDataAggregated,
    data,
    growthRatios,
    cohort,
    avgTerm,
    avgTermIsFetched,
    error,
    isFetching,
    isAggregate,
  } = useGetRiskData(DelinquencyService.getPar, 'prr', false, true, {
    is_adjusted: isAdjusted,
    value: `par${value}`,
  })

  let maxVal = 0

  const chartData = cohort.map((c: RiskData<DataResponse>) => {
    const result = historicalExchgList(rates, c, c.cohort)
    const cohortData = (data || []).filter(
      (d: RiskData<DataResponse>) => d.cohort === c.cohort
    )
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      result.type_all_denom = parseFloat(
        (
          cohortData?.[0]?.[`${isAdjusted ? 'adj_' : ''}par_denom`] ?? 0
        ).toString()
      )
      result.type_all_numer = parseFloat(
        (
          cohortData?.[0]?.[`${isAdjusted ? 'adj_' : ''}par_numer`] ?? 0
        ).toString()
      )
      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find((cd: RiskData<DataResponse>) => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        result[`type_${ct}_denom`] = parseFloat(
          (
            cohortCategory?.[`${isAdjusted ? 'adj_' : ''}par_denom`] ?? 0
          ).toString()
        )
        result[`type_${ct}_numer`] = parseFloat(
          (
            cohortCategory?.[`${isAdjusted ? 'adj_' : ''}par_numer`] ?? 0
          ).toString()
        )
        if (result[`type_${ct}`] > maxVal) {
          maxVal = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const parSeries: any[] = isAggregate
    ? [
        {
          label: `${value} DPD`,
          tooltipValueFormat: '#.00a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.00a%',
          type: 'SmoothedXLineSeries',
          field: `type_${ct}`,
          color,
          hasBullet: true,
        }
      })

  const header_data = [
    parSeries.reduce((p: any, s: any) => {
      return {
        ...p,
        [`${s.field}_denom`]: `${
          isAdjusted ? 'Adjusted ' : ''
        }Outstanding Balance (Total)`,
        [`${s.field}_numer`]: `${
          isAdjusted ? 'Adjusted ' : ''
        }Outstanding Balance (> ${value}DPD)`,
        [s.field]: `PAR${value}`,
      }
    }, {}),
  ]

  const table_column = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...parSeries.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_numer`,
          title: header_data[0][`${s.field}_numer`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_numer`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_denom`,
          title: header_data[0][`${s.field}_denom`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_denom`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `Portfolio at Risk - ${
            isAdjusted ? 'Adjusted ' : ''
          }Outstanding Balance - ${value}DPD ${
            isAggregate ? '' : `(${s.label})`
          }`,
          head: { colSpan: 3, align: 'center' },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]

  const showAdjustedToggle = avgTermIsFetched
    ? Number(avgTerm ?? 0) <= AVG_MONTH_THRESHOLD_DAYS
    : false

  return (
    <div className="flex flex-col gap-y-2 md:gap-y-6">
      <MultiOptionButtonGroup
        buttonSelections={[
          ...(showAdjustedToggle
            ? [
                {
                  options: [
                    {
                      title: 'Outstanding Balance',
                      active: !isAdjusted,
                      action: () => setIsAdjusted(false),
                    },
                    {
                      title: 'Adjusted Outstanding Balance',
                      active: isAdjusted,
                      action: () => setIsAdjusted(true),
                    },
                  ],
                },
              ]
            : []),
          {
            options: [7, 30, 60, 90].map(dpd => {
              return {
                title: `${dpd} DPD`,
                active: value === dpd,
                action: () => setValue(dpd),
              }
            }),
          },
        ]}
      />

      <div className="flex flex-col lg:flex-row gap-6">
        <div className="flex-1">
          <Chart
            loading={isFetching || isProcessing}
            id={`dpd${value}Par_by_${activeType}`}
            yLabel="Percentage"
            yFormat="#.00a%"
            ySetting={maxVal < 5 ? { max: 5, maxPrecision: 1 } : {}}
            data={chartData}
            series={parSeries as any}
            tooltipSubtitle={
              showHistoricalRateIndicator
                ? displayRates(display_rates)
                : undefined
            }
            exportable={exportable}
            exportableColumn={table_column}
            error={error as { message: string }}
          />
        </div>
        <StatisticL5
          description={`percentage at ${value}DPD`}
          classSetting={{
            up: 'text-cc-negative',
            down: 'text-cc-positive',
          }}
          {...growthRatios}
        />
      </div>

      <div className="flex justify-between md:-mt-4 ">
        <div className="flex justify-center items-center">
          <Tooltip
            content={
              <span>
                Description:
                <br />
                {`Always paired with a DPD cut-off, PAR is the percentage of
                        the outstanding balance value that is more than the DPD
                        cut-off. Eg. PAR${value} is the percentage of the outstanding
                        balance that is more than ${value} DPD.`}
                <br />
                <br />
                Outstanding Balance:
                <br />
                All unpaid principal as of a specific date.
                <br />
                <br />
                {showAdjustedToggle && (
                  <>
                    Adjusted Outstanding Balance:
                    <br />
                    All unpaid principal as of specific date + paid principal
                    for loans disbursed and paid in same month
                    <br />
                    <br />
                  </>
                )}
                Formula:
                <br />
                Outstanding Balance Value of all loans with a DPD past the DPD
                cut-off - Writeoffs / Total Outstanding Balance Value(including
                loans paid off within this month only)
                <br />
                <br />
                Write Offs = {company?.writeoff_days} days
                <br />
              </span>
            }
            placement="right"
          >
            <InformationCircleIcon className="w-8 text-cc-icon-primary cursor-help hover:text-primary-hover" />
          </Tooltip>
          {showHistoricalRateIndicator && <ExchangeRateBadge />}
        </div>
        <Switch
          label="Show Data"
          checked={showTable}
          onChange={() => {
            setShowTable(!showTable)
          }}
          defaultChecked
          color="light-blue"
          crossOrigin={undefined}
        />
      </div>

      {showTable && (
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          headerData={header_data}
          columns={table_column}
        />
      )}
    </div>
  )
}

export default ParL5
