import React from 'react'
import moment from 'moment'

import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../document-centre/templates/components'
import { styles } from '../template-style'

const CoignAdvanceRequestTemplate = ({
  agent,
  borrower,
  requestDate,
  advanceDate,
  loanAgreementDate,
  formData,
}: AdvanceRequestTemplateProps) => {
  const formatCurrency = (amount: number) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount)
  }

  const bbData = formData.borrowing_base_data
  const appendixValues = {
    eligiblePrincipal: bbData?.find(
      (data: any) => data.display_name === 'Eligible Pool 1'
    )?.display_value,
    advanceRate: bbData?.find(
      (data: any) => data.display_name === 'Advance Rate'
    )?.display_value,
    totalAvailability: bbData?.find(
      (data: any) => data.display_name === 'Discounted Borrowing Base'
    )?.display_value,
    outstandingPrincipal: bbData?.find(
      (data: any) => data.display_name === 'Lender 1 Principal Balance'
    )?.display_value,
    surplus: bbData?.find((data: any) => data.display_name === 'Surplus')
      ?.display_value,
    maximumLoanAmount: 0,
  }
  appendixValues.maximumLoanAmount =
    Number(appendixValues.surplus?.replaceAll(',', '')) > 0
      ? bbData?.find((data: any) => data.display_name === 'Surplus')
          ?.display_value
      : 0

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={{ ...styles.title, borderBottom: 'none' }}>
          BORROWING REQUEST NOTICE
        </Text>
        <Text style={styles.text}>
          Date: {moment(requestDate).format('MMMM DD, YYYY')}
        </Text>
        <Text style={styles.text}>
          TO:{' '}
          {agent
            ? `${agent}, a Delaware limited liability company, as Lender`
            : '[Agent]'}
        </Text>
        <Text style={styles.text}>FROM: {borrower || '[Borrower]'}</Text>
        <Text style={{ ...styles.text, textIndent: 32 }}>
          This Borrowing Request Notice (this{' '}
          <Text style={styles.textBold}>“Borrowing Request Notice”</Text>) is
          delivered to Lender pursuant to that certain Loan Agreement, dated as
          of {moment.utc(loanAgreementDate).format('MMMM DD, YYYY')} (as
          amended, restated, amended and restated, supplemented or otherwise
          modified from time to time, the{' '}
          <Text style={styles.textBold}>“Loan Agreement”</Text>
          ), made and entered into by and among Borrower, Zing America, Inc., a
          Delaware corporation (<Text style={styles.textBold}>“Parent”</Text>),
          and
          {agent}, a Delaware limited liability company (the{' '}
          <Text style={styles.textBold}>“Lender”</Text>). All capitalized terms
          used herein shall have the respective meanings specified in the Loan
          Agreement unless otherwise defined herein.
        </Text>
        <View style={{ marginLeft: 32, width: 445 }}>
          <Text style={styles.text}>
            This Borrowing Notice Request constitutes a request for a Loan as
            set forth below:
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
            <Text style={styles.text}>1.</Text>
            <Text style={styles.text}>
              The date of the proposed Loan is{' '}
              {moment.utc(advanceDate).format('MMMM DD, YYYY')} (the{' '}
              <Text style={styles.textBold}>“Loan Date”</Text>).
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
            <Text style={styles.text}>2.</Text>
            <Text style={styles.text}>
              The principal amount requested is{' '}
              {formData.amount ? formatCurrency(formData.amount) : '[Amount]'}{' '}
              (the <Text style={styles.textBold}>“Requested Advance”</Text>) via
              wire transfer directly to Borrower to the Controlled Account.
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
            <Text style={styles.text}>3.</Text>
            <Text style={styles.text}>
              As of the Loan Date, both before and after giving effect to the
              making of the Loan and the application of proceeds therefrom: (i)
              the representations and warranties of the Credit Parties contained
              in Article 4 of the Loan Agreement and in the other Loan Documents
              are true, correct and complete (except for representations and
              warranties that are made as of a specific date, which will be true
              and correct in all material respects as of that date) on and as of
              the Loan Date except as to such representations or warranties that
              expressly relate to an earlier date and (ii) and no Event of
              Default has occurred and is continuing, or would exist after
              giving effect to such Loan.
            </Text>
          </View>
        </View>
      </Page>
      <Page style={styles.body}>
        <Text style={{ ...styles.text, textIndent: 32 }}>
          IN WITNESS WHEREOF, the undersigned has executed this Borrowing Notice
          Request as of the date hereof.
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <View style={{ marginBottom: 12 }}>
            <Text style={{ ...styles.textBold, marginBottom: 0 }}>
              {borrower},
            </Text>
            <Text style={{ ...styles.text, marginTop: 0 }}>
              a Delaware limited liability company,
            </Text>
            <View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ ...styles.text, marginBottom: 0 }}>By: </Text>
                <View style={{ borderBottom: '1px solid black' }}>
                  <Text
                    style={{
                      ...styles.signTags,
                      marginBottom: 0,
                      width: 250,
                    }}
                  >
                    [sig|req|signer2|signature]
                  </Text>
                </View>
              </View>
              <Text style={{ ...styles.text, marginVertical: 0 }}>
                Name: {formData.signers?.[1]?.name}
              </Text>
              <Text style={{ ...styles.text, marginVertical: 0 }}>
                Title: {formData.signers?.[1]?.title}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: 100,
            right: 35,
            alignItems: 'flex-end',
          }}
        >
          <Text style={{ ...styles.text, marginBottom: 0 }}>Approval: </Text>
          <View style={{ borderBottom: '1px solid black' }}>
            <Text
              style={{
                ...styles.signTags,
                marginBottom: 0,
                width: 100,
              }}
            >
              [initial|req|signer1]
            </Text>
          </View>
        </View>
        <Footer />
      </Page>
      <Page style={styles.body}>
        <Text
          style={{
            ...styles.textBold,
            fontSize: 18,
            textDecoration: 'underline',
            margin: 2,
            marginBottom: 80,
          }}
        >
          APPENDIX A
        </Text>
        <View style={{ width: 300, display: 'flex', flexDirection: 'column' }}>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Draw Request Date
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFE699',
              }}
            >
              <Text style={{ ...styles.text, margin: 2 }}>
                {moment.utc(requestDate).format('M/D/YYYY')}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Current Eligible Principal Balance
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{ ...styles.text, margin: 2 }}
              >{`$${appendixValues.eligiblePrincipal}`}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Advance Rate
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ ...styles.text, margin: 2 }}>
                {appendixValues.advanceRate}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Total Availability
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{ ...styles.text, margin: 2 }}
              >{`$${appendixValues.totalAvailability}`}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Outstanding Principal (
                {moment.utc(formData.calculation_date).format('M/D/YYYY')})
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{ ...styles.text, margin: 2 }}
              >{`$${appendixValues.outstandingPrincipal
                ?.replace('(', '')
                .replace(')', '')}`}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Borrowing Base Surplus{' '}
                {Number(appendixValues.surplus?.replaceAll(',', '')) < 0 && (
                  <Text style={{ ...styles.textBold, color: 'red', margin: 0 }}>
                    (Deficiency)
                  </Text>
                )}
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{ ...styles.text, margin: 2 }}
              >{`$${appendixValues.surplus?.replace('-', '')}`}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Maximum Loan Amount Permitted
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{ ...styles.text, margin: 2 }}
              >{`$${appendixValues.maximumLoanAmount}`}</Text>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
            }}
          >
            <View style={{ width: '65%' }}>
              <Text style={{ ...styles.textBold, margin: 2 }}>
                Loan Amount Requested
              </Text>
            </View>
            <View
              style={{
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ ...styles.text, margin: 2 }}>
                {formatCurrency(formData.amount)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default CoignAdvanceRequestTemplate
