import React, { useContext, useEffect, useState } from 'react'
import colormap from 'colormap'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import CohortService from '@services/api-analytics/risk-cohort'
import FilterService from '@services/api-analytics/risk-filter'

const CollectionCurveL5 = ({
  exportable,
  type,
}: {
  exportable: boolean
  type: 'principal' | 'scheduled'
}) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { rates = {}, display_rates = [] } = optionFilters
  const isAggregate = activeType === 'All'

  const avgFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    filters: isAggregate ? [] : categoryTypes,
    slug_name: company?.slug_name ?? '',
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    slug_name: company?.slug_name ?? '',
    currency: currency,
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    filters: categoryTypes,
    is_principal: type == 'principal',
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortCollectionCurve', filters],
    () => CohortService.getCollectionCurve(filters),
    { ...getStaleMins(), enabled: avgTermIsFetched }
  )

  const cohort = (data?.data ?? []).reduce(
    (prev: { cohort: string; color: string; cutoff_age: number }[], cur) => {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      return prev.findIndex(
        (x: { cohort: string }) => x.cohort === cur.cohort
      ) < 0
        ? [...prev, { cohort: cur.cohort, color, cutoff_age: cur.cutoff_age }]
        : prev
    },
    []
  )

  const mob =
    (data?.data ?? []).length > 0
      ? Math.max(...(data?.data ?? []).map(x => x.cutoff_age))
      : 0

  let maxVal = 0
  const chartData = Array(mob)
    .fill('')
    .map((x, i) => {
      const currentMob = (data?.data ?? []).filter(x => x.mob === i + 1)
      const result: { x: number; [key: string]: number | undefined } = {
        x: i + 1,
      }
      cohort.forEach((y: { cohort: string; cutoff_age: number }) => {
        const currentVal = currentMob.find(x => x.cohort === y.cohort)
        const val =
          currentVal?.numer && currentVal?.denom
            ? (parseFloat(currentVal?.numer.toString()) /
                parseFloat(currentVal?.denom.toString())) *
              100
            : 0

        const valForMaxVal = val / 100
        if (valForMaxVal > maxVal && i + 1 < currentVal?.cutoff_age) {
          maxVal = valForMaxVal
        }

        result[moment(y.cohort).format('MMM-YY')] =
          i + 1 <= y.cutoff_age ? parseFloat(val.toString()) : undefined
      })
      return result
    })

  const heatmapColors = colormap({
    colormap: 'portland',
    nshades: 101,
    format: 'hex',
    alpha: 1,
  })

  /**
   * zoom
   */
  const xStart = 0
  const xSpan = Math.ceil(Number(avgTerm ?? 0) / 30) + 5
  const xEnd =
    chartData?.length === 0
      ? 0
      : xSpan >= chartData?.length
      ? chartData?.[chartData?.length - 1]?.x
      : chartData?.[xSpan - (xSpan > 0 ? 1 : 0)].x /
        chartData?.[chartData?.length - 1]?.x

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <L5ChartstatLayout
      chart={
        <>
          <Chart
            loading={isFetching || isProcessing || avgTermIsFetching}
            id={`riskCohortCollectionCurve__${type}`}
            yLabel={'Collection'}
            yFormat="#.00a%"
            xLabel="Month on Books (MoB)"
            xAxisType="CategoryAxis"
            data={chartData}
            series={cohort.map((c: { cohort: string; color: string }) => {
              const label = `${moment(c.cohort).format('MMM YY')}`
              return {
                label,
                tooltipValueFormat: '#.00a%',
                type: 'SmoothedXLineSeries',
                field: moment(c.cohort).format('MMM-YY'),
                color: c.color,
                hasBullet: false,
              }
            })}
            legendSetting={{
              show: true,
              position: 'right',
              config: { width: 124, paddingLeft: 24 },
            }}
            exportable={exportable}
            scroll={{ y: true, x: true, xStart, xEnd }}
            error={error as { message: string }}
          />
          <div
            className={`mt-4 overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)]`}
          >
            <table className="border-separate border-spacing-0">
              <thead className="sticky top-0 z-20">
                <tr>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[100px] sticky left-0 bg-neutral-white">
                    Cohort
                  </th>
                  {showHistoricalRateIndicator &&
                    display_rates.map((r: any) => (
                      <th
                        key={r}
                        className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[100px] bg-neutral-white"
                      >
                        USD:{r}
                      </th>
                    ))}
                  {Array(mob)
                    .fill('')
                    .map((_, i) => (
                      <th
                        key={i}
                        className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
                      >
                        {i + 1}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {cohort
                  .sort((a, b) => {
                    return moment(a.cohort).diff(moment(b.cohort))
                  })
                  .map((c: { cohort: string; color: string }) => {
                    const rowData = (data?.data ?? []).filter(
                      x => x.cohort === c.cohort
                    )

                    return (
                      <tr key={c.cohort}>
                        <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 bg-neutral-white text-sm text-center bg-neutral-white z-10">
                          {`${moment(c.cohort).format('MMM YY')}`}
                        </td>
                        {showHistoricalRateIndicator &&
                          display_rates.map((r: any) => (
                            <td
                              key={r}
                              className="p-3 border-r border-b border-neutral-border-1 bg-neutral-white text-sm text-right bg-neutral-white z-10"
                            >
                              {Intl.NumberFormat(undefined, {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                              }).format(rates[c.cohort]?.[r] ?? 1)}
                            </td>
                          ))}

                        {Array(mob)
                          .fill('')
                          .map((_, i) => {
                            const dt = rowData.find(x => x.mob === i + 1)
                            if (i + 1 > (rowData[0]?.cutoff_age ?? 0)) {
                              return (
                                <td key={i} className="bg-neutral-border-1" />
                              )
                            }
                            const cellVal =
                              dt?.numer && dt.denom
                                ? parseFloat(dt?.numer.toString()) /
                                  parseFloat(dt?.denom.toString())
                                : 0
                            const valRange =
                              maxVal > 0
                                ? Math.ceil((cellVal / maxVal) * 100)
                                : 0
                            const reversedVal = 100 - valRange
                            const backgroundColor = heatmapColors[reversedVal]
                            return (
                              <td
                                key={i}
                                className={`p-3 border-r border-b border-neutral-border-1 text-sm text-right relative text-white`}
                                style={{ backgroundColor }}
                              >
                                {Intl.NumberFormat(undefined, {
                                  style: 'percent',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(cellVal)}
                              </td>
                            )
                          })}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </>
      }
    />
  )
}

export default CollectionCurveL5
