import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { BorrowingBaseResponse } from '@interfaces/manage-monitor-borrowing-base'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

import DetailTable from './detail-table'
import HistoricalSurplusChart from './historical-surplus'

const is_status = (arr: string[], val: string) => {
  return arr.find(br => val.toLowerCase().includes(br))
}
const BorrowingBaseL4 = () => {
  const [expandedRow, setExpandedRow] = useState<string[]>([])

  const handleOpen = (row: string): void => {
    const rows = expandedRow.includes(row)
      ? expandedRow.filter(x => x !== row)
      : [...expandedRow, row]
    setExpandedRow(rows)
  }

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId, calculationDate } = appliedFilters

  const filters = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    calculation_date: calculationDate,
  }

  const { data, isFetching } = useQuery(
    ['borrowing-base', filters],
    () => BorrowingBaseService.get(filters),
    { ...getStaleMins(), enabled: !!calculationDate }
  )

  const bold_rows = [
    'borrowing base',
    'total lender',
    'surplus',
    'deficiency',
    'collateralization',
  ]

  const border_rows = ['surplus', 'deficiency']

  const table_data = data
  const table_column: any[] = [
    {
      title: optionFilters.facilities.find(
        (obj: any) => obj.facility_id == appliedFilters.activeFacilityId
      ).agreement_currency, // used to be hardcoded to USD
      field: 'display_name',
      head: {
        colSpan: 3,
      },
      render: (r: BorrowingBaseResponse) => {
        const is_bold = is_status(bold_rows, r.display_name)
        return (
          <Typography className={`${is_bold ? 'font-semibold' : ''} text-sm`}>
            {r.display_name}
          </Typography>
        )
      },
    },
    {
      title: '',
      field: 'display_value',
      align: 'right',
      width: 150,
      head: {
        colSpan: 0,
      },
      render: (r: BorrowingBaseResponse) => {
        const is_bold = is_status(bold_rows, r.display_name)
        return is_bold ? null : r.display_value ?? '0.00'
      },
    },
    {
      title: '',
      field: 'display_value',
      align: 'right',
      width: 150,
      head: {
        colSpan: 0,
      },
      render: (r: BorrowingBaseResponse) => {
        const is_bold = is_status(bold_rows, r.display_name)
        return is_bold ? (
          <Typography className={`${is_bold ? 'font-semibold' : ''} text-sm`}>
            {r.display_value ?? '0.00'}
          </Typography>
        ) : null
      },
    },
  ]

  const _handleOpenComponent = (r: BorrowingBaseResponse) => {
    setExpandedRow([
      ...expandedRow.filter(x => x !== r.display_name),
      r.display_name,
    ])
    setTimeout(() => {
      document.getElementsByClassName(r.display_name)?.[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      })
    }, 100)
  }

  return (
    <div className="flex flex-col p-4">
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col [&>div]:flex-none" id="summary-table">
          <Table
            loading={isFetching || !calculationDate}
            columns={table_column}
            data={table_data?.filter(
              td =>
                !td.display_name.toLowerCase().includes('days deficient') &&
                !!td.display_value
            )}
            rowClass={(r: BorrowingBaseResponse) => {
              const is_border = is_status(border_rows, r.display_name)
              const is_positive = !r.display_value?.includes('(')
              return `${
                is_border
                  ? `border-t-2 border-neutral-black ${
                      is_positive
                        ? '[&>td]:!bg-success-surface'
                        : '[&>td]:!bg-danger-surface'
                    }`
                  : ''
              } `
            }}
            onRowClick={_handleOpenComponent}
          />
          <Table
            loading={isFetching || !calculationDate}
            containerClass="[&>table>thead]:hidden"
            columns={table_column.slice(0, 2)}
            data={table_data?.filter(td =>
              td.display_name.toLowerCase().includes('days deficient')
            )}
          />
        </div>
        <div>
          <div className="border border-neutral-border-2 rounded-lg p-4 justify-center">
            <HistoricalSurplusChart expanded />
          </div>
        </div>
      </div>
      {table_data
        ?.filter(item => item.detail_data?.length > 0)
        .map((item, i) => {
          const is_open = expandedRow.includes(item.display_name)
          const Icon = is_open ? ChevronDownIcon : ChevronRightIcon
          return (
            <Accordion
              key={i}
              open={is_open}
              icon={<span></span>}
              className={`border-t border-neutral-border ${item.display_name}`}
            >
              <AccordionHeader
                onClick={() => handleOpen(item.display_name)}
                className="text-lg border-0 justify-start"
              >
                <Icon className="w-6 h-6 mr-2" />
                <span className="text-left capitalize">
                  {item.display_name}
                </span>
              </AccordionHeader>
              <AccordionBody>
                <DetailTable data={item.detail_data ?? []} />
              </AccordionBody>
            </Accordion>
          )
        })}
    </div>
  )
}
export default BorrowingBaseL4
