import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import moment, { Moment } from 'moment'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import FilesUploadDropZone from '@components/file-upload-modal/drop-zone'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import { CategoriesResult } from '@interfaces/manage-reporting'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import DocumentService from '@services/api-manage/docs'

import { MAX_FILE_SIZE, MAX_FILE_SIZE_TEXT } from '../constants'

interface SignatureHistoryDialogProps {
  open: boolean
  handler: () => void
  uploadCategories: CategoriesResult[]
  category: CategoriesResult
  docData?: any
}

const UploadDocumentDialog = ({
  open,
  handler,
  uploadCategories,
  category,
  docData,
}: SignatureHistoryDialogProps) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { facilities } = optionFilters
  const { activeFacilityId } = appliedFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )
  const { user } = useAuth0()
  const [fileAdded, setFileAdded] = useState<boolean>(false)
  const { displayToastError } = useToastNotification()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    shouldUnregister: false,
  })

  useEffect(() => {
    !!category.category &&
      setValue('category', category.category, {
        shouldValidate: false,
      })
  }, [category])

  const onSubmit = (dt: any) => {
    const formData = new FormData()
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append(
      'effective_date',
      moment(dt.effective_date ?? docData.effective_date).format('DD-MM-YYYY')
    )
    formData.append('category', dt.category ?? docData.category)
    formData.append('title', dt.title ?? docData.name)
    formData.append('facility', activeFacility?.facility_name)
    user?.name && formData.append('author_email', `${user.email}`)
    user?.name && formData.append('author_name', `${user.name}`)
    docData.id && formData.append('id', docData.id)
    formData.append('files', dt.file)

    uploadDocument(formData)
  }

  const handleSuccess = () => {
    toast.dismiss()
    toast.success('Upload Successful', { autoClose: 5000 })
    handler()
    reset()
  }

  const { mutate: uploadDocument, isLoading: isUploading } = useMutation(
    (formData: any) => {
      return DocumentService.uploadDocument(formData)
    },
    {
      onSuccess: handleSuccess,
      onError: (err: AxiosError) => {
        displayToastError(err.response?.status, undefined, false)
      },
    }
  )

  const datePickerStart = activeFacility?.agreement_date
    ? moment(activeFacility?.agreement_date)
    : moment(company?.date_start)
  const datePickerEnd = moment().add(1, 'month')

  const isLoading = isSubmitting || isUploading
  const isSubmitDisabled = !!docData ? !fileAdded : !isValid || !fileAdded

  return (
    <Dialog open={open} size={'sm'} handler={() => undefined}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader className="flex justify-between">
          Upload Document for {activeFacility?.facility_name}
          <XMarkIcon
            className="w-7 cursor-pointer"
            onClick={() => {
              reset()
              setFileAdded(false)
              handler()
            }}
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col overflow-auto p-5">
          <div>
            <FormInput
              type="text"
              label={{ start: 'Title' }}
              value={docData ? docData.name : getValues('title')}
              {...register('title', {
                required: `Title is required`,
                disabled: isSubmitting || !!docData,
                onChange: (e: any) => {
                  setValue('title', e.target.value, {
                    shouldValidate: !docData,
                  })
                },
              })}
              error={errors?.title?.message as string}
            />
            <FormInput
              type="select"
              label={{ start: 'Category' }}
              value={
                docData
                  ? docData.category
                  : category.category ?? getValues('category')
              }
              {...register('category', {
                required: `Category is required`,
                disabled: isSubmitting || !!docData,
              })}
              onSelected={val => {
                setValue('category', val, {
                  shouldValidate: !docData,
                })
              }}
              options={uploadCategories.map(a => ({
                value: a.category,
                title: a.category,
              }))}
              isDisabled={!!category.category}
              error={errors?.category?.message as string}
            />
            <FormInput
              type="date"
              label={{ start: 'Event Date' }}
              value={
                docData
                  ? moment(docData.effective_date)
                  : getValues(`effective_date`)
              }
              {...register('effective_date', {
                required: `Effective/Due Date is required`,
                disabled: isSubmitting || !!docData,
              })}
              onSelected={val => {
                setValue(`effective_date`, val, {
                  shouldValidate: true,
                })
              }}
              disabledDate={(current: Moment) => {
                return (
                  current.isBefore(moment.utc(datePickerStart)) ||
                  current.isAfter(moment.utc(datePickerEnd))
                )
              }}
              error={errors?.effective_date?.message as string}
            />
          </div>

          <div className="mx-2">
            <FilesUploadDropZone
              id={'actionsFileUpload'}
              fileTypeText={'.pdf,.xlsx, .xls, .doc, .docx extension'}
              isLoading={false}
              maxSize={MAX_FILE_SIZE}
              titleMaxFilesSize={MAX_FILE_SIZE_TEXT}
              fileTypes={{
                'application/pdf': ['.pdf'],
                'application/xlsx': ['.xlsx'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  ['docx'],
                'application/msword': ['doc'],
              }}
              handleCancel={() => setFileAdded(false)}
              uploader={(file: Array<any>) => {
                setFileAdded(!!file?.[0])
                setValue('file', file?.[0])
              }}
            />
          </div>
        </DialogBody>
        <DialogFooter className="flex justify-center flex-col">
          <span className="text-sm font-medium mb-4 text-warning-main text-center">
            This will not trigger any associated workflows or signature requests
          </span>

          <Button
            disabled={isLoading || isSubmitDisabled}
            type="submit"
            color="primary"
            className="w-full"
          >
            {isLoading ? (
              <ArrowPathIcon className="w-4 h-4 mx-20 text-primary-main animate-spin" />
            ) : (
              `Upload Document`
            )}
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  )
}

export default UploadDocumentDialog
