import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import Typography from '@components/atoms/typography'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFacility from '@components/filters/filter-facility'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { CovenantType } from '@interfaces/manage-monitor-covenant'

import CovenantDetail from './detail'
import CovenantExport from './export'
import { useCovenantList } from './helper'
const MonitorCovenantL3 = ({ type }: { type: CovenantType }) => {
  const {
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
    company,
  } = useContext(AuthContext)

  const { activeFacilityId } = activeFilters
  const { activeFacilityId: appliedActiveFacilityId } = appliedFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  const [covenantType, setCovenantType] = useState<string>()

  /**
   * Since MonitorCovenantL3 is shared by different covenants,
   * the active filter is not reset when changing the covenant type,
   * which creates an unpleasant user experience.
   */
  if (covenantType !== type && appliedActiveFacilityId !== undefined) {
    if (appliedActiveFacilityId !== activeFacilityId) {
      setActiveFilters(appliedFilters)
    }
    setCovenantType(type)
  }

  useEffect(() => {
    if (!filterReady && activeFacilityId !== undefined) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = appliedActiveFacilityId === 1

  const { isLoading, data } = useCovenantList({ type })

  const { get_access } = useUserAccessFeature()
  const FEATURE = `${appliedActiveFacilityId}_manage_monitor_${type}_covenant`
  const can_export = get_access(FEATURE, 'export')

  const tabs = (data ?? [])
    //removes duplicates
    .filter(
      (v, i, s) => s.findIndex(item => item.covenant_id === v.covenant_id) === i
    )
    .map(d => {
      return {
        label: d.covenant_name,
        component: <CovenantDetail list_item={d} type={type} />,
        headerClassName: 'text-sm',
        id: d.covenant_id.toString(),
      }
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1))

  return (
    <L3TabsLayout
      title={`${type} Covenants`}
      filters={{
        left: (
          <FilterCurrency
            hideSelect
            dateAsOfType={filterDateAsOfType.financials}
          />
        ),
        middle: <FilterFacility />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      empty={
        isLoading ? (
          <div className="flex justify-center gap-2">
            <ArrowPathIcon className="animate-spin text-primary-main w-4" />
            <Typography className="text-center">Loading</Typography>
          </div>
        ) : (
          <Typography className="text-center">
            No {type} covenant found
          </Typography>
        )
      }
      rightFooterContent={can_export ? <CovenantExport type={type} /> : <></>}
      switchable
    />
  )
}
export default MonitorCovenantL3
