import { noop } from 'lodash'

import { CategoriesResult } from '@interfaces/manage-reporting'

import { useWriteCoignExcelFileHook } from './coign'
import {
  useCreateNegativeAdvanceRequest,
  useWriteCollateralFileHook,
} from './general'

export const useAfterEffects = (category: CategoriesResult) => {
  switch (category.subcategory) {
    case 'orange-bank-compliance-monthly':
    case 'orange-bank-compliance-quarterly':
      return useWriteCollateralFileHook()
    case 'orange-bank-payment-demand':
      return useCreateNegativeAdvanceRequest()
    case 'coign-compliance-cert':
      return useWriteCoignExcelFileHook()

    default:
      return { afterEffect: noop }
  }
}
