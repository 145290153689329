import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import Chart from '@components/chart'
import ExchangeRateBadge from '@components/exchange-rate-badge'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiOptionButtonGroup from '@components/selectors/multi-option-button'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { useGetRiskData } from '@helpers/risk-charts-data-hook'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { DataResponse, RiskData } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { Switch, Tooltip } from '@material-tailwind/react'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const RdrL5 = ({ exportable }: { exportable: boolean }) => {
  const [value, setValue] = useState<number>(7)

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { categoryTypes = [], activeType, currency = 'USD' } = appliedFilters
  const {
    rates = {},
    display_rates = [],
    categoryTypeOptions = [],
  } = optionFilters
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []
  const { showTable, setShowTable } = useContext(ApplicationCustomContext)

  const {
    fetchedDataAggregated,
    data,
    growthRatios,
    cohort,
    error,
    isFetching,
    isAggregate,
  } = useGetRiskData(DelinquencyService.getRdr, 'rdr', false, false, {
    is_adjusted: false,
    value: `rdr${value}`,
  })

  let maxVal = 0

  const chartData = cohort.map((c: RiskData<DataResponse>) => {
    const result = historicalExchgList(rates, c, c.cohort)
    const cohortData = (data || []).filter(
      (d: RiskData<DataResponse>) => d.cohort === c.cohort
    )
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      result.type_all_val_2 = parseFloat(
        (cohortData?.[0]?.val_2 ?? 0).toString()
      )
      result.type_all_denom = parseFloat(
        (cohortData?.[0]?.denom ?? 0).toString()
      )
      result.type_all_numer = parseFloat(
        (cohortData?.[0]?.numer ?? 0).toString()
      )
      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find((cd: RiskData<DataResponse>) => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        result[`type_${ct}_val_2`] = parseFloat(
          (cohortCategory?.val_2 ?? 0).toString()
        )
        result[`type_${ct}_denom`] = parseFloat(
          (cohortCategory?.denom ?? 0).toString()
        )
        result[`type_${ct}_numer`] = parseFloat(
          (cohortCategory?.numer ?? 0).toString()
        )
        if (result[`type_${ct}`] > maxVal) {
          maxVal = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const rdrSeries: any[] = isAggregate
    ? [
        {
          label: `${value} DPD`,
          tooltipValueFormat: '#.00a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.00a%',
          field: `type_${ct}`,
          color,
          type: 'SmoothedXLineSeries',
          hasBullet: true,
        }
      })

  const header_data = [
    rdrSeries.reduce((p: any, s: any) => {
      return {
        ...p,
        [`${s.field}_denom`]: 'Disbursed Principal',
        [`${s.field}_numer`]: `Unpaid Principal (> ${value} DPD)`,
        [`${s.field}_val_2`]: `Default Rate (6 MoB, ${value} DPD)`,
        [s.field]: 'Rolling Default Rate (12m Avg)',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...rdrSeries.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_numer`,
          title: header_data[0][`${s.field}_numer`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_numer`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_denom`,
          title: header_data[0][`${s.field}_denom`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_val_2`,
          title: header_data[0][`${s.field}_val_2`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_val_2`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `Rolling Default Rate - ${value}DPD ${
            isAggregate ? '' : `(${s.label})`
          }`,
          head: { colSpan: 4, align: 'center' },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]

  return (
    <div className="[&>div>div:nth-child(2)]:mt-10">
      <L5ChartstatLayout
        chart={
          <>
            <div className="flex mb-8 justify-between">
              <div className="flex justify-center items-center">
                <Tooltip
                  content={
                    <span>
                      Description:
                      <br />
                      {`Average default rate using default rates from the last 12 months`}
                      <br />
                      <br />
                      Formula:
                      <br />
                      Default rate at each month is calculated as outstanding
                      principal of loans over the DPD threshold for all loans
                      with at least 6 months on books, divided by disbursed
                      principal. Average is a simple average over the past 12
                      months of default rates.
                      <br />
                      <br />
                      Write Offs = {company?.writeoff_days} days
                      <br />
                    </span>
                  }
                  placement="right"
                >
                  <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
                </Tooltip>
                {showHistoricalRateIndicator && <ExchangeRateBadge />}
              </div>

              <MultiOptionButtonGroup
                buttonSelections={[
                  {
                    options: [7, 30, 60, 90].map(dpd => {
                      return {
                        title: `${dpd} DPD`,
                        active: value === dpd,
                        action: () => setValue(dpd),
                      }
                    }),
                  },
                ]}
              />
            </div>
            <Chart
              loading={isFetching || isProcessing}
              id={`rdr${value}Par_by_${activeType}`}
              yLabel="Percentage"
              yFormat="#.00a%"
              ySetting={
                maxVal < 5 ? { max: 5, maxPrecision: 1 } : { maxPrecision: 1 }
              }
              data={chartData}
              series={rdrSeries as any}
              tooltipSubtitle={
                showHistoricalRateIndicator
                  ? displayRates(display_rates)
                  : undefined
              }
              exportable={exportable}
              exportableColumn={table_columns}
              error={error as { message: string }}
            />
            <div className="flex justify-end mt-8">
              <Switch
                label="Show Data"
                checked={showTable}
                onChange={() => {
                  setShowTable(!showTable)
                }}
                defaultChecked
                color="light-blue"
                crossOrigin={undefined}
              />
            </div>
            {showTable && (
              <Table
                containerClass="mt-8 [&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
                loading={isFetching || isProcessing}
                data={chartData}
                headerData={header_data}
                columns={table_columns}
              />
            )}
          </>
        }
        stat={
          <StatisticL5
            description={`percentage at ${value}DPD`}
            classSetting={{
              up: 'text-danger-hover',
              down: 'text-success-hover',
            }}
            {...growthRatios}
          />
        }
      />
    </div>
  )
}

export default RdrL5
