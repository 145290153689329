import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthContext from '@contexts/auth'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { TemplateDialogProps } from '@interfaces/manage-signature'
import SigningTemplateAction from '@pages/manage/monitor/actions/document-centre/reporting/signing-template-action'
import {
  BorrowingBaseCertAction,
  ComplianceCertAction,
  FinancialCovenantCertAction,
  GenericTemplateAction,
  PortfolioCovenantCertAction,
} from '@pages/manage/monitor/actions/document-centre/templates'

interface GenerateDocProviderProps {
  docData: any
  category: CategoriesResult
  handler: () => void
  refetchHandler: () => void
  canSign: boolean
}

interface Redirects {
  'Advance Request': string
  'Capital Advance': string
  'Waterfall Report': string
  'Profit Share': string
}

export const redirectCategories = [
  'Advance Request',
  'Capital Advance',
  'Waterfall Report',
]

const GenerateDocProvider = ({
  docData,
  category,
  handler,
  refetchHandler,
  canSign,
}: GenerateDocProviderProps) => {
  const navigate = useNavigate()
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(true)
  const { company, setLoadingData } = useContext(AuthContext)

  const redirects: Redirects = {
    'Advance Request': `/manage/monitor/actions?tab=advance-request&dCAction=create&dcId=${docData.id}&pid=${company?.slug_name}`,
    'Capital Advance': `/manage/monitor/actions?tab=advance-request&dCAction=create&dcId=${docData.id}&pid=${company?.slug_name}`,
    'Waterfall Report': `/manage/monitor/actions?tab=waterfall&pid=${company?.slug_name}`,
    'Profit Share': `/manage/monitor/actions?tab=waterfall&waterfall=profit-share&pid=${company?.slug_name}`,
  }

  const handleDialogOpen = () => {
    handler()
    setDialogIsOpen(!dialogIsOpen)
  }

  const redirectUser = (path: string) => {
    setLoadingData([
      {
        state: 'loading',
        text: `Redirecting...`,
      },
    ])

    setTimeout(() => {
      setLoadingData({})
      navigate(path)
    }, 1000)
  }

  useEffect(() => {
    if (redirects[category.category as keyof Redirects]) {
      redirectUser(redirects[category.category as keyof Redirects])
    }
  }, [category, navigate])

  switch (category.category) {
    case 'Compliance Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          dialogOpen={dialogIsOpen}
          handler={() => {
            handleDialogOpen()
            refetchHandler()
          }}
          renderDialog={(props: TemplateDialogProps) => (
            <ComplianceCertAction {...props} />
          )}
          category={category}
          canSign={canSign}
        />
      )

    case 'Portfolio Covenant Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          dialogOpen={dialogIsOpen}
          handler={() => {
            handleDialogOpen()
            refetchHandler()
          }}
          renderDialog={(props: TemplateDialogProps) => (
            <PortfolioCovenantCertAction {...props} />
          )}
          category={category}
          canSign={canSign}
        />
      )

    case 'Financial Covenant Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          handler={() => {
            handler()
            refetchHandler()
          }}
          dialogOpen={dialogIsOpen}
          renderDialog={(props: TemplateDialogProps) => (
            <FinancialCovenantCertAction {...props} />
          )}
          category={category}
          canSign={canSign}
        />
      )

    case 'Borrowing Base Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          handler={() => {
            handler()
            refetchHandler()
          }}
          dialogOpen={dialogIsOpen}
          renderDialog={(props: TemplateDialogProps) => (
            <BorrowingBaseCertAction {...props} />
          )}
          category={category}
          canSign={canSign}
        />
      )

    default:
      return !redirectCategories.includes(category.category) ? (
        <SigningTemplateAction
          docData={docData}
          handler={() => {
            handler()
            refetchHandler()
          }}
          dialogOpen={true}
          renderDialog={(props: TemplateDialogProps) => (
            <GenericTemplateAction {...props} />
          )}
          category={category}
          canSign={canSign}
        />
      ) : (
        <></>
      )
  }
}

export default GenerateDocProvider
