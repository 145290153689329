import React from 'react'
import moment from 'moment'

import Typography from '@components/atoms/typography'
import Chart from '@components/chart'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import MonitorDashboardTableItem from './table'

const MonitorDashboardMultilineGraphItem = ({
  item,
}: {
  item: DashboardResponse
}) => {
  const unit = item.data?.[0]?.unit
  const chart_series = (item.data ?? []).reduce((p, c) => {
    const existingIndex = p.findIndex(
      (x: any) => x.label === c[item.series_field ?? '']
    )
    if (existingIndex >= 0) {
      return p
    } else {
      return [
        ...p,
        {
          label: c[item.series_field ?? ''],
          tooltipValueFormat: `${item.series_format ?? '#.00a'} ${unit ?? ''}`,
          type: 'SmoothedXLineSeries',
          field: c[item.series_field ?? ''],
          color: '#' + Math.floor(Math.random() * 16777215).toString(16),
        },
      ]
    }
  }, [])
  const chart_data = (item.data ?? []).reduce((p, c) => {
    const x =
      item.x_axis_type === 'CategoryAxis'
        ? c[item.x_axis_field]
        : moment.utc(c[item.x_axis_field]).valueOf()

    const existingIndex = p.findIndex((_x: any) => _x.x === x)
    if (existingIndex >= 0) {
      p[existingIndex][c[item.series_field ?? '']] = c[item.y_axis_field]
      return p
    } else {
      return [
        ...p,
        { ...c, x, [c[item.series_field ?? '']]: c[item.y_axis_field] },
      ]
    }
  }, [])
  const xSetting = item.x_axis_setting
    ? JSON.parse(item.x_axis_setting)
    : undefined

  return (
    <>
      {item.title && (
        <Typography className="text-center text-lg font-semibold mt-8">
          {item.title}
        </Typography>
      )}
      <Chart
        data={chart_data}
        series={chart_series}
        loading={false}
        yLabel={`${item.y_axis_label} ${unit ? `(${unit})` : ''}`}
        yFormat={item.y_axis_format ?? '#.00a'}
        xSetting={xSetting}
        id={`${item.title}-${item.dashboard_id}` ?? ''}
        exportable={false}
      />
      <MonitorDashboardTableItem item={item} />
    </>
  )
}
export default MonitorDashboardMultilineGraphItem
