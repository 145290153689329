import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Entity } from '@interfaces/accounting/financials'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { Option, Select } from '@material-tailwind/react'
import { EntityService } from '@services/api-admin/settings-entities'

const FilterEntity = () => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const entityParam = searchParams.get('entity')
  const { activeEntityId: appliedActiveEntityId } = appliedFilters
  const { activeEntityId } = activeFilters
  const { entities = [] } = optionFilters

  const {
    data: cleanedEntities,
    isFetching,
    error,
  } = useQuery(
    ['entities', company?.slug_name],
    () =>
      EntityService.getFilterEntities({
        slugName: company?.slug_name ?? '',
      }),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name,
    }
  )

  useEffect(() => {
    const entityParamId = cleanedEntities?.find(
      (f: Entity) => f.entityNickname === entityParam
    )?.entityId

    if (
      !appliedActiveEntityId &&
      (entityParamId || cleanedEntities?.[0]?.entityId)
    ) {
      setAppliedFilters((prev: LayoutFilter) => ({
        ...prev,
        activeEntityId: entityParamId ?? cleanedEntities?.[0]?.entityId,
      }))
    }
    setActiveFilters((prev: LayoutFilter) => ({
      ...prev,
      activeEntityId:
        entityParamId ?? appliedActiveEntityId ?? cleanedEntities?.[0]?.ID,
    }))
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      entities: cleanedEntities,
    }))
    if (!appliedFilters.activeEntityId && entityParamId) {
      setAppliedFilters((prev: LayoutFilter) => ({
        ...prev,
        activeEntityId:
          entityParamId ?? appliedActiveEntityId ?? cleanedEntities?.[0]?.ID,
      }))
      if (searchParams.has('entity')) {
        searchParams.delete('entity')
        setSearchParams(searchParams.toString())
      }
    }
  }, [cleanedEntities])

  useEffect(() => {
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      entitiesIsFetching: isFetching,
      entitiesError: error,
    }))
  }, [isFetching, error])

  const activeEntityObject = entities?.find(
    (f: Entity) => f.ID === activeEntityId
  )

  return (
    <div className="flex flex-col gap-1 min-w-[150px]">
      <Typography className="text-neutral-body-2 text-sm">Entities</Typography>

      <Select
        className="[&~ul]:max-h-[100px] bg-neutral-white text-neutral-black"
        disabled={entities.length === 0}
        onChange={val => {
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            activeEntityId: val,
          }))
        }}
        selected={() => {
          return (
            <span>
              {isFetching ? 'loading' : activeEntityObject?.entityNickname}
            </span>
          )
        }}
      >
        {entities.map((c: Entity, i: number) => (
          <Option key={i} value={c.ID}>
            {c.entityNickname}
          </Option>
        ))}
      </Select>
    </div>
  )
}
export default FilterEntity
