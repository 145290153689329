import { useContext } from 'react'
import moment from 'moment'
import { useMutation } from 'react-query'

import AuthContext from '@contexts/auth'
import { Facility } from '@interfaces/facility'
import { UploadCoignExcelFilePayload } from '@interfaces/manage-monitor-action'
import { ActionService } from '@services/api-manage/monitor-action'

export const useWriteCoignExcelFileHook = () => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  const { mutate: uploadFile } = useMutation(
    (params: UploadCoignExcelFilePayload) => {
      return ActionService.uploadCoignExcelFile(params)
    }
  )

  const afterEffect = (
    effectiveDate: string,
    customData: Record<string, any>,
    inputs: Record<string, string | number>
  ) => {
    if (customData.fileUrl) {
      uploadFile({
        facilityId: activeFacility?.facility_id,
        slugName: activeFacility?.slug_name ?? company?.slug_name,
        effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
        fileName: customData.fileUrl,
        inputs,
      })
    }
  }

  return { afterEffect }
}
