/**
 * List of availables features
 * Structured in L1_L2_L3
 *
 * User features should be set in auth0 console > User Management > Users > user_meta data
 * sample:
 * "features": [
 *      "analytics_risk_traction"
 * ]
 * Otherwise fallback into this
 */
export default [
  'analytics_risk_overview',
  'analytics_risk_indicators',
  'analytics_risk_traction',
  'analytics_risk_delinquency',
  'analytics_risk_collection',
  'analytics_risk_cohort',
  'analytics_risk_characteristics',
  'analytics_data-exports_loan-tape',
  // 'analytics_financials_overview',
  'analytics_financials_key-indicators',
  'analytics_financials_statements',

  'manage_monitor_overview',
  // 'manage_monitor_facility-details',
  'manage_monitor_dashboard',
  'manage_monitor_borrowing-base',
  'manage_monitor_portfolio-covenants',
  'manage_monitor_financial-covenants',
  'manage_monitor_cashflows',
  'manage_monitor_actions',
  'manage_audit_payment-audit',
  'manage_audit_underwriting-audit',

  // 'data-validation_data-quarantine_collateral-data',

  'administration_sources_banking',
  'administration_sources_accounting',
  'administration_sources_uploads',
  'administration_setting_users',
  'administration_setting_accounting-config',
]

/*
 * Update as Feature flag requirements grow.
 * flag should be equal to the env variable set for API
 * control to be false by default
 */
export const initialFeatureFlagMapping = [
  {
    path: 'administration_setting_accounting-config',
    flag: 'FEATURE_ADMIN_ACCOUNTING_ENABLED',
    control: false,
  },
  {
    path: 'administration_sources_accounting',
    flag: 'FEATURE_ADMIN_ACCOUNTING_ENABLED',
    control: false,
  },
  {
    path: 'administration_sources_uploads',
    flag: 'FEATURE_ADMIN_ACCOUNTING_ENABLED',
    control: false,
  },
  {
    path: 'analytics_financials_key-indicators',
    flag: 'FEATURE_ANALYTICS_FINANCIALS_ENABLED',
    control: false,
  },
  {
    path: 'analytics_financials_statements',
    flag: 'FEATURE_ANALYTICS_FINANCIALS_ENABLED',
    control: false,
  },
]
