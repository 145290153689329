import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import useHotjar from 'react-use-hotjar'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import { setFilterDocCentre } from '@components/filters/filter-doc-centre'
import AuthContext from '@contexts/auth'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { HomeIcon } from '@heroicons/react/24/solid'
import {
  Filter,
  FilterItem,
  LayoutFilter,
} from '@interfaces/analytics-risk-filter-type-key'
import { Facility } from '@interfaces/facility'
import { Breadcrumbs } from '@material-tailwind/react'
import AnalyticsWarmerService from '@services/api-analytics/cache-warmer'
import ManageWarmerService from '@services/api-manage/cache-warmer'

export interface BaseLayoutProps {
  /**
   * filters section. empty by default
   */
  filters?: {
    left?: any
    middle?: any
    right?: any
    className?: string
  }
  /**
   * page title
   */
  title: string

  /**
   * whether reset button disabled or not. Depend on which page observing context.
   */
  disableReset?: boolean

  /**
   * right footer content in jsx
   * currently used for export button
   */
  rightFooterContent?: any
}

const BaseLayout: FC<BaseLayoutProps> = ({
  children,
  filters,
  disableReset = true,
  rightFooterContent,
}) => {
  const {
    showFilter,
    setShowFilter,
    company,
    optionFilters,
    setOptionFilters,
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)
  const location = useLocation()
  const { stateChange } = useHotjar()

  const exportDivRef = useRef<HTMLDivElement>(null)

  const applyEnabled =
    activeFilters?.activeFacilityId !== appliedFilters?.activeFacilityId ||
    activeFilters?.activeType !== appliedFilters?.activeType ||
    activeFilters?.calculationDate !== appliedFilters?.calculationDate ||
    activeFilters?.initialDate !== appliedFilters?.initialDate ||
    activeFilters?.categoryTypes !== appliedFilters?.categoryTypes ||
    activeFilters?.currency !== appliedFilters?.currency ||
    activeFilters?.dateEnd !== appliedFilters?.dateEnd ||
    activeFilters?.dateEndCohort !== appliedFilters?.dateEndCohort ||
    activeFilters?.dateStart !== appliedFilters?.dateStart ||
    activeFilters?.dateStartCohort !== appliedFilters?.dateStartCohort ||
    activeFilters?.docCategory?.category !==
      appliedFilters?.docCategory?.category ||
    (Array.isArray(activeFilters?.docCategory?.subcategory) &&
    Array.isArray(appliedFilters?.docCategory?.subcategory)
      ? JSON.stringify(activeFilters?.docCategory?.subcategory?.sort()) !==
        JSON.stringify(appliedFilters?.docCategory?.subcategory?.sort())
      : activeFilters?.docCategory?.subcategory !==
        appliedFilters?.docCategory?.subcategory) ||
    activeFilters?.docKeyword !== appliedFilters?.docKeyword ||
    activeFilters?.docStatus?.display !== appliedFilters?.docStatus?.display ||
    (Array.isArray(activeFilters?.docStatus?.status) &&
    Array.isArray(appliedFilters?.docStatus?.status)
      ? JSON.stringify(activeFilters?.docStatus?.status?.sort()) !==
        JSON.stringify(appliedFilters?.docStatus?.status?.sort())
      : activeFilters?.docStatus?.status !==
        appliedFilters?.docStatus?.status) ||
    activeFilters?.financialEntity !== appliedFilters?.financialEntity ||
    activeFilters?.financialEntityCurrency !==
      appliedFilters?.financialEntityCurrency ||
    activeFilters?.range !== appliedFilters?.range ||
    activeFilters?.entityStartDate !== appliedFilters?.entityStartDate ||
    activeFilters?.entityEndDate !== appliedFilters?.entityEndDate ||
    activeFilters?.activeEntityId !== appliedFilters?.activeEntityId ||
    activeFilters?.financialUploadStatus !==
      appliedFilters?.financialUploadStatus

  const disableApply = Object.keys(appliedFilters).length == 0 || !applyEnabled

  const resetFilter = () => {
    const initialFilters = {
      currency: company?.currencies_available?.[0]?.to_currency,
      dateStart: moment(company?.date_start).format('YYYY-MM-DD'),
      dateEnd: moment(company?.date_end).isAfter(moment())
        ? moment()
        : moment(company?.date_end),
      dateEndCohort: moment(company?.date_end_cohort).isAfter(moment())
        ? moment()
        : moment(company?.date_end_cohort),
      range: 0,
      activeType: 'All',
      categoryTypes: optionFilters.categoryTypeOptions
        ?.find((cto: Filter) => cto.type_key === 'All')
        ?.type_val?.map((tv: FilterItem) => tv.id),
      activeFacilityId: 1,
      dateStartCohort: moment.utc(company?.date_start),
      financialEntity: optionFilters.financialEntities?.length
        ? optionFilters.financialEntities[0].entity_id
        : '',
      financialEntityCurrency: optionFilters.financialEntities?.length
        ? optionFilters.financialEntities[0].currency
        : '',
      entityStartDate: optionFilters.financialEntities?.length
        ? optionFilters.financialEntities[0].start_date
        : '',
      entityEndate: optionFilters.financialEntities?.length
        ? optionFilters.financialEntities[0].end_date
        : '',
      activeEntityId: optionFilters.entities?.length
        ? optionFilters.entities[0].ID
        : '',
      calculationDate: optionFilters.calculationDateOption?.length
        ? optionFilters.calculationDateOption[0]?.calculation_date
        : company?.date_end,
      financialUploadStatus: 'All',
    }
    const firstFacility = optionFilters?.facilities?.find(
      (f: Facility) => f.facility_id === 1
    )
    setActiveFilters(initialFilters)
    setAppliedFilters(initialFilters)
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      calculationDateIsFetching: false,
    }))
    setFilterDocCentre(
      firstFacility,
      company,
      setActiveFilters,
      optionFilters,
      setOptionFilters,
      true
    )
  }

  const applyFilter = () => {
    if (company) {
      if (company.has_analytics) {
        let dates
        try {
          dates = {
            default_from: moment
              .utc(activeFilters.dateStart)
              .format('YYYY-MM-DD'),
            default_to: moment.utc(activeFilters.dateEnd).format('YYYY-MM-DD'),
            cohort_from: moment
              .utc(activeFilters.dateStartCohort)
              .format('YYYY-MM-DD'),
            cohort_to: moment
              .utc(activeFilters.dateEndCohort)
              .format('YYYY-MM-DD'),
          }
        } catch (error) {
          dates
        }

        AnalyticsWarmerService.warmup({
          slug_name: company.slug_name,
          dates,
          currency: activeFilters?.currency,
          filters: activeFilters?.categoryTypes,
        })
      }
      if (company.has_manage) {
        let calculation_date
        try {
          calculation_date = moment
            .utc(activeFilters.calculationDate)
            .format('YYYY-MM-DD')
        } catch (error) {
          calculation_date
        }
        ManageWarmerService.warmup({
          slug_name: company.slug_name,
          calculation_date,
        })
      }
    }
    if (activeFilters.activeFacilityId !== appliedFilters.activeFacilityId) {
      activeFilters.calculationDate = company?.date_end
    }
    setAppliedFilters(activeFilters)
    setShowFilter(!showFilter) //closes filters when new filters are selected
  }
  //preparing breadcrumbs.. yum toast ;)
  const breadcrumbsArr = window.location.pathname.split('/')

  /**
   * temp workaround for this issue
   * https://github.com/creativetimofficial/material-tailwind/pull/206
   */
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 500)
    return () => {
      setMounted(false)
    }
  }, [])
  /** end workaround */

  useEffect(() => {
    setShowFilter(false)
    stateChange(window.location.pathname, console.info)
  }, [location])

  return (
    <div className="overflow-y-auto flex flex-col gap-y-4 py-4 pt-[5rem] px-2 sm:px-6 lg:gap-y-6 lg:py-6 xl:px-10 [&>nav]:w-full">
      <details
        className="z-10 relative duration-300 [&>summary>div.icon-open]:open:!flex [&>summary>div.icon-close]:open:!hidden"
        open={mounted}
      >
        <summary className="pb-2 gap-y-1 sm:pb-6 list-none flex flex-col sm:flex-row justify-between">
          {breadcrumbsArr.length > 1 && (
            <Breadcrumbs separator=">" className="bg-transparent p-0 w-full">
              {breadcrumbsArr.map((crumb, i) => (
                <a
                  key={i}
                  href="#"
                  className="text-sm capitalize text-neutral-body-2"
                >
                  {crumb == '' ? (
                    <HomeIcon className="w-5" />
                  ) : (
                    crumb.replaceAll('-', ' ')
                  )}
                </a>
              ))}
            </Breadcrumbs>
          )}
          {filters && (
            <>
              <div className="icon-open justify-center gap-2 hidden items-center cursor-pointer">
                <Typography className="text-neutral-body-2 text-sm">
                  Hide Filter
                </Typography>
                <ChevronDownIcon className="w-4" />
              </div>
              <div className="icon-close flex justify-center gap-2 items-center cursor-pointer">
                <Typography className="text-neutral-body-2 text-sm">
                  Show Filter
                </Typography>
                <ChevronRightIcon className="w-4" />
              </div>
            </>
          )}
        </summary>
        {filters && (
          <div className=" flex flex-col margin-flex-col gap-x-3 gap-y-2 tablet:gap-y-0 py-4 px-6 bg-neutral-white rounded-md shadow sm:flex-row sm:flex-wrap sm:justify-between">
            {filters?.left && <div>{filters?.left}</div>}

            {filters?.middle && (
              <div className="min-w-[260px] ">{filters?.middle}</div>
            )}
            {filters?.right && <div>{filters?.right}</div>}

            <div className="flex ml-auto sm:flex-row gap-2 sm:gap-3 items-end  min-h-[64px]">
              {/* placed above due to margin issues... */}
              <div ref={exportDivRef} id="exportdiv" className="min-h-[40px]">
                {rightFooterContent}
              </div>
              <Button
                className="min-h-[40px]"
                onClick={resetFilter}
                disabled={disableReset}
              >
                Reset
              </Button>
              <Button
                color="primary"
                onClick={applyFilter}
                disabled={disableApply}
                className="min-h-[40px]"
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </details>
      <div className="rounded-md">{children}</div>
    </div>
  )
}

export default BaseLayout
