import React, { useContext, useEffect } from 'react'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { ShieldCheckIcon } from '@heroicons/react/24/outline'
import { LayoutFilter } from '@interfaces/filter'
import { EntityInfo } from '@interfaces/financial'
import { Option, Select } from '@material-tailwind/react'
import FilterService from '@services/api-analytics/risk-traction-filter'

const FilterFinancialDisplay = () => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { financialEntity } = activeFilters
  const { financialEntities } = optionFilters

  useEffect(() => {
    if (company) {
      FilterService.getEntities(company.slug_name).then(res => {
        setOptionFilters((prev: LayoutFilter) => ({
          ...prev,
          financialEntities: res.data,
        }))

        setActiveFilters((prev: LayoutFilter) => {
          return {
            ...prev,
            currency: prev.financialEntityCurrency ?? res.data[0]?.currency,
            rate: prev.rate ?? 1,
            range: prev.range ?? 0,
            financialEntity: prev.financialEntity ?? res.data[0]?.entity_id,
            financialEntityCurrency:
              prev.financialEntityCurrency ?? res.data[0]?.currency,
          }
        })
      })
    }
  }, [company])

  useEffect(() => {
    //removes values set by this filter component
    const cleanupActiveFilters = () => {
      const {
        /*eslint-disable @typescript-eslint/no-unused-vars */
        /* eslint-disable no-unused-vars */
        currency,
        rate,
        financialEntity,
        financialEntityCurrency,
        /* eslint-enable no-unused-vars */
        /* eslint-enable @typescript-eslint/no-unused-vars */
        ...cleanFilters
      } = activeFilters

      setActiveFilters({
        ...cleanFilters,
        rate: 1,
      })
    }

    // Cleanup filters on unmount
    return cleanupActiveFilters
  }, [])
  /**ready for when display by interval filter is needed */

  // const monthCount = moment(dateEnd).diff(moment(dateStart), 'months')

  // useEffect(() => {
  //   setActiveFilters((prev: LayoutFilter) => {
  //     return {
  //       ...prev,
  //       financialsPeriod:
  //         monthCount < 12 && monthCount > 3
  //           ? 'Quater'
  //           : monthCount < 3
  //           ? 'Month'
  //           : 'Year',
  //     }
  //   })
  // }, [monthCount])

  const getEntityDisplayName = (entity: string) => {
    let name = ''
    financialEntities.map((en: EntityInfo) => {
      if (en.entity_id == entity) {
        name = en.name
      }
    })
    return name
  }

  return (
    <div className="flex flex-col gap-1 min-w-[150px]">
      {!!financialEntities && financialEntities.length > 0 && (
        <>
          <Typography className="text-neutral-body-2 text-sm">
            Entities
          </Typography>

          {/* Display by filter commented out for now
          <div className="p-4 flex w-full">
          {['Month', 'Quater', 'Year'].map((x, i) => (
            <Button
              key={i}
              color={financialsPeriod == x ? 'primary' : 'default'}
              disabled={
                x == 'Month'
                  ? monthCount < 0
                  : x == 'Quater'
                  ? monthCount < 3
                  : monthCount < 12
              }
              onClick={() => {
                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  financialsPeriod: x,
                }))
              }}
              className={`flex-1 h-[40px] ${
                i === 0
                  ? 'rounded-r-none'
                  : i === 2
                  ? 'rounded-l-none'
                  : 'rounded-none'
              }`}
            >
              {x}
            </Button>
          ))}
        </div> */}
          <Select
            className="[&~ul]:max-h-[100px] bg-neutral-white text-neutral-black"
            onChange={val => {
              const enCurrency = financialEntities.find(
                (e: EntityInfo) => e.entity_id == val
              ).currency
              setActiveFilters({
                ...activeFilters,
                financialEntity: val,
                financialEntityCurrency: enCurrency,
                currency: enCurrency,
              })
            }}
            selected={() => {
              return (
                <span className="flex">
                  {' '}
                  <ShieldCheckIcon className="w-4 mr-2" />
                  {getEntityDisplayName(financialEntity)}
                </span>
              )
            }}
          >
            {financialEntities.map((e: EntityInfo, i: number) => (
              <Option key={i} value={e.entity_id}>
                {e.name}
              </Option>
            ))}
          </Select>
        </>
      )}
    </div>
  )
}
export default FilterFinancialDisplay
