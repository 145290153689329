import React, { useEffect, useState } from 'react'
import moment from 'moment'

import Button from '@components/atoms/button'
import Chart, { chartColors } from '@components/chart'
import { REMOUNT_MS } from '@constants/config'
import { greenToRed } from '@helpers/green-to-red'
import {
  CovenantCohortPool,
  CovenantDetailResponse,
} from '@interfaces/manage-monitor-covenant'

import CovenantAudit from '../audit'
import CovenantSummary from '../summary'

import { CovenantDetailProps } from '.'

export interface CovenantDetailRegularProps extends CovenantDetailProps {
  covenant?: CovenantDetailResponse
}

const triggerColors = ['#d9f932', '#0887a7', '#b058a7']

const CovenantDetailRegular = ({
  covenant,
  list_item,
  type,
}: CovenantDetailRegularProps) => {
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const { data = [], params } = covenant ?? {}
  const {
    format = '#.00a%',
    invert_colours = false,
    cohort_pool = CovenantCohortPool.month,
    label = 'Percentage',
    special_series_label = '',
    special_series_field = '',
  } = params ?? {}

  const setColor = (field: string, data: any) => {
    if (data.x > moment.utc(list_item.date_as_of).valueOf()) {
      return '#757d90'
    } else if ((list_item.triggers ?? []).length === 0) {
      return greenToRed(0, true).toString()
    }

    const denominator = (list_item.triggers ?? []).length
    const numerator = invert_colours
      ? denominator - (data.trigger_value ?? 0)
      : data.trigger_value ?? 0
    const percent = (numerator / denominator) * 100

    return greenToRed(percent, true).toString()
  }

  const covenants = (list_item.values ?? []).map((v: any, iv: number) => ({
    label: v.label,
    tooltipValueFormat: format,
    type: 'ColumnSeries',
    field: `value_${iv + 1}`,
    setColor,
  }))

  const triggers = (list_item.triggers ?? []).map((t: any, it: number) => {
    // const color = getRandomColor()
    return {
      label: `Trigger xd ${it + 1}`,
      tooltipValueFormat: format,
      type: 'SmoothedXLineSeries',
      field: `trigger_${it + 1}`,
      color: triggerColors[it],
      hasBullet: false,
    }
  })
  const specialSeriesLabels = special_series_label.split(',')

  const specialSeriesFields = special_series_field.split(',')
  const specialSeries = (specialSeriesFields ?? []).map((s: any, i: number) => {
    return {
      label: specialSeriesLabels[i],
      tooltipValueFormat: format,
      type: 'SmoothedXLineSeries',
      field: s,
      color: chartColors[i + [...covenants, ...triggers].length],
      hasBullet: false,
      isSpecial: true,
    }
  })

  const chart_series = [...covenants, ...triggers, ...specialSeries]

  let yMin = 0

  const chart_data = data.map(d => {
    if (d.value_1 < yMin) {
      yMin = d.value_1
    }
    return {
      ...d,
      x: moment.utc(d.cohort, 'YYYY-MM-DD').valueOf(),
    }
  })

  const xLabel = cohort_pool[0].toUpperCase() + cohort_pool.slice(1)

  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <div className="flex flex-col">
      <div className="ml-4 desktop:ml-40 desktop:mr-35 my-8">
        <div className="w-full grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-x-4 gap-y-4">
          {[
            { color: '#59ce87', label: 'No Trigger Breach' },
            ...(list_item.triggers ?? []).map((t, i) => ({
              color: greenToRed(
                ((i + 1) / (list_item.triggers ?? []).length) * 100,
                true
              ) as string,
              label: `Trigger ${i + 1} Breached`,
            })),
            { color: '#757d90', label: 'Not in Test Period' },
          ].map((t, i) => {
            return (
              <div
                key={i}
                className="text-xs flex items-center gap-2 w-[150px]"
              >
                <span
                  style={{
                    display: 'inline-block',
                    height: 14,
                    width: 14,
                    backgroundColor: t.color,
                    borderRadius: 4,
                  }}
                />
                {t.label}
              </div>
            )
          })}
          {triggers.map((t, i) => {
            return (
              <div key={i} className="text-xs flex items-center gap-1">
                <span
                  style={{
                    display: 'inline-block',
                    height: 2,
                    width: 14,
                    backgroundColor:
                      chartColors[i + covenants.length] ?? t.color,
                  }}
                />
                {t.label}
              </div>
            )
          })}
          {specialSeriesFields != '' &&
            specialSeries.map((s: any, i: any) => {
              return (
                <div key={i} className="text-xs flex items-center gap-1">
                  <span
                    style={{
                      display: 'inline-block',
                      height: 4,
                      width: 14,
                      background: `linear-gradient(to right, ${s.color} 0%, ${s.color} 45%, white 45%, white 55%, ${s.color} 55%, ${s.color} 100%)`,
                    }}
                  />
                  {s.label}
                </div>
              )
            })}
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex-1">
          <Chart
            loading={isProcessing}
            id={list_item.covenant_id.toString()}
            yLabel={label}
            yFormat={format}
            xSetting={{
              renderer: {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
              },
              dateAxis: {
                baseInterval: {
                  timeUnit: cohort_pool,
                  count: 1,
                },
              },
            }}
            xLabel={xLabel}
            ySetting={{ min: yMin }}
            data={chart_data as any}
            series={chart_series as any}
            tooltipPosition="bottom"
            legendSetting={{ show: false }}
            exportable={false}
          />
        </div>
        <div className="flex max-w-[357px] flex-start flex-col gap-6 mt-3.5">
          <CovenantSummary covenant={list_item} type={type} />
          <Button className="!outline-none" onClick={() => setShowPopup(true)}>
            Audit
          </Button>
          <CovenantAudit
            open={showPopup}
            handler={() => setShowPopup(false)}
            list_item={list_item}
            type={type}
          />
        </div>
      </div>
    </div>
  )
}

export default CovenantDetailRegular
