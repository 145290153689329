import {
  AdvanceRequestListPayload,
  AdvanceRequestLogListPayload,
  AdvanceRequestResponse,
  UploadCoignExcelFilePayload,
} from '@interfaces/manage-monitor-action'

import manageApiClient from './client'

export class ActionService {
  static async getAdvanceRequest({
    slug_name,
    facility_id,
    facility,
    page,
    per_page,
  }: AdvanceRequestListPayload) {
    const { data } = await manageApiClient.get<{
      data: AdvanceRequestResponse[]
      total: number
    }>(
      `/advance-request/${slug_name}/${facility_id}/${facility}/list?page=${page}&per_page=${per_page}`
    )
    return data
  }

  static async getAdvanceRequestById({
    slug_name,
    facility_id,
    facility,
    id,
  }: AdvanceRequestListPayload) {
    const { data } = await manageApiClient.get<{
      data: AdvanceRequestResponse[]
      total: number
    }>(
      `/advance-request/${slug_name}/${facility_id}/${facility}/filter-by-id/${id}`
    )
    return data
  }

  static async createAdvanceRequest(
    slug_name: string,
    facility_id: number,
    formData: FormData
  ) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request/create/${slug_name}/${facility_id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }

  static async updateAdvanceRequest(
    formData: FormData,
    slug_name: string,
    facility_id: number,
    id: number
  ) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request/${slug_name}/${facility_id}/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }

  static async archiveAdvanceRequest(
    id: number,
    params: { slug_name: string; facility_id: number }
  ) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request/${id}/archive`,
      params
    )
    return data
  }

  static async downloadAdvanceRequest({
    slug_name,
    facility_id,
    file,
  }: {
    slug_name: string
    facility_id: number
    file: string
  }) {
    const { data } = await manageApiClient.get<any[]>(
      `/advance-request/${slug_name}/${facility_id}/download/${file}`
    )
    return data
  }

  static async getHistoryAdvanceRequest(
    id: number,
    { slug_name, facility_id, page, per_page }: AdvanceRequestLogListPayload
  ) {
    const { data } = await manageApiClient.get<any[]>(
      `/advance-request/${slug_name}/${facility_id}/history/${id}?page=${page}&per_page=${per_page}`
    )
    return data
  }

  static async uploadCoignExcelFile({
    slugName,
    facilityId,
    fileName,
    effectiveDate,
    inputs,
  }: UploadCoignExcelFilePayload) {
    const { data } = await manageApiClient.post<any[]>(
      `/client-data/${slugName}/${facilityId}/coign-compliance-data`,
      { fileName, effectiveDate, inputs }
    )
    return data
  }
}
