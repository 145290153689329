import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { Facility } from '@interfaces/facility'
import {
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'
import {
  SignatureRequest,
  TemplateDialogProps,
} from '@interfaces/manage-signature'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { FacilityDetailsService } from '@services/api-manage/facility-details'
import { CovenantService } from '@services/api-manage/monitor-covenant'

import { SignerInput } from '../components'
import { getCustomData } from '../custom-data'

import { renderTemplate } from './templates'

interface Props extends TemplateDialogProps {
  covenants: CovenantListResponse[]
}

const PortfolioCovenantTemplate = ({
  form,
  user,
  createEmbeddedSignRequest,
  uploadDocument,
  handler,
  dialogOpen,
  covenants,
  docData,
  category,
}: Props) => {
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form

  const covenantNames = covenants.map(c => c.covenant_name)

  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId, dateEnd } = appliedFilters
  const { facilities = [] } = optionFilters
  const [guarantors, setGuarantors] = useState<string>()
  const [agent, setAgent] = useState<string>('')
  const [borrower, setBorrower] = useState<string>('')

  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )
  const agreementDate = moment
    .utc(docData?.effective_date ?? getValues('date'))
    .format('YYYY-MM-DD')

  const signers = category?.signers ? JSON.parse(category.signers) : undefined

  const [isLoadingCustomData, customData] = getCustomData(category)

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility_id: activeFacility?.facility_id,
    type: 'portfolio' as CovenantType,
    date: agreementDate || undefined,
  }

  const covenantQueries = covenants.map(covenant =>
    useQuery(
      ['covenant-raw', covenant, filters],
      () => CovenantService.getRaw({ ...filters, id: covenant.covenant_id }),
      {
        ...getStaleMins(),
        enabled: !!activeFacility?.facility_name,
      }
    )
  )

  const {
    data: partyDetailsData,
    mutate: getPartyDetails,
    isLoading: isLoadingPartyDetails,
  } = useMutation(
    (params: { facility_id: number; facility: string; slug_name: string }) => {
      return FacilityDetailsService.getDealPartyDetailsRequest(params)
    }
  )

  const allTableData = covenantQueries.every(q => !q.isLoading)
    ? covenantQueries.map((query, i: number) => {
        const queryData = query?.data

        const firstData = (queryData ?? [])?.[0] ?? {}

        const columns: any = Object.keys(firstData)
          .filter(x => firstData[x] !== null)
          .map(x => ({
            field: x,
            title: x.replace('_', ' '),
            align: 'right',
          }))

        const data = queryData
          ?.sort((a: any, b: any) => (a.cohort < b.cohort ? -1 : 1))
          .filter((x: any) => {
            // show trigger breached
            return x[columns[columns.length - 1].field] !== 0 ? 'Yes' : 'No'
          })
        return { [covenants[i].covenant_name]: { data, columns } }
      })
    : []

  const onSubmit = (cd: any) => {
    if (!!signers?.signers && !signers.signers.length) {
      uploadDoc(cd)
    } else {
      createSignableDoc(cd)
    }
  }

  const uploadDoc = async (cd: any) => {
    const formData = new FormData()
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append('effective_date', moment.utc(cd.date).format('DD-MM-YYYY'))
    formData.append('category', 'Portfolio Covenant Certificate')
    formData.append(
      'title',
      `Form of Compliance Certificate – Portfolio Covenants - ${moment
        .utc(cd.date)
        .format('MMM-YYYY')}`
    )
    formData.append('facility', activeFacility?.facility_name)
    user?.email && formData.append('author_email', `${user.email}`)
    user?.name && formData.append('author_name', `${user.name}`)
    formData.append('form_values', JSON.stringify(cd))
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          effectiveDate: moment.utc(cd.date ?? dateEnd).format('YYYY-MM-DD'),
          tableData: allTableData,
          covenantNames,
          agreementDate: moment
            .utc(activeFacility?.agreement_date)
            .format('YYYY-MM-DD'),
          customData,
          formData: cd,
        })
      ).toBlob()
    )

    uploadDocument(formData)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const createSignableDoc = async (cd: any) => {
    const formData = new FormData()
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    docData.id && formData.append('id', docData.id)
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append('category', 'Portfolio Covenant Certificate')
    formData.append('facility', activeFacility?.facility_name)
    formData.append('effective_date', moment.utc(cd.date).format('DD-MM-YYYY'))
    formData.append(
      'title',
      `Form of Compliance Certificate – Portfolio Covenants - ${moment
        .utc(cd.date)
        .format('MMM-YYYY')}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          effectiveDate: moment.utc(cd.date ?? dateEnd).format('YYYY-MM-DD'),
          tableData: allTableData,
          covenantNames,
          agreementDate: moment
            .utc(activeFacility?.agreement_date)
            .format('YYYY-MM-DD'),
          customData,
          formData: cd,
        })
      ).toBlob()
    )
    formData.append('subject', `Form of Compliance Certificate`)
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        cd.signers.map(
          (s: { name: string; email: string; title?: string }, i: number) => ({
            name: s.name,
            order: i,
            emailAddress: s.email,
          })
        )
      )
    )
    formData.append('form_values', JSON.stringify(cd))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const isLoading = isSubmitting || isLoadingPartyDetails || isLoadingCustomData

  const erroredCovenants: string[] = covenantQueries
    .map((q, index) => (q.isError ? covenantNames[index] : null))
    .filter(x => x !== null) as string[]

  useEffect(() => {
    if (dialogOpen) {
      setValue('date', moment.utc(docData?.effective_date), {
        shouldValidate: true,
      })
    }
  }, [dialogOpen])

  useEffect(() => {
    if (dialogOpen && activeFacility.facility_name && company?.slug_name) {
      getPartyDetails({
        facility_id: activeFacility?.facility_id,
        facility: activeFacility?.facility_name,
        slug_name: activeFacility?.slug_name ?? company?.slug_name,
      })
    }
  }, [dialogOpen, activeFacility])

  useEffect(() => {
    if (partyDetailsData) {
      const guarantorsList = partyDetailsData
        .filter((entry: { type: string }) => entry.type === 'Guarantor')
        .map((entry: { legal_name: any }) => entry.legal_name)
        .join(' and ')
      setGuarantors(guarantorsList)
      const borrower = partyDetailsData.filter(
        (entry: { type: string }) => entry.type === 'Borrower'
      )?.[0]?.legal_name
      const agent = partyDetailsData.filter(
        (entry: { type: string }) => entry.type === 'Agent'
      )?.[0]?.legal_name
      setAgent(agent)
      setBorrower(borrower)
    }
  }, [partyDetailsData])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogBody className="flex flex-row h-[80vh] overflow-hidden relative">
        <XMarkIcon
          className="absolute right-3 w-7 h-7 cursor-pointer"
          onClick={handler}
        />
        {isLoading ? (
          <div className="w-[100%] h-[100%] flex items-center justify-center">
            <ArrowPathIcon className="w-10 h-10 mx-20 text-primary-main animate-spin" />
          </div>
        ) : !covenantNames?.length || erroredCovenants?.length ? (
          <div className="flex flex-col justify-center items-center w-full">
            {!covenantNames?.length
              ? 'No covenants available to display'
              : 'No data available for the following covenants:'}
            <ul>
              {erroredCovenants.map(c => (
                <li key={c}>{c}</li>
              ))}
            </ul>
          </div>
        ) : (
          <PDFViewer showToolbar={false} className="h-full w-full">
            {renderTemplate(category, {
              agent,
              borrower,
              guarantors,
              effectiveDate: moment.utc(getValues('date')).format('YYYY-MM-DD'),
              tableData: allTableData,
              covenantNames,
              agreementDate: moment
                .utc(activeFacility?.agreement_date)
                .format('YYYY-MM-DD'),
              customData,
              formData: getValues(),
            })}
          </PDFViewer>
        )}
        <div className="m-5 w-1/3 overflow-auto">
          <div className="flex flex-col content-between">
            <div className="font-bold text-lg pb-5">
              Fill in below fields to generate document
            </div>
            <FormInput
              type="date"
              label={{
                start: 'Effective Date of Compliance Certificate',
              }}
              value={getValues('date') || moment.utc(docData?.effective_date)}
              onSelected={val => {
                setValue('date', val, {
                  shouldValidate: true,
                })
              }}
              isDisabled={!!docData?.effective_date}
              error={errors?.date?.message as string}
            />
            <SignerInput user={user} form={form} signers={signers} />
            <div className="flex flex-row w-full justify-end my-3">
              <Button
                disabled={isLoading || !isValid}
                type="submit"
                color="primary"
                className="w-full"
              >
                {isLoading ? (
                  <ArrowPathIcon className="w-4 h-4 mx-20 text-primary-main animate-spin" />
                ) : signers?.signers && !signers?.signers?.length ? (
                  'Generate'
                ) : (
                  'Prepare Signature Request'
                )}
              </Button>
            </div>
          </div>
        </div>
      </DialogBody>
    </form>
  )
}

export const PortfolioCovenantCertAction = (props: TemplateDialogProps) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  const params = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility_id: activeFacilityId,
    type: 'portfolio' as CovenantType,
  }

  const { data: covenants = [], isFetching: isFetchingCovenantNames } =
    useQuery(
      ['covenant-names', params],
      () => CovenantService.getList(params),
      {
        ...getStaleMins(),
        enabled: !!activeFacility?.facility_name,
      }
    )

  return (
    <Dialog
      open={props.dialogOpen}
      size={'xl'}
      dismiss={{ enabled: false }}
      handler={props.handler}
    >
      {isFetchingCovenantNames ? (
        <div className="w-[100%] h-[70vh] flex items-center justify-center">
          <ArrowPathIcon className="w-10 h-10 mx-20 text-primary-main animate-spin" />
        </div>
      ) : (
        <PortfolioCovenantTemplate {...props} covenants={covenants} />
      )}
    </Dialog>
  )
}
