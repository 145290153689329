import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Switch } from '@material-tailwind/react'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

const DelinqByCohortL5 = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const isAggregate = activeType === 'All'

  const { showTable, setShowTable } = useContext(ApplicationCustomContext)

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    currency,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['delinqByCohort', filters],
    () => DelinquencyService.getDelinqByCohort(filters),
    getStaleMins()
  )

  const { data } = fetchedData ?? {}

  const chartData = (data || []).map(x => {
    const typecasetedX = x as unknown as { [key: string]: number | string }
    const result: { x: number; [key: string]: number } = {
      x: moment(typecasetedX.cohort).valueOf(),
    }
    Object.keys(x).forEach((key: string) => {
      result[key] = parseFloat((typecasetedX[key] ?? 0).toString())
    })

    return result
  })
  const series = [
    { field: 'dbc_paidoff', label: 'Paid Off' },
    { field: 'dbc0', label: '0 DPD' },
    { field: 'dbc1', label: '1-7 DPD' },
    { field: 'dbc8', label: '8-14 DPD' },
    { field: 'dbc15', label: '15-29 DPD' },
    { field: 'dbc30', label: '30-45 DPD' },
    { field: 'dbc46', label: '46-59 DPD' },
    { field: 'dbc60', label: '60-89 DPD' },
    { field: 'dbc90', label: '90-119 DPD' },
    { field: 'dbc120', label: '120-149 DPD' },
    { field: 'dbc150', label: '150-179 DPD' },
    { field: 'dbc180', label: '180+ DPD' },
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const table_column = [
    {
      title: 'Cohort',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...series.map((s: any) => {
      return {
        className: 'min-w-[150px]',
        align: 'right',
        field: `${s.field}_outstanding_balance`,
        title: s.label,
        head: { align: 'center' },
        render: (r: any) =>
          numberFormatter(r[`${s.field}_outstanding_balance`]),
      } as any
    }),
    {
      title: 'Total Volume',
      field: 'total_outstanding_balance',
      align: 'right',
      className: 'min-w-[150px]',
      head: { align: 'center' },
      render: (r: any) => numberFormatter(r['total_outstanding_balance']),
    },
  ]

  return (
    <L5ChartstatLayout
      chart={
        <>
          <Chart
            loading={isFetching || isProcessing}
            id={`delinqByCohort_by_${activeType}`}
            yLabel="Percentage"
            yFormat="#.00a%"
            data={chartData}
            series={series.map(s => ({
              ...s,
              tooltipValueFormat: '#.00a%',
              type: 'ColumnSeries',
              isStack: true,
            }))}
            exportable={exportable}
            exportableColumn={table_column}
            error={error as { message: string }}
          />
          <div className="flex justify-end mt-8">
            <Switch
              label="Show Data"
              checked={showTable}
              onChange={() => {
                setShowTable(!showTable)
              }}
              defaultChecked
              color="light-blue"
              crossOrigin={undefined}
            />
          </div>
          {showTable && (
            <Table
              containerClass="mt-8 [&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
              loading={isFetching || isProcessing}
              data={chartData}
              columns={table_column}
            />
          )}
        </>
      }
    />
  )
}

export default DelinqByCohortL5
